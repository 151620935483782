<template>
    <div class="w3-product-detail-images">
        <div class="w3-product-detail-images__main">
            <img class="w3-product-detail-images__main-image" :src="selectedImage.src" :alt="selectedImage.atl" />
        </div>
        <div class="w3-product-detail-images__thumbnails" v-show="images.length > 1">
            <div v-for="image in images" :key="image.src" class="w3-product-detail-images__thumbnail" @click="selectedImage = image">
                <img class="w3-product-detail-images__thumbnail-image" :src="image.src" :alt="image.alt"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        images: {
            required: true,
            type: Array
        }
    },
    data() {
        return {
            selectedImage: {},
        }
    },
    created() {
        if (this.images[0]) {
            this.selectedImage = this.images[0];
        }
    }
}
</script>

