<template>
    <div class="modal-card"
         style="overflow: unset">
        <header class="modal-card-head">
            <p class="modal-card-title">Wijnhuis</p>
        </header>
        <section class="modal-card-body" style="overflow: unset">
            <wine-house-create-form ref="wineHouseCreateForm"></wine-house-create-form>
        </section>
        <footer class="modal-card-foot">
            <button class="button"
                    type="button"
                    @click="$parent.close()">Close
            </button>
            <button class="button is-primary"
                    @click="handleSubmitClick">Opslaan
            </button>
        </footer>
    </div>
</template>

<script>
    import WineHouseCreateForm from './WineHouseCreateForm';

    export default {
        components: {
            WineHouseCreateForm
        },
        methods: {
            handleSubmitClick() {
                this.$refs.wineHouseCreateForm.submitWineHouseCreate().then(data => {
                    this.$emit('wine-house-created', data);
                    this.$parent.close();
                })
            }
        },
    }
</script>

<style>

</style>
