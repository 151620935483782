<template>
    <div class="w3-admin-main-form-search">
        <div v-if="currentRelation !== null"
             class="w3-admin-main-form-search-results">
            <h5>Huidig:</h5>
            <div class="w3-admin-main-form-search-results__item">
                <div class="w3-admin-main-form-search-results__item-info">
                    <h5 class="w3-admin-main-form-search-results__item-title">
                        <strong>{{ currentRelation.name }}</strong>
                    </h5>
                    <p>{{ currentRelation.email }}</p>

                    <h6>
                        <strong>Adres:</strong>
                    </h6>
                    <p><i>{{ currentRelation.correspondence_address.full_name }}</i></p>
                    <p>{{ currentRelation.correspondence_address.street }} {{ currentRelation.correspondence_address.house_number }} {{ currentRelation.correspondence_address.addition }}</p>
                    <p>{{ currentRelation.correspondence_address.zipcode }} {{ currentRelation.correspondence_address.city }}</p>
                    <p>{{ currentRelation.correspondence_address.country }}</p>
                </div>
            </div>
        </div>

        <b-field>
            <b-input placeholder="Klant..."
                     v-model="name"
                     type="search"
                     icon="search"
                     expanded>
            </b-input>
            <p class="control">
                <button class="button is-primary"
                        @click="search"
                        type="button">
                    Zoeken
                </button>
            </p>
        </b-field>

        <div v-if="relation !== null"
             class="w3-admin-main-form-search-results">
            <h5>Gevonden:</h5>
            <div class="w3-admin-main-form-search-results__item"
                 @click="setSelected">
                <div class="w3-admin-main-form-search-results__item-info">
                    <h5 class="w3-admin-main-form-search-results__item-title">
                        <strong>{{ relation.name }}</strong>
                    </h5>
                    <p>{{ relation.email }}</p>

                    <h6>
                        <strong>Adres:</strong>
                    </h6>
                    <p><i>{{ relation.correspondence_address.full_name }}</i></p>
                    <p>{{ relation.correspondence_address.street }} {{ relation.correspondence_address.house_number }} {{ relation.correspondence_address.addition }}</p>
                    <p>{{ relation.correspondence_address.zipcode }} {{ relation.correspondence_address.city }}</p>
                    <p>{{ relation.correspondence_address.country }}</p>
                </div>
                <i class="fas fa-check-circle w3-admin-main-form-search-results__item-icon"
                   :class="{ ' w3-admin-main-form-search-results__item-icon--active': selected }"></i>
            </div>
        </div>
    </div>
</template>

<script>
import CustomerApi from "./../api/customer";

export default {
    props: {
        relationId: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            name: "",
            relation: null,
            selected: false,
            currentRelation: null
        };
    },
    created() {
        if (this.relationId) {
            CustomerApi.getExternalRelationById(this.relationId).then(response => {
                this.currentRelation = response.data
            })
        }
    },
    methods: {
        search() {
            CustomerApi.getExternalRelation(encodeURIComponent(this.name)).then(response => {
                this.relation = response.data;
            });
        },
        setSelected() {
            this.selected = !this.selected;

            if (this.selected === true) {
                this.$emit('relation-selected', this.relation);
                return;
            }

            this.$emit('relation-selected', null);
        }
    }
};
</script>

