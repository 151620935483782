<template>
    <div>
        <b-field v-for="timeSlotString in timeSlots" :key="timeSlotString">
            <b-radio
                @input="setTimeSlot"
                v-model="timeSlot"
                :native-value="timeSlotString"
            >
                {{ timeSlotString }}
            </b-radio>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'OrderDeliveryTimeSlotRadio',
        props: {
            timeSlots: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                timeSlot: '',
            }
        },
        methods: {
            setTimeSlot() {
                this.$emit('timeSlot', this.timeSlot);
            }
        },
    }
</script>
