import product from './product';
import region from './region';
import wineHouse from './wineHouse';
import customer from './customer';
import featureType from './featureType';
import featureValue from './featureValue';
import cart from './cart';
import user from './user';
import favorite from './favorite';

export { product as productApi };
export { region as regionApi };
export { wineHouse as wineHouseApi };
export { customer as customerApi };
export { featureType as featureTypeApi };
export { featureValue as featureValueApi };
export { cart as cartApi };
export { user as userApi };
export { favorite as favoriteApi };

export default {
    product,
    region,
    wineHouse,
    customer,
    featureType,
    featureValue,
    cart,
    user,
    favorite
};
