<template>
    <div>
        <b-table
            :data="isEmpty ? [] : addresses" :default-sort="['name', 'desc']">

            <template slot-scope="props">
                <b-table-column field="name" label="Naam" sortable>
                    {{ props.row.full_name }}
                </b-table-column>

                <b-table-column field="name" label="Adres">
                    {{ props.row.address1 }} {{ props.row.house_number }}
                </b-table-column>

                <b-table-column field="name" label="Plaats">
                    {{ props.row.city }}
                </b-table-column>

                <b-table-column field="action">
                    <span class="button is-primary is-outlined is-small" @click="editAddress(props.row.id)"><i class="fas fa-pencil-alt"></i></span>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-if="loading === false">
                    <div class="content has-text-grey has-text-centered">
                        <p>Geen adressen gekoppeld...</p>
                    </div>
                </section>
            </template>
        </b-table>

        <span class="button is-primary is-outlined m-t-20"
                @click="isCreateAddressActive = true">
            Nieuw adres aanmaken
        </span>

        <b-modal :active.sync="isCreateAddressActive">
            <div class="card">
                <div class="card-content">
                    <address-create-form :customer-data="customer" @redirect="redirectCreateAddress()"></address-create-form>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import AddressCreateForm from './../components/AddressCreateForm';

    export default {
        components: {
            AddressCreateForm,
        },

        props: [
            'customer',
            'addresses',
        ],

        data() {
            return {
                loading: false,
                isEmpty: false,
                isCreateAddressActive: false,
            };
        },
        methods: {
            editAddress(id) {
                window.location.href = '/admin/addresses/' + id + '/edit';
            },

            redirectCreateAddress() {
                this.isCreateAddressActive = false;
                location.reload();
            }
        }
    };
</script>
