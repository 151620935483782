<template>
    <div>
        <b-field label="Titel">
            <b-input
                type="text"
                ref="title"
                v-model="form.title"
                placeholder="Title"
                required>
            </b-input>
        </b-field>
    </div>
</template>

<script>
    import { featureTypeApi } from "../api";

    export default {
        data() {
            return {
                form: {
                    title: "",
                }
            };
        },
        methods: {
            submitFeatureTypeCreate() {
                featureTypeApi.storeFeatureType(this.form)
                              .then(data => {
                                  this.$emit('feature-type-created', data);
                              })
                              .catch(error => {
                                  this.$emit('feature-type-creation-failed', error);
                              });
            },
            resetForm() {
                this.form.title = '';
                this.$refs.title.focus();
            }
        }
    };
</script>

<style>
</style>
