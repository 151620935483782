<template>
    <div>
        <b-field label="Titel">
            <b-input
                type="text"
                ref="title"
                v-model="form.title"
                placeholder="Title"
                required>
            </b-input>
        </b-field>

        <b-field label="Beschrijving">
            <b-input
                type="text"
                v-model="form.description"
                placeholder="Beschrijving"
                required>
            </b-input>
        </b-field>
    </div>
</template>

<script>
    import ProductApi from "../api/product";

    export default {
        data() {
            return {
                form: {
                    title: "",
                    description: ""
                }
            };
        },
        methods: {
            submitProductCreate() {
                ProductApi.storeProduct(this.form)
                          .then(data => {
                              this.$emit('product-created', data);
                          })
                          .catch(error => {
                              this.$emit('product-creation-failed', error);
                          });
            },
            resetForm() {
                this.form.title = '';
                this.form.description = '';
                this.$refs.title.focus();
            }
        },
        watch: {
            form: {
                handler: function(val) {
                    this.$emit('input', val);
                },
                deep: true
            },
        },
    };
</script>

<style>
</style>
