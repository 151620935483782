<template>
    <span class="tag is-success w3-search-amount-tag" v-if="amountInCart > 0" @click="$emit('click', productId)">
        <i class="fas fa-cart-plus w3-search-amount-tag__icon"></i> {{ amountInCart }} in bestelling
    </span>
</template>

<script>
    export default {
        props: {
            productId: Number
        },
        computed: {
            amountInCart() {
                return this.$store.getters.getProductAmountInCart(this.productId);
            },
            cartItem() {
                return this.$store.getters.getItemByProductId(this.productId);
            }
        },
    }
</script>

<style>

</style>
