<template>
    <div>
        <b-field label="Zoeken">
            <b-input
                    type="text"
                    ref="zoek"
                    v-model="search"
                    placeholder="Zoeken op naam of artikelcode"
                    @input="handleSearch()">
            </b-input>
        </b-field>

        <b-table
            :data="products"
            :loading="tableOptions.loading"
            :total="tableOptions.total"
            :per-page="tableOptions.perPage"
            @page-change="onPageChange"
            backend-pagination
            paginated>

            <template slot-scope="props">
                <b-table-column field="id" label="ID" width="40">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="reference" label="Artikelcode">
                    {{ props.row.reference }}
                </b-table-column>

                <b-table-column field="title" label="Naam">
                    {{ props.row.title }}
                </b-table-column>

                <b-table-column field="action" label="Actie">
                    <a class="button" :href="`/admin/products/${props.row.id}/edit`">Aanpassen</a>
                    <button class="button is-danger m-l-5" @click="showDeleteModal(props.row.id, props.row.title)">Verwijderen</button>
                </b-table-column>

            </template>

            <template slot="empty">
                <section class="section" v-if="! tableOptions.loading">
                    <div class="content has-text-grey has-text-centered">
                        <p>Geen producten gevonden...</p>
                    </div>
                </section>
            </template>
        </b-table>

        <b-modal :active.sync="isModalActive" has-modal-card>
            <delete-product-modal @delete-me="deleteProduct" :productid="removing_product.id" :productname="removing_product.name" ></delete-product-modal>
        </b-modal>
    </div>
</template>

<script>
    import productApi from "./../api/product";
    import DeleteProductModal from "./DeleteProductModal";

    export default {
        components: {
            DeleteProductModal
        },
        props: ["initialProducts"],
        data() {
            return {
                isModalActive: false,
                removing_product: {
                    name: null,
                    id: 0,
                },
                search: '',
                products: [],
                tableOptions: {
                    total: 0,
                    page: 1,
                    perPage: 15,
                    loading: true,
                },
            };
        },
        created() {
            this.getProducts();
        },
        mounted() {

        },
        methods: {
            getProducts(page = null, search = null) {
                productApi.getProducts(page, search).then(response => {
                    this.products = response.data;
                    this.tableOptions.total = response.meta.total;
                    this.tableOptions.loading = false;
                });
            },
            showDeleteModal(productId, productName){
                this.removing_product.name = productName;
                this.removing_product.id = productId;
                this.isModalActive = true;
            },
            deleteProduct(productId) {
                productApi.deleteProduct(productId).then(() => {
                    this.getProducts(this.tableOptions.page, this.search);
                });
            },
            onPageChange(page) {
                this.tableOptions.page = page;
                this.getProducts(page, this.search)
            },
            handleProductCreatedEvent() {
                this.getProducts(this.tableOptions.page, this.search);
            },
            handleSearch() {
                this.tableOptions.page = 1;
                this.getProducts(this.tableOptions.page, this.search);
            }
        }
    };
</script>

<style>
</style>
