<template>
    <div>
        <product-add-feature v-for="input in inputAmount"
                             :key="input"
                             @feature-changed="handleFeatureChangedEvent($event, input)"></product-add-feature>
        <button class="button is-dark"
                @click="inputAmount = inputAmount + 1">
            Kenmerk toevoegen
        </button>
    </div>
</template>

<script>
    import ProductAddFeature from './ProductAddFeature';

    export default {
        components: {
            ProductAddFeature
        },
        data() {
            return {
                features: [],
                inputAmount: 1
            }
        },
        computed: {
            formattedFeatures() {
                return this.features.map(val => {
                    return val.feature_value;
                });
            }
        },
        methods: {
            handleFeatureChangedEvent(payload, input) {
                const index = this.features.findIndex(val => {
                    return val.index === input;
                });

                if (index === -1) {
                    this.features.push({
                        index: input,
                        feature_type: payload.feature_type,
                        feature_value: payload.feature_value
                    });

                    return;
                } else if (payload.feature_type === null || payload.feature_value === null) {
                    this.features.splice(index, 1);

                    return;
                }

                this.features[index].feature_type = payload.feature_type;
                this.features[index].feature_value = payload.feature_value;
            }
        },
        watch: {
            formattedFeatures(newValue) {
                this.$emit('features-updated', newValue);
            }
        },
    }
</script>

<style>

</style>
