<template>
    <form @submit.prevent="submitProductCreate">
        <b-field label="Naam">
            <b-input v-model="form.name"></b-input>
        </b-field>

        <customer-relation-search @relation-selected="handleRelationSelectedEvent"></customer-relation-search>

        <button class="button is-primary"
                type="submit">
            Opslaan
        </button>
    </form>
</template>

<script>
    import CustomerApi from "./../api/customer";
    import CustomerRelationSearch from "./CustomerRelationSearch";

    export default {
        components: {
            CustomerRelationSearch
        },
        data() {
            return {
                form: {
                    name: "",
                    snel_start_relation_guid: null
                }
            };
        },
        methods: {
            submitProductCreate() {
                CustomerApi.storeCustomer(this.form)
                           .then(function(data) {
                               window.location.href = data.redirect_to;
                           })
                           .catch(function(error) {
                               console.log(error);
                           });
            },
            handleRelationSelectedEvent(payload) {
                if (payload !== null && payload.id) {
                    this.form.snel_start_relation_guid = payload.id;
                    return;
                }

                this.form.snel_start_relation_guid = null;
            }

        }
    };
</script>

<style>
</style>
