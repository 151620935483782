<template>
    <div class="w3-admin-main-form">
        <div class="w3-admin-hero">
            <h1 class="w3-admin-hero__title">Product aanpassen</h1>
            <button class="button is-primary w3-admin-hero__button"
                    @click="submitProductUpdate(false)">Opslaan
            </button>
            <button class="button is-primary w3-admin-hero__button"
                    @click="submitProductUpdate(true)">Opslaan en bekijken
            </button>
        </div>

        <div class="notification is-success" v-show="is_saved">
            <button class="delete" @click="is_saved = false"></button>
            Wijzigingen zijn opgeslagen!
            <i class="far fa-thumbs-up"></i>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Product titel</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Titel">
                    <b-input
                        type="text"
                        ref="title"
                        v-model="form.title"
                        required>
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Product alternatieve titel</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Alternatieve titel">
                    <b-input
                        type="text"
                        v-model="form.alternative_title"
                        placeholder="Alternatieve titel">
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Product Beschrijving</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Beschrijving">
                    <b-input
                        type="textarea"
                        v-model="form.description"
                        required>
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Product wijn/spijs Beschrijving</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Beschrijving">
                    <b-input
                        type="textarea"
                        v-model="form.food_pair_description">
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Artikelcode</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Artikelcode">
                    <b-input
                        type="text"
                        v-model="form.reference"
                        placeholder="Artikelcode">
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Tonen op Dashboard</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-switch v-model="form.is_highlighted" type="is-success">
                    Tonen als ontdek nieuwe wijn
                </b-switch>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Basis prijs</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Basis prijs">
                    <div class="control is-clearfix">
                        <money v-model="form.price" class="input"></money>
                    </div>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Horeca prijs</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Horeca prijs">
                    <div class="control is-clearfix">
                        <money v-model="form.price_retail" class="input"></money>
                    </div>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Kortings prijs</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Kortings prijs">
                    <div class="control is-clearfix">
                        <money v-model="form.price_discount" class="input"></money>
                    </div>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Voorraad</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Aantal">
                    <b-input
                        type="number"
                        v-model="form.stock_available">
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Besteleenheid</p>
                <p class="w3-admin-main-form__description">Het kleinste aantal waarvan de klant het veelvoud van kan bestellen</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Aantal">
                    <b-input
                        type="number"
                        v-model="form.order_unit">
                    </b-input>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Wijn kleur</p>
            </div>
            <div class="w3-admin-main-form__input">
                <b-field label="Kleur">
                    <b-select placeholder="Selecteer een kleur" v-model="form.color_id">
                        <option
                            v-for="color in productColors"
                            :value="color.id"
                            :key="color.id">
                            {{ color.title }}
                        </option>
                    </b-select>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Wijn huis</p>
            </div>
            <div class="w3-admin-main-form__input">
                <product-add-wine-house :initial-wine-house="product.wine_house" @wine-house-selected="handleWineHouseSelected"></product-add-wine-house>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Kenmerken</p>
            </div>
            <div class="w3-admin-main-form__input">
                <div class="columns"
                     v-for="(featureValue, index) in feature_values_existing">
                    <div class="column">
                        <strong>{{ featureValue.feature_type.title }}</strong>
                    </div>
                    <div class="column">
                        {{ featureValue.title }}
                    </div>
                    <div class="column">
                        <button class="button is-danger"
                                @click="deleteExistingFeatureValue(index)">
                            Verwijderen
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Nieuw kenmerk en waarde toevoegen</p>
            </div>
            <div class="w3-admin-main-form__input">
                <product-feature-list @features-updated="handleFeatureUpdatedEvent"></product-feature-list>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Product afbeeldingen</p>
            </div>
            <div class="w3-admin-main-form__input">
                <div class="w3-admin-main-form-file-list">
                    <div v-for="image in filteredImages" class="w3-admin-main-form-file-list__item">
                        <div class="w3-admin-main-form-file-list__item-card">
                            <div class="w3-admin-main-form-file-list__image-wrapper">
                                <img :src="`/storage/${image.filename}`"
                                     class="w3-admin-main-form-file-list__image"
                                     alt="">
                            </div>

                            <button class="delete is-small w3-admin-main-form-file-list__delete-button"
                                    type="button"
                                    @click="deleteImage(image.id)">
                            </button>
                        </div>
                    </div>
                </div>

                <b-field>
                    <b-upload v-model="imageDropFiles"
                              multiple
                              drag-drop
                              accept="image/png,image/jpeg">
                        <section class="section">
                            <div class="content has-text-centered">
                                <p>
                                    <b-icon
                                        icon="upload"
                                        size="is-large">
                                    </b-icon>
                                </p>
                                <p>Sleep uw afbeedlingen hierheen of klik om te uploaden</p>
                            </div>
                        </section>
                    </b-upload>
                </b-field>
            </div>
        </div>

        <div class="w3-admin-main-form__item">
            <div class="w3-admin-main-form__label">
                <p class="w3-admin-main-form__title">Product documenten</p>
            </div>
            <div class="w3-admin-main-form__input">
                <div class="w3-admin-main-form-file-list">
                    <div v-for="document in filteredDocuments" class="w3-admin-main-form-file-list__item">
                        <div class="w3-admin-main-form-file-list__item-card w3-admin-main-form-file-list__item-card--h-100">
                            <div class="w3-admin-main-form-file-list__file-thumb">
                                <i class="fas fa-file"></i>
                            </div>

                            <p class="w3-admin-main-form-file-list__name">{{ document.filename_without_folder }}</p>

                            <a :href="`/storage/${document.filename}`" target="_blank" class="button is-primary w3-admin-main-form-file-list__button">Open voorbeeld</a>

                            <button class="delete is-small w3-admin-main-form-file-list__delete-button"
                                    type="button"
                                    @click="deleteDocument(document.id)">
                            </button>
                        </div>
                    </div>
                </div>

                <b-field>
                    <b-upload v-model="documentDropFiles"
                              multiple
                              drag-drop>
                        <section class="section">
                            <div class="content has-text-centered">
                                <p>
                                    <b-icon
                                        icon="upload"
                                        size="is-large">
                                    </b-icon>
                                </p>
                                <p>Sleep uw documenten hierheen of klik om te uploaden</p>
                            </div>
                        </section>
                    </b-upload>
                </b-field>
            </div>
        </div>

        <div class="notification is-success" v-show="is_saved">
            <button class="delete" @click="is_saved = false"></button>
            Wijzigingen zijn opgeslagen!
            <i class="far fa-thumbs-up"></i>
        </div>

        <div class="columns">
            <div class="column is-one-quarter is-offset-one-quarter">
                <button class="button is-primary w3-admin-hero__button w3-button--padding-large"
                        @click="submitProductUpdate(false)">Opslaan
                </button>
            </div>
            <div class="column">
                <button class="button is-primary w3-admin-hero__button w3-button--padding-large"
                        @click="submitProductUpdate(true)">Opslaan en bekijken
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    import ProductApi from "./../api/product";
    import ProductFeatureList from './ProductFeatureList';
    import ProductAddWineHouse from './ProductAddWineHouse';

    export default {
        props: {
            product: Object,
            productColors: Array,
        },
        components: {
            ProductFeatureList,
            ProductAddWineHouse
        },
        data() {
            return {
                form: {
                    title: this.product.title,
                    description: this.product.description,
                    feature_values: this.product.feature_values.map(val => {
                        return val.id;
                    }),
                    wine_house_id: this.product.wine_house_id,
                    reference: this.product.reference,
                    price_retail: this.product.price_retail,
                    price: this.product.price,
                    price_discount: this.product.price_discount ? this.product.price_discount : 0,
                    removed_images: [],
                    removed_documents: [],
                    alternative_title: this.product.alternative_title,
                    is_highlighted: this.product.is_highlighted,
                    food_pair_description: this.product.food_pair_description,
                    stock_available: this.product.stock_available,
                    order_unit: this.product.order_unit,
                    color_id: this.product.color_id,
                },
                feature_values_existing: this.product.feature_values,
                feature_values_new: [],
                images: this.product.images,
                documents: this.product.documents,
                imageDropFiles: [],
                documentDropFiles: [],
                is_saved: false,
            };
        },
        computed: {
            uploadedImageFiles() {
                let files = [];
                this.imageDropFiles.forEach(file => {
                    const obj = {
                        url: URL.createObjectURL(file),
                        file: file
                    };
                    files.push(obj)
                });

                return files;
            },
            filteredImages() {
                return this.images.filter(({ id }) => {
                    return this.form.removed_images.findIndex(removedImageId => removedImageId === id) === -1;
                })
            },
            filteredDocuments() {
                return this.documents.filter(({ id }) => {
                    return this.form.removed_documents.findIndex(removedImageId => removedImageId === id) === -1;
                })
            }
        },
        methods: {
            submitProductUpdate(view = false) {
                ProductApi.updateProduct(this.product.id, this.form)
                          .then((data) => {
                              this.is_saved = true;
                              if (view) {
                                  window.location.href = data.product.product_url;
                              }
                          })
                          .catch((error) => {
                              console.log(error);
                          });
            },
            handleFeatureUpdatedEvent(payload) {
                this.feature_values_new = payload;
                this.setFormFeatureValues();
            },
            handleWineHouseSelected(payload) {
                if (payload) {
                    return this.form.wine_house_id = payload.id;
                }
                return this.form.wine_house_id = null;
            },
            deleteExistingFeatureValue(index) {
                this.feature_values_existing.splice(index, 1);
                this.setFormFeatureValues();
            },
            setFormFeatureValues() {
                this.form.feature_values = [];
                this.feature_values_new.forEach(featureValue => {
                    this.form.feature_values.push(featureValue.id);
                });
                this.feature_values_existing.forEach(featureValue => {
                    this.form.feature_values.push(featureValue.id);
                });
            },
            deleteDropFile(index) {
                this.documentDropFiles.splice(index, 1)
            },
            deleteImage(imageId) {
                this.form.removed_images.push(imageId);
            },
            deleteDocument(documentId) {
                this.form.removed_documents.push(documentId);
            },
        },
        watch: {
            documentDropFiles(files) {
                if (this.documentDropFiles.length === 0) {
                    return;
                }

                const formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    formData.append(`documents[${i}]`, files[i]);
                }

                axios.post(`/api/products/${this.product.id}/documents`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.documents = response.data.documents;
                    this.documentDropFiles = [];
                }).catch(error => {
                    console.log(error)
                })
            },
            imageDropFiles(files) {
                if (files.length === 0) {
                    return;
                }

                const formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    formData.append(`images[${i}]`, files[i]);
                }

                axios.post(`/api/products/${this.product.id}/images`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.images = response.data.images;
                    this.imageDropFiles = [];
                }).catch(error => {
                    console.log(error)
                })
            }
        },
    };
</script>

<style>
</style>
