<template>
    <form @submit.prevent="submitCustomer">
        <b-field label="Klanten">
            <b-select v-model="selected" expanded>
                <option disabled value="">Kies een klant</option>
                <option v-for="customer in customers" :value="customer.id">{{ customer.name }}</option>
            </b-select>
        </b-field>
        <button class="button is-success is-outlined is-fullwidth m-t-20" :disabled="selected.length === 0">Selecteer</button>
    </form>
</template>

<script>
    import CustomerApi from "./../api/customer";
    import UserApi from "./../api/user";

    export default {
        props: [
            'redirectUrl',
        ],
        data() {
            return {
                customers: [],
                selected: '',
            };
        },
        methods: {
            getCustomers() {
                let that = this;
                CustomerApi.getCustomersByUser()
                           .then(function(data) {
                               that.customers = data.data.data;
                           })
                           .catch(function(error) {
                               console.log(error);
                           });
            },
            submitCustomer() {
                let that = this;
                UserApi.setCustomer(that.selected)
                       .then(function(data) {
                           window.location.href = that.redirectUrl;
                       })
                       .catch(function(error) {
                           console.log(error);
                       });
            }
        },
        created() {
            this.getCustomers();
        }
    };
</script>

<style>
</style>
