require('./bootstrap');

window.Vue = require('vue').default;

/**
 * Buefy
 */
import Buefy from 'buefy';

Vue.use(Buefy, {
    defaultIconPack: 'fal'
});

/**
 * Algolia Vue InstantSearch
 */
import InstantSearch from 'vue-instantsearch';

Vue.use(InstantSearch);

/**
 * Vee Validate
 */
import VeeValidate, { Validator } from 'vee-validate';
import nl from 'vee-validate/dist/locale/nl';

Validator.localize('nl', nl);
Vue.use(VeeValidate);

/**
 * Mavon editor
 */
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

Vue.use(mavonEditor)

/**
 * v-money
 */
import money from 'v-money';
Vue.use(money, {
    precision: 2,
    decimal: ',',
    thousands: '.',
    prefix: '€ ',
    suffix: ''
});

import store from './store';

import ProductCreateForm from './components/ProductCreateForm';
import ProductUpdate from './views/ProductUpdate';
import Products from './views/Products';
import Orders from './views/Orders';
import WineHouses from './views/WineHouses';
import WineHouseCreateForm from './components/WineHouseCreateForm';
import WineHouseUpdateForm from './components/WineHouseUpdateForm';
import Regions from './views/Regions';
import RegionUpdateForm from './components/RegionUpdateForm';
import RegionCreateForm from './components/RegionCreateForm';
import Customers from './views/Customers';
import CustomerUpdateForm from './components/CustomerUpdateForm';
import Users from './views/Users';
import UserUpdateForm from './components/UserUpdateForm';
import Addresses from './views/Addresses';
import AddressUpdateForm from './components/AddressUpdateForm';
import FeatureTypes from './views/FeatureTypes';
import FeatureTypeUpdate from './views/FeatureTypeUpdate';
import Search from './views/Search';
import Cart from './views/Cart';
import Checkout from './views/Checkout';
import CheckoutConfirmation from './views/CheckoutConfirmation';
import SelectCustomer from './views/SelectCustomer';
import CartAddProductButton from './components/CartAddProductButton';
import Dashboard from './views/Dashboard';
import ProductDetailImageGallery from "./components/ProductDetailImageGallery";
import FavoriteToggleButton from "./components/FavoriteToggleButton";
import MyAccountChangePassword from "./components/MyAccountChangePassword";
import CountryFlags from './views/CountryFlags';

Vue.component('cart-amount', require('./components/CartAmount').default);
Vue.component('my-account-dropdown', require('./components/MyAccountDropdown').default);

Vue.filter('currency', function(value) {
    if (!value) return '';

    value = Number(value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return '€ ' + value;
});


const app = new Vue({
    el: '#app',
    store,
    components: {
        Products,
        ProductCreateForm,
        ProductUpdate,
        Orders,
        WineHouses,
        WineHouseCreateForm,
        WineHouseUpdateForm,
        Regions,
        RegionCreateForm,
        RegionUpdateForm,
        Customers,
        CustomerUpdateForm,
        Users,
        UserUpdateForm,
        Addresses,
        AddressUpdateForm,
        FeatureTypes,
        FeatureTypeUpdate,
        Search,
        Cart,
        Checkout,
        CheckoutConfirmation,
        SelectCustomer,
        CartAddProductButton,
        Dashboard,
        ProductDetailImageGallery,
        FavoriteToggleButton,
        MyAccountChangePassword,
        CountryFlags,
    },
    created() {
        if (window.Laravel.isAuthenticated) {
            this.$store.dispatch('setUser');
            this.$store.dispatch('setCart');
        }
    }
});
