<template>
    <div class="w3-admin-main-form full-width">
        <div class="w3-admin-hero">
            <h1 class="w3-admin-hero__title">Wijnen</h1>
            <button class="button is-primary w3-admin-hero__button"
                    @click="isComponentModalActive = true">Nieuw product aanmaken
            </button>
        </div>

        <div class="columns m-t-15">
            <div class="column">
                <div class="card">
                    <div class="card-content">
                        <products-list ref="productsList"></products-list>

                        <b-modal :active.sync="isComponentModalActive" has-modal-card>
                            <create-product-modal @product-created="handleProductCreatedEvent()"></create-product-modal>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CreateProductModal from "../components/CreateProductModal";
    import ProductsList from "../components/ProductsList";

    export default {
        components: {
            CreateProductModal,
            ProductsList
        },
        data() {
            return {
                isComponentModalActive: false,
            };
        },
        methods: {
            handleProductCreatedEvent() {
                this.$refs.productsList.handleProductCreatedEvent();
            }
        }
    };
</script>

<style>
</style>
