export default {
    /**
     * Get wine houses.
     *
     * @returns {Promise}
     */
    getWineHouses(page = 1, title = null) {
        let url = `/api/wine-houses?page=${page}`;

        if (title !== null) {
            url += `&title=${title}`;
        }

        return new Promise(function(resolve, reject) {
            axios.get(url)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Store a wine house.
     *
     * @param {Object} param
     * @param {string} param.title
     * @param {string} param.region_id
     * @returns {Promise}
     */
    storeWineHouse({ title, region_id }) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/wine-houses', { title, region_id })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Update a wine house.
     *
     * @param id
     * @param {Object} param
     * @param {string} param.title
     * @param {string} param.region_id
     * @param {string} param.quote
     * @param {string} param.description_left
     * @param {string} param.description_right
     * @param {string} param.removed_images
     * @returns {Promise}
     */
    updateWineHouse(id, { title, region_id, quote, description_left, description_right, removed_images }) {
        return new Promise(function(resolve, reject) {
            axios.put(`/api/wine-houses/${id}`, { title, region_id, quote, description_left, description_right, removed_images })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Delete a wine house.
     *
     * @param id
     * @returns {Promise}
     */
    deleteWineHouse(id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/wine-houses/${id}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Store the wine house logo.
     *
     * @param id
     * @param {File} file
     * @returns {Promise}
     */
    storeWineHouseLogo(wine_house_id, file) {
        // Set formdata.
        const formData = new FormData();
        formData.append('wine_house_logo', file);

        return new Promise(function(resolve, reject) {
            axios.post(`/api/wine-houses/${wine_house_id}/logo`, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
}
