<template>
    <button @click="favoritesEnabled = ! favoritesEnabled" class="w3-button w3-button--padding-large w3-button--primary-outline" :class="{ 'w3-button--active': favoritesEnabled }">
        <input type="checkbox" v-model="favoritesEnabled">
        Favorieten
    </button>
</template>

<script>
    import { Component } from 'vue-instantsearch';

    export default {
        mixins: [ Component ],
        data() {
            return {
                favoritesEnabled: false,
            }
        },
        created() {
            this.searchStore.addFacet('id', 'or');
        },
        mounted() {
            const params = new URLSearchParams(document.location.search);

            if (params.get('favorites') === 'true') {
                this.favoritesEnabled = true;
            }
        },
        computed: {
            favoriteIds() {
                return this.$store.getters.favoriteIds;
            },
            facetIsActive() {
                return this.searchStore._helper.getRefinements('id').length > 0;
            }
        },
        methods: {
            setFacet() {
                // Remove filter to cleanup
                this.searchStore._helper.removeDisjunctiveFacetRefinement('id');

                // Set facets manually so that only one request is used
                this.searchStore._helper.state.disjunctiveFacetsRefinements.id = this.favoriteIds;
                this.searchStore._helper.search();
            }
        },
        watch: {
            favoritesEnabled(newValue) {
                if (newValue === true) {
                    this.setFacet()
                } else {
                    this.searchStore._helper.removeDisjunctiveFacetRefinement('id')
                }
            },
            favoriteIds() {
                if (! this.favoritesEnabled) {
                    return;
                }

                this.setFacet()
            },
            facetIsActive(isActive) {
                if (isActive) this.favoritesEnabled = true;
            }
        },
    }
</script>

<style>

</style>
