<template>
    <div class="w3-main-content">
        <h1 class="w3-header w3-header--primary">
            Bestelhistorie
        </h1>
        <div class="columns">
            <div class="column">
                <div class="card">
                    <div class="card-content">

                        <order-history-list ref="orderHistoryList"></order-history-list>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import OrderHistoryList from "../components/OrderHistoryList";

    export default {
        components: {
            OrderHistoryList
        },
        data() {
            return {
            };
        },
        methods: {

        }
    };
</script>

<style>
</style>
