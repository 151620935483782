<template>
    <div class="dropdown w3-dropdown"
         :class="{ 'is-active': isActive }">
        <div class="dropdown-trigger w3-dropdown-trigger"
             ref="trigger">
            <button class="w3-button w3-button--primary-outline"
                    :class="{ 'w3-button--active': facetIsActive }"
                    aria-haspopup="true"
                    @click="toggleDropdown">
                <span v-text="title"></span>

                <span class="icon is-small">
                    <i class="fas fa-caret-down"
                       aria-hidden="true"></i>
                </span>
            </button>
        </div>
        <div class="dropdown-menu"
             role="menu"
             ref="dropdownMenu">
            <div class="dropdown-content w3-dropdown-content">
                <span class="w3-header w3-header--quaternary m-b-10 is-block"
                      v-text="title"></span>

                <span class="w3-dropdown-content__close-btn"
                      @click="closeDropDown"
                      ref="closeButton">
                    <i class="fal fa-times"></i>
                </span>

                <div>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Component } from "vue-instantsearch";

export default {
    mixins: [ Component ],
    props: {
        algoliaAttributeName: {
            type: String,
        },
        title: {
            type: String
        }
    },
    data() {
        return {
            isActive: false
        };
    },
    computed: {
        facetIsActive() {
            return this.searchStore._helper.getRefinements(this.algoliaAttributeName).length > 0;
        }
    },
    methods: {
        isInWhiteList(element) {
            if (element === this.$refs.closeButton) return false;
            if (element === this.$refs.dropdownMenu) return true;
            if (element === this.$refs.trigger) return true;
            // All chidren from dropdown
            if (this.$refs.dropdownMenu !== undefined) {
                const children = this.$refs.dropdownMenu.querySelectorAll("*");
                for (const child of children) {
                    if (element === child) {
                        return true;
                    }
                }
            }
            // All children from trigger
            if (this.$refs.trigger !== undefined) {
                const children = this.$refs.trigger.querySelectorAll("*");
                for (const child of children) {
                    if (element === child) {
                        return true;
                    }
                }
            }

            return false;
        },
        clickedOutside(event) {
            if (!this.isInWhiteList(event.target)) this.closeDropDown();
        },
        closeDropDown() {
            if (this.isActive === false) {
                return;
            }

            this.isActive = false;
            this.$emit("dropdown-closed");
        },
        openDropDown() {
            if (this.isActive === true) {
                return;
            }

            this.isActive = true;
            this.$emit("dropdown-opened");
        },
        toggleDropdown() {
            this.isActive = !this.isActive;

            if (this.isActive === true) {
                this.$emit("dropdown-opened");
                return;
            }

            this.$emit("dropdown-closed");
        }
    },
    created() {
        document.addEventListener("click", this.clickedOutside);
    }
};
</script>

<style>
</style>
