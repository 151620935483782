<template>
    <div class="w3-admin-main-form">
        <form @submit.prevent="submitChangePassword">
            <div class="w3-admin-hero">
                <h2 class="w3-admin-hero__title">Wachtwoord aanpassen</h2>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Huidig wachtwoord</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Huidig wachtwoord">
                        <b-input type="password" v-model="form.current_password"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Nieuw wachtwoord</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Nieuw wachtwoord">
                        <b-input type="password" v-model="form.new_password"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Nieuw wachtwoord (controle)</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Nieuw wachtwoord (controle)">
                        <b-input type="password" v-model="form.new_password_validation"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="notification is-success" v-show="is_saved">
                <button class="delete" @click="is_saved = false"></button>
                Nieuwe wachtwoord is opgeslagen!
                <i class="far fa-thumbs-up"></i>
            </div>

            <div class="notification is-danger" v-show="is_failed">
                <button class="delete" @click="is_failed = false"></button>
                <i class="far fa-thumbs-down"></i>
                {{ failed_message }}
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">

                </div>
                <div class="w3-admin-main-form__input">
                    <button class="button is-primary w3-admin-hero__button">
                        Wachtwoord aanpassen
                    </button>
                </div>
            </div>



        </form>
    </div>
</template>

<script>
    import { reject } from 'lodash-es';
    import MyAccountApi from "./../api/my-account";

    export default {
        props: [
            'user'
        ],

        data() {
            return {
                form: {
                    current_password: '',
                    new_password: '',
                    new_password_validation: ''
                },
                is_saved: false,
                is_failed: false,
                failed_message: '',
            };
        },
        methods: {
            submitChangePassword() {
                MyAccountApi.updatePassword(this.user.id, this.form)
                    .then((data) => {
                        this.resetForm();
                        this.is_saved = true;
                    })
                    .catch((error) => {
                        this.resetForm();
                        this.is_failed = true;
                        this.failed_message = error.response.data.message;
                    });
            },
            resetForm() {
                this.form.current_password = '';
                this.form.new_password = '';
                this.form.new_password_validation = '';
                this.is_saved = false;
                this.is_failed = false;
                this.failed_message = '';
            }
        }
    };
</script>

<style>
</style>
