<template>
    <div class="w3-admin-main-form full-width">
        <div class="w3-admin-hero">
            <h1 class="w3-admin-hero__title">Adressen</h1>
            <button class="button is-primary w3-admin-hero__button"
                    @click="isCreateAddressActive = true">Nieuw adres aanmaken
            </button>
        </div>

        <div class="columns m-t-15">
            <div class="column">
                <div class="card">
                    <div class="card-content">
                        <address-list></address-list>

                        <b-modal :active.sync="isCreateAddressActive">
                            <div class="card">
                                <div class="card-content">
                                    <address-create-form @redirect="createAddressRedirect($event)"></address-create-form>
                                </div>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AddressCreateForm from './../components/AddressCreateForm';
    import AddressList from './../components/AddressList';

    export default {
        components: {
            AddressCreateForm,
            AddressList
        },

        data() {
            return {
                isCreateAddressActive: false,
            };
        },
        mounted() {
        },
        methods: {
            createAddressRedirect(url) {
                window.location.href = url;
            }
        }
    };
</script>

<style>
</style>
