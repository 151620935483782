<template>
    <div>
        <b-field :label="label">
            <b-autocomplete
                icon="search"
                rounded
                v-model="name"
                :data="data"
                placeholder="Zoek op klant.."
                field="title"
                :loading="isFetching"
                @input="getAsyncData"
                @select="option => addCustomer(option)">

                <template slot="header">
                    <a @click="newCustomer">
                        <span> Nieuwe klant aanmaken</span>
                    </a>
                </template>

                <template slot-scope="props">
                    <div class="media">
                        <div class="media-content">
                            {{ props.option.name }}
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>
    </div>
</template>

<script>
    import { debounce } from 'lodash-es';
    import CustomerApi from "./../api/customer";

    export default {
        props: [
            'label'
        ],

        data() {
            return {
                data: [],
                name: '',
                selected: null,
                isFetching: false
            }
        },
        methods: {
            getAsyncData: debounce(function () {
                this.data = [];
                this.isFetching = true;

                let that = this;
                CustomerApi.searchCustomers(this.name)
                    .then(function(data) {
                        that.data = data.data.data;
                        that.isFetching = false;
                    })
                    .catch(function(error) {
                        console.log(error);
                        that.isFetching = false;
                    });
            }, 300),

            addCustomer(customer) {
                this.$emit('add-customer', customer);
            },

            newCustomer() {
                this.$dialog.prompt({
                    message: 'Nieuwe klant',
                    inputAttrs: {
                        maxlength: 250,
                        value: '',
                    },
                    confirmText: 'Toevoegen',
                    onConfirm: (value) => {
                        let that = this;
                        CustomerApi.storeCustomer({ name: value })
                                   .then(function(data) {
                                       that.$emit('add-customer', data.customer);
                                   })
                                   .catch(function(error) {
                                       console.log(error);
                                   });
                    }
                })
            },
        }
    };
</script>
