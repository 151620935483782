<template>
    <div>
        <cart-add-product-modal :active.sync="isCartAddModalActive"
                                v-bind="{ productId, orderUnit, productTitle, imageUrl, stock }"></cart-add-product-modal>

        <button type="button" :class="buttonClass"
                @click="openCartAddProductModal()">
            <slot></slot>
        </button>
    </div>
</template>

<script>
import CartAddProductModal from "../components/CartAddProductModal";

export default {
    components: {
        CartAddProductModal
    },
    props: {
        productId: Number,
        orderUnit: Number,
        productTitle: {
            type: String
        },
        imageUrl: {
            type: String
        },
        stock: {
            type: Number,
            required: false
        },
        buttonClass: {
            type: String
        }
    },
    data() {
        return {
            isCartAddModalActive: false
        }
    },
    methods: {
        openCartAddProductModal() {
            this.isCartAddModalActive = true;

            // this.$modal.open({
            //     parent: this,
            //     component: CartAddProductModal,
            //     hasModalCard: true,
            //     props: {
            //         productId: this.productId,
            //         orderUnit: this.orderUnit,
            //         stock: this.stock
            //     }
            // });
        }
    }
};
</script>

<style>
</style>
