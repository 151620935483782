<template>
    <form @submit.prevent="submitAddressCreate" style="min-height: 300px;">

        <customer-input-autocomplete
            v-if="!customer"
            @add-customer="selectCustomer($event)"
            label="Adres voor klant">
        </customer-input-autocomplete>

        <div class="address-input-fields" v-if="customer">

            <b-field label="Klant">
                <b-input v-model="customer.name" disabled></b-input>
            </b-field>
            <div><a @click="customer = ''"><small >Wijzigen</small></a></div>

            <div class="columns m-t-15">
                <div class="column">
                    <b-field label="Naam">
                        <b-input v-model="form.full_name" placeholder="Naam"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Soort Adres">
                        <b-select placeholder="Selecteer een soort" v-model="form.address_type_id" expanded>
                            <option
                                v-for="type in address_types"
                                :value="type.id"
                                :key="type.id">
                                {{ type.title }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </div>

            <b-field label="Adres">
                <b-input v-model="form.address1" placeholder="Adres"></b-input>
            </b-field>
            <b-field v-if="form.country_iso_2 !== 'NL'">
                <b-input v-model="form.address2" placeholder="Adres regel 2"></b-input>
            </b-field>
            <b-field v-if="form.country_iso_2 !== 'NL'">
                <b-input v-model="form.address3" placeholder="Adres regel 3"></b-input>
            </b-field>

            <div class="columns">
                <div class="column">
                    <b-field label="Huisnummer">
                        <b-input v-model="form.house_number" placeholder="b.v. 15"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Toevoeging">
                        <b-input v-model="form.house_number_addition" placeholder="Toev."></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Postcode">
                        <b-input v-model="form.zipcode" placeholder="b.v. 1234 AB"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <b-field label="Plaats">
                        <b-input v-model="form.city" placeholder="b.v. Utrecht"></b-input>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Land" >
                        <b-select placeholder="Kies een land" v-model="form.country_iso_2" expanded>
                            <option
                                value="NL">
                                Nederland (NL)
                            </option>

                            <option
                                value="BE">
                                Belgie (BE)
                            </option>
                        </b-select>
                    </b-field>
                </div>
            </div>

            <button class="button is-primary"
                    type="submit">
                Opslaan
            </button>
        </div>

    </form>
</template>

<script>
    import AddressApi from "./../api/address";
    import CustomerInputAutocomplete from "./CustomerInputAutocomplete";

    export default {
        components: {
            CustomerInputAutocomplete,
        },

        props: [
            'customerData'
        ],

        data() {
            return {
                form: {
                    address_type_id: 1,
                    full_name: '',
                    customer_id: '',
                    address1: '',
                    address2: '',
                    address3: '',
                    house_number: '',
                    house_number_addition: '',
                    zipcode: '',
                    city: '',
                    country_iso_2: 'NL',
                },
                address_types: [],
                customer: '',
            };
        },
        methods: {
            submitAddressCreate() {
                let that = this;
                AddressApi.storeAddress(this.form)
                          .then(function(data) {
                              that.$emit('redirect', data.redirect_to)
                          })
                          .catch(function(error) {
                              console.log(error);
                          });
            },

            getAddressTypes() {
                let that = this;
                AddressApi.getAddressTypes()
                          .then(function(data) {
                              that.address_types = data.data;
                          })
                          .catch(function(error) {
                              console.log(error);
                          });
            },

            selectCustomer(customer) {
                this.customer = customer;
                this.form.customer_id = customer.id;
            }
        },

        created() {
            this.getAddressTypes();
            if(this.customerData) {
                this.customer = this.customerData;
                this.form.customer_id = this.customerData.id;
            }
        }

    };
</script>

<style>
</style>
