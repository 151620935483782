<template>
    <div>
        <b-table
            :data="isEmpty ? [] : users" :default-sort="['name', 'desc']">

            <template slot-scope="props">
                <b-table-column field="name" label="Naam" sortable>
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column field="email" label="Email">
                    {{ props.row.email }}
                </b-table-column>

                <b-table-column field="action">
                    <span class="button is-danger is-small" @click="deleteUser(props.row.id)">
                        <i class="fas fa-times"></i>
                    </span>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-if="loading === false">
                    <div class="content has-text-grey has-text-centered">
                        <p>Geen gebruikers gekoppeld...</p>
                    </div>
                </section>
            </template>
        </b-table>

        <customer-add-user @add-user="addUser($event)"></customer-add-user>
    </div>
</template>

<script>
    import CustomerAddUser from "./CustomerAddUser";

    export default {
        components: {
            CustomerAddUser,
        },

        props: [
            'users'
        ],

        data() {
            return {
                loading: false,
                isEmpty: false,
            };
        },
        methods: {
            addUser(user) {
                this.$emit('add-user', user)
            },

            deleteUser(id) {
                this.$emit('delete-user', id);
            },
        }
    };
</script>
