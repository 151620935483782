export default {
    /**
     * Get Orders.
     *
     * @param {number=} page
     * @returns {Promise}
     */
    getOrders(page) {
        if (! page) {
            page = 1;
        }

        return new Promise(function(resolve, reject) {
            axios.get(`/api/orders?page=${page}`)
                .then(function(response) {
                    resolve(response.data);
                })
                .catch(function(error) {
                    reject(error);
                });
        })
    },

    /**
     * Get Order
     *
     * @param id
     * @returns {Promise}
     */
    getOrder(id) {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/orders/${id}`)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
}
