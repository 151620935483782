<template>
 <div>
     <div class="w3-invoice-addresses active" v-if="invoice_addresses.length > 0">
         <div class="radio-button active"></div>
         <div class="address-box">
             <div class="fullname">
                 {{ invoice_addresses[0].full_name }}
            </div>
             <div class="address">
                 {{ invoice_addresses[0].address1 }} {{ invoice_addresses[0].house_number }} {{ invoice_addresses[0].address2 }} {{ invoice_addresses[0].address3 }}
            </div>
             <div class="postcode-city">
                 {{ invoice_addresses[0].zipcode }}, {{ invoice_addresses[0].city }}
            </div>
             <div class="country">
                 {{ invoice_addresses[0].country_iso_2 }}
            </div>
         </div>
         <div class="edit-address" @click="editAddress">
             <i class="far fa-pencil-alt"></i>
         </div>
     </div>
     <div v-else>
         <invoice-address-modal v-if="loading === false" @success="getInvoiceAddress()" :status="'new'"></invoice-address-modal>
     </div>
     <invoice-address-modal @success="getInvoiceAddress()" :invoice-address="invoice_addresses[0]" :open="edit" :status="'edit'"></invoice-address-modal>
 </div>
</template>

<script>
    import AddressApi from "./../api/address";
    import InvoiceAddressModal from './InvoiceAddressModal';

    export default {
        name: 'InvoiceAddress',
        components: {
            InvoiceAddressModal,
        },
        data() {
            return {
                addresses: [],
                edit: false,
                loading: true,
            }
        },
        methods: {
            getInvoiceAddress() {
                this.edit = false;
                let that = this;
                AddressApi.getAddressesByCustomer()
                          .then(function(data) {
                              that.addresses = data.data;
                              that.loading = false;
                              that.$nextTick(() => {
                                  if(that.invoice_addresses.length > 0) {
                                      that.$emit('invoice-address-id', that.invoice_addresses[0].id);
                                  }
                              });
                          })
                          .catch(function(error) {
                              console.log(error);
                          });
            },
            editAddress() {
                this.edit = true;
            }
        },
        computed: {
            invoice_addresses() {
                return this.addresses.filter(function (el) {
                    return el.address_type_id === 2;
                }).reverse();
            }
        },
        created() {
            this.getInvoiceAddress();
        }
    }
</script>

<style>

</style>
