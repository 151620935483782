export default {
    /**
     * Get the current user.
     *
     * @returns {Promise}
     */
    getCurrentUser() {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/users/current`)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Store a user.
     *
     * @param name
     * @param email
     * @param phone
     * @param password
     * @returns {Promise}
     */
    storeUser({ name, email, phone, password }) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/users', { name, email, phone, password })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Update a user.
     *
     * @param id
     * @param name
     * @param email
     * @param phone
     * @param customers
     * @returns {Promise}
     */
    updateUser(id, { name, email, phone, customers }) {
        return new Promise(function(resolve, reject) {
            axios.put(`/api/users/${id}`, { name, email, phone, customers })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Delete a user.
     *
     * @param id
     * @returns {Promise}
     */
    deleteUser(id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/users/${id}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Get a list of users.
     *
     * @param page
     * @param search
     * @returns {Promise}
     */
    getUsers(page, search) {
        if (!page) {
            page = 1;
        }

        let url = `/api/users/?page=${page}`;
        if (search) {
            url += `&search=${search}`
        }

        return new Promise(function(resolve, reject) {
            axios.get(url)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Search users by keyword
     *
     * @param key
     * @returns {Promise}
     */
    searchUsers(key) {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/users/search?key=${key}`)
                .then(function(response) {
                    resolve(response);
                })
                .catch(function(error) {
                    reject(error);
                });
        })
    },

    /**
     *  Store customer for user
     * @param id
     * @returns {Promise}
     */
    setCustomer(id) {
        return new Promise(function(resolve, reject) {
            axios.post(`/api/user/customer/${id}`)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    }
}
