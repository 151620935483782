<template>
    <div class="w3-search-current-filter">
        <div class="w3-badge w3-badge--block w3-badge--secondary w3-search-current-filter__badge"
             v-for="refinement in currentRefinements"
             @click="removeRefinement(refinement.facet, refinement.value)">
            <span class="w3-search-current-filter__text" v-text="refinement.value"></span>
            <i class="fas fa-times"></i>
        </div>
    </div>
</template>

<script>
    import { Component } from 'vue-instantsearch';

    export default {
        mixins: [Component],
        computed: {
            currentRefinements() {
                let refinements = [];

                Object.keys(this.searchStore._helper.state.disjunctiveFacetsRefinements).forEach(facet => {
                    if (facet === 'id') {
                        return;
                    }

                    this.searchStore._helper.state.disjunctiveFacetsRefinements[facet].forEach(value => {
                        refinements.push({
                            facet,
                            value
                        })
                    })
                });

                return refinements;
            }
        },
        methods: {
            removeRefinement(facet, value) {
                this.searchStore._helper.removeDisjunctiveFacetRefinement(facet, value)
            }
        },
    }
</script>

<style>

</style>
