<template>
    <div id="Dashboard">
        <div class="w3-main-content">
            <div class="columns is-mobile is-multiline">
                <dashboard-orders></dashboard-orders>

                <dashboard-favorites></dashboard-favorites>

                <dashboard-highlighted-product></dashboard-highlighted-product>

                <div class="column column-eq-height is-12-mobile is-6-tablet is-6-desktop is-3-fullhd">
                    <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--contact-person is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
                        <div class="columns is-gapless is-full-height">
                            <div class="column">
                                <div
                                        class="w3-card--contact-person__image"
                                        style="background-image: url(/img/w3-wijnen-contact.webp);">
                                </div>
                            </div>
                            <div class="column">
                                <div class="w3-card--content">
                                    <h3 class="w3-header w3-header--tertiary w3-header--primary m-b-30">Contact</h3>
                                    <a href="mailto:info@w3wijnen.nl">info@w3wijnen.nl</a>
                                    <div class="m-t-5">
                                        <small>W3 Wijnen B.V.</small><br />
                                        <small>Archimedesbaan 18a</small><br />
                                        <small>3439 ME Nieuwegein</small>
                                    </div>
                                    <div class="m-t-20">
                                    </div>
                                    <strong>Judith Blume</strong>
                                    <div class="m-t-0">
                                        <small>0031 6 223 78 986</small>
                                    </div>
                                    <div class="m-t-20">
                                    </div>
                                    <strong>Wesley van Dinteren</strong>
                                    <div class="m-t-0">
                                        <small>0031 6 10 25 15 21</small>
                                    </div>
                                    <div class="m-t-5">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="column column-eq-height is-12-mobile is-6-tablet is-4-desktop is-3-fullhd">
                    <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--content is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
                        <h3 class="w3-header w3-header--tertiary w3-header--primary m-b-30">Kurkensaldo</h3>
                        <div class="w3-dashboard-loyalty">
                            <div class="w3-dashboard-loyalty__points">0</div>
                            <div class="w3-dashboard-loyalty__redeem-button">
                                <a href="/" class="w3-button w3-button--primary">punten inwisselen</a>
                            </div>
                        </div>
                    </div>
                </div>

                <dashboard-popular-products></dashboard-popular-products>

                <dashboard-popular-wine-houses></dashboard-popular-wine-houses>

            </div>
        </div>
    </div>
</template>
<script>
    import DashboardHighlightedProduct from '../components/DashboardHighlightedProduct';
    import DashboardOrders from '../components/DashboardOrders';
    import DashboardFavorites from '../components/DashboardFavorites';
    import DashboardPopularProducts from '../components/DashboardPopularProducts';
    import DashboardPopularWineHouses from '../components/DashboardPopularWineHouses';

    export default {
        components: {
            DashboardHighlightedProduct,
            DashboardOrders,
            DashboardFavorites,
            DashboardPopularProducts,
            DashboardPopularWineHouses,
        },
        data() {
            return {
                data_field: 10
            }
        },
        computed: {
            computed_data_field: function () {
                return 1 + 2;
            }
        },
        methods: {
            someFunction: function () {

            }
        },
        mounted() {

        },
        watch: {
            data_field() {

            }
        }
    }
</script>
