<template>
    <div class="columns is-centered">
        <div class="column is-6 m-t-100">
            <div class="card">
                <div class="card-content">
                    <h1 class="title">Selecteer klant</h1>
                    <select-customer-form redirect-url="/"></select-customer-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SelectCustomerForm from './../components/SelectCustomerForm';

    export default {
        components: {
            SelectCustomerForm,
        },
    };
</script>

<style>
</style>
