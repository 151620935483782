<template>
    <div class="">
        <div class="w3-cart__items" v-if="cart.items.length > 0">
            <div v-for="item in cart.items" class="w3-cart-item" :key="item.id">

                <a :href="`/products/${item.product.id}`" class="w3-cart-item__thumbnail">
                    <img :src="item.product.default_image"
                         alt="" class="w3-cart-item__image">
                </a>

                <div class="w3-cart-item__info">
                    <div class="w3-cart-item__title has-text-weight-semibold is-size-6">
                        <a :href="`/products/${item.product.id}`">{{ item.product.title }}</a>
                    </div>

                    <div class="w3-cart-item__reference has-text-grey-light">
                        Referentie: {{ item.product.reference }} - Stukprijs: {{ item.product.customer_price_tax_excl | currency }}
                    </div>

                    <cart-quantity-input :value="item.quantity"
                                         :step="item.product.order_unit"
                                         :item-id="item.id" class="w3-cart-item__quantity"></cart-quantity-input>

                    <div v-if="item.sold_outside_stock">
                        Let op! Er is onvoldoende voorraad beschikbaar op dit moment.
                        Het gekozen aantal ({{item.quantity}}) ligt hoger dan de beschikbare voorraad ({{item.product.stock_available}}).
                        Wij nemen z.s.m. contact op voor een passende oplossing.
                    </div>
                </div>

                <div class="w3-cart-item__price">
                    {{ item.total_price | currency }}
                </div>

                <div class="w3-cart-item__delete-item">
                    <button class="button is-danger is-outlined w3-cart-item__delete-button"
                            @click="deleteCartItem(item.id)">
                        Verwijderen
                    </button>
                </div>
            </div>
        </div>
        <div class="w3-cart__empty" v-else>
            <div class="w3-cart__empty__box">
                <div class="content">
                    <p class="text">
                        Er zit (nog) geen product in je winkelwagen.
                    </p>
                    <p>
                        Mocht je vragen hebben, dan kun je ons tijdens kantoortijden via de telefoon bereiken of een email sturen via ons contactformulier.
                   </p>

                </div>
            </div>

        </div>


        <cart-totals v-if="cart.items.length > 0"></cart-totals>

        <slot v-if="cart.items.length > 0"></slot>
    </div>
</template>

<script>
    import CartQuantityInput from '../components/CartQuantityInput';
    import CartTotals from '../components/CartTotals';

    export default {
        components: {
            CartQuantityInput,
            CartTotals
        },
        computed: {
            cart() {
                return this.$store.state.cart;
            }
        },
        methods: {
            deleteCartItem(item_id) {
                this.$store.dispatch('deleteItemFromCart', item_id)
            }
        },
    }
</script>

<style>

</style>
