<template>
    <div id="w3-search">
        <h1 class="w3-header w3-header--primary">
            Ons assortiment
        </h1>
        <ais-index
            :app-id="algolia_app_id"
            :api-key="algolia_app_key"
            :index-name="algolia_index_name"
        >

            <span class="w3-button w3-button--primary w3-search__open-filters" @click="showMobileFilters">Filters</span>

            <div class="w3-search__filters" v-show="showFilter()">
                <div class="w3-search-filters__header">
                    <span class="w3-header w3-header--secondary">Filters</span>

                    <div class="w3-search-filters__close" @click="hideMobileFilters">
                        <i class="fal fa-times w3-search-filters__close-icon"></i>
                    </div>
                </div>

                <div class="columns is-marginless--bottom is-mobile is-multiline">
                    <div class="column is-narrow is-12-mobile">
                        <div class="w3-search-box-group">
                            <ais-search-box class="w3-search-box" :autofocus="true"
                                            placeholder="typ hier uw zoekterm"></ais-search-box>
                            <i class="fas fa-search w3-search-box-group__icon"></i>
                        </div>
                    </div>
                    <div class="column is-narrow is-12-mobile">
                        <search-dropdown :title="'land / gebied'"
                                         @dropdown-opened="ui.dropdowns.countryActive = true"
                                         @dropdown-closed="ui.dropdowns.countryActive = false">
                            <country-region-selection></country-region-selection>
                        </search-dropdown>
                    </div>
                    <div class="column is-narrow is-12-mobile">
                        <search-dropdown title="land"
                                         @dropdown-opened="ui.dropdowns.countryActive = true"
                                         @dropdown-closed="ui.dropdowns.countryActive = false">
                            <ais-refinement-list attribute-name="country" :limit="100" :sort-by="['isRefined', 'name:asc']"></ais-refinement-list>
                        </search-dropdown>
                    </div>
                    <div class="column is-narrow is-12-mobile">
                        <search-dropdown title="gebied"
                                         @dropdown-opened="ui.dropdowns.regionActive = true"
                                         @dropdown-closed="ui.dropdowns.regionActive = false">
                            <ais-refinement-list attribute-name="region_title" :limit="100" :sort-by="['isRefined', 'name:asc']"></ais-refinement-list>
                        </search-dropdown>
                    </div>
                    <div class="column is-narrow is-12-mobile">
                        <search-dropdown title="wijnhuizen"
                                         @dropdown-opened="ui.dropdowns.wineHouseActive = true"
                                         @dropdown-closed="ui.dropdowns.wineHouseActive = false">
                            <ais-refinement-list attribute-name="winehouse_title" :limit="100" :sort-by="['isRefined', 'name:asc']"></ais-refinement-list>
                        </search-dropdown>
                    </div>
                    <div class="column is-narrow is-12-mobile">
                        <search-dropdown title="wijnsoorten"
                                         @dropdown-opened="ui.dropdowns.wineTypeActive = true"
                                         @dropdown-closed="ui.dropdowns.wineTypeActive = false">
                            <ais-refinement-list attribute-name="Wijnsoort" :limit="100" :sort-by="['isRefined', 'name:asc']"></ais-refinement-list>
                        </search-dropdown>
                    </div>

                    <div class="column is-narrow is-12-mobile">
                        <search-favorites-toggle></search-favorites-toggle>
                    </div>

                </div>
            </div>

            <div class="w3-card w3-card--rounded w3-card--content" v-if="notificationText !== ''" v-html="notificationText">
            </div>

            <search-current-refinements></search-current-refinements>

            <div class="w3-search-stats">
                <ais-stats>
                    <template slot-scope="{ totalResults, processingTime, query, resultStart, resultEnd }">
                        <h3 class="w3-header--quaternary w3-header--color-primary w3-header--margin-2">
                            {{ totalResults }} wijnen voldoen aan uw selectie</h3>
                    </template>
                </ais-stats>
            </div>

            <search-store-mutator/>

            <div class="product-list">
                <div class="w3-overlay w3-overlay--product-list is-overlay" v-show="showOverlay"></div>
                <ais-results class="columns is-multiline search-results">
                    <template slot-scope="{ result }">

                        <div class="column is-6 is-4-desktop is-3-fullhd search-results__item">
                            <div class="w3-card w3-card--product w3-card--rounded">
                                <div class="w3-card--product__icons">
                                    <i class="fas fa-heart w3-card--product__heart-icon"
                                       :class="{ 'w3-card--product__heart-icon--active': favoriteIds.indexOf(result.id) !== -1 }"
                                       @click="toggleFavorite(result.id)"></i>
                                    <i :class="getColorClass(result.color)"></i>
                                </div>

                                <a :href="`/products/${result.id}`">
                                    <img :src="result.image_url" class="w3-card--product__img"/>
                                </a>

                                <div class="w3-card--product__title-block">
                                    <a :href="`/products/${result.id}`">
                                        <strong class="w3-header w3-header--product">
                                            <ais-highlight
                                                :result="result"
                                                attribute-name="title"></ais-highlight>
                                        </strong>
                                    </a>
                                </div>
                                <div class="w3-card--product__content">
                                    <ul class="w3-product-attributes">
                                        <li class="w3-product-attributes__item">
                                            <i class="fas fa-box w3-product-attributes__item__icon"></i>
                                            {{ result.reference }}
                                        </li>
                                        <li class="w3-product-attributes__item">
                                            <i class="fas fa-flag w3-product-attributes__item__icon"></i>
                                            {{ result.country }}
                                        </li>
                                        <li class="w3-product-attributes__item"><i
                                            class="fas fa-map-marker-alt w3-product-attributes__item__icon"></i> {{
                                            result.region_title }}
                                        </li>
                                        <li class="w3-product-attributes__item">
                                            <i class="fas fa-home w3-product-attributes__item__icon"></i>
                                            {{ result.winehouse_title }}
                                        </li>
                                        <li class="w3-product-attributes__item">
                                            <i class="fas fa-wine-bottle w3-product-attributes__item__icon"></i>
                                            {{ result.stock_available }} op voorraad
                                        </li>
                                    </ul>

                                    <cart-product-amount-tag @click="clickAmountTag(result.id)"
                                                             :product-id="result.id"></cart-product-amount-tag>

                                    <span class="w3-product-price">
                                        <span v-text="getProductPrice(result.id)"></span>
                                        <span class="w3-product-price__option">per fles</span>
                                    </span>
                                </div>
                                <div class="w3-card--product__buttons">
                                    <a :href="`/products/${result.id}`" class="w3-button w3-button--primary-outline">meer
                                        info</a>
                                    <button v-if="result.stock_available> 0" class="w3-button w3-button--primary"
                                            @click="openCartAddProductModal(result.id, result.order_unit, result.stock_available, result.image_url, result.title)">
                                        winkelwagen <i class="fas fa-plus w3-card--product__add-to-cart-icon"></i>
                                    </button>
                                    <button v-else class="w3-button w3-button--disabled" disabled>niet op voorraad
                                    </button>

                                    <a :href="`/admin/products/${result.id}/edit`" target="_blank" class="w3-button"
                                       v-if="is_admin"><i class="fas fa-edit w3-product-attributes__item__icon"></i></a>
                                </div>
                            </div>
                        </div>

                    </template>
                </ais-results>
            </div>

            <ais-pagination class="w3-search-pagination"></ais-pagination>

        </ais-index>

        <cart-add-product-modal v-if="Object.keys(currentAddToCartItem).length !== 0" :active.sync="isCartAddModalActive"
                                v-bind="currentAddToCartItem"></cart-add-product-modal>

                                <cart-update-product-modal v-if="currentItemId !== null" :item-id="currentItemId" :active.sync="isCartUpdateModalActive"></cart-update-product-modal>

    </div>
</template>
<script>
    import SearchFavoritesToggle from '../components/SearchFavoritesToggle';
    import SearchCurrentRefinements from '../components/SearchCurrentRefinements';
    import CartAddProductModal from '../components/CartAddProductModal';
    import CartUpdateProductModal from '../components/CartUpdateProductModal';
    import CartProductAmountTag from "../components/CartProductAmountTag";
    import SearchDropdown from "../components/SearchDropdown";
    import SearchStoreMutator from "../components/SearchStoreMutator";
    import CountryRegionSelection from "../components/CountryRegionSelection";
    import { convertWineColorStringToClass } from "../utilities/color";

    export default {
        components: {
            CountryRegionSelection,
            SearchDropdown,
            CartAddProductModal,
            CartUpdateProductModal,
            CartProductAmountTag,
            SearchFavoritesToggle,
            SearchCurrentRefinements,
            SearchStoreMutator
        },
        data() {
            return {
                algolia_app_id: window.Laravel.algolia.app_id,
                algolia_app_key: window.Laravel.algolia.app_key,
                algolia_index_name: window.Laravel.algolia.index_products,
                is_admin: window.Laravel.isAdmin,
                activeDropdown: null,
                ui: {
                    mobileFilterOverlayIsActive: false,
                    dropdowns: {
                        countryActive: false,
                        regionActive: false,
                        wineHouseActive: false,
                        wineTypeActive: false,
                    }
                },
                productPrices: [],
                isCartAddModalActive: false,
                isCartUpdateModalActive: false,
                currentItemId: null,
                currentAddToCartItem: {},
            }
        },
        props: {
            notificationText: ''
        },
        created() {
            axios.get('/api/products/prices').then(response => {
                this.productPrices = response.data;
            })
        },
        computed: {
            favoriteIds() {
                return this.$store.getters.favoriteIds;
            },
            showOverlay() {
                let showOverlay = false;
                let test = Object.keys(this.ui.dropdowns).forEach((dropdown) => {
                    if (this.ui.dropdowns[dropdown] === false) {
                        return;
                    }
                    showOverlay = true
                })

                return showOverlay;
            }
        },
        methods: {
            toggleDropdown: function(type) {
                if (this.activeDropdown === type) {
                    this.activeDropdown = null;
                    return;
                }
                this.activeDropdown = type;
            },
            addItemToCart(product_id, quantity) {
                this.$store.dispatch('addItemToCart', { product_id, quantity })
            },
            toggleFavorite(product_id) {
                if (this.favoriteIds.indexOf(product_id) === -1) {
                    this.$store.dispatch('addProductToFavorites', product_id);
                    return;
                }

                this.$store.dispatch('removeProductFromFavorites', product_id);
            },
            getColorClass(color) {
                return convertWineColorStringToClass(color);
            },
            getProductPrice(product_id) {
                const productPriceObj = this.productPrices.find(val => {
                    return val.product_id === product_id;
                });

                if (!productPriceObj) {
                    return;
                }

                return productPriceObj.base_price_display;
            },
            openCartAddProductModal(product_id, order_unit, stock, image_url, title) {
                this.currentAddToCartItem = {
                    productTitle: title,
                    productId: product_id,
                    orderUnit: order_unit,
                    imageUrl: image_url,
                    stock: stock
                };
                this.isCartAddModalActive = true;
                // this.$modal.open({
                //     parent: this,
                //     component: CartAddProductModal,
                //     hasModalCard: true,
                //     props: {
                //         productId: product_id,
                //         orderUnit: order_unit,
                //         stock: stock
                //     }
                // });
            },
            clickAmountTag(id) {
                const item = this.$store.getters.getItemByProductId(id);
                this.currentItemId = item.id;
                this.isCartUpdateModalActive = true;
            },
            showMobileFilters() {
                if (window.innerWidth <= 768) {
                    this.ui.mobileFilterOverlayIsActive = true;
                }
            },
            hideMobileFilters() {
                if (window.innerWidth <= 768) {
                    this.ui.mobileFilterOverlayIsActive = false;
                }
            },
            showFilter() {
                if (window.innerWidth <= 768) {
                    return this.ui.mobileFilterOverlayIsActive;
                }

                return true;
            },
        },
    }
</script>
