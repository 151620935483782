export default {
    /**
     * get regions.
     *
     * @param {Number=} page
     * @param {String=} title
     * @returns {Promise}
     */
    getRegions(page, title) {
        if (! page) {
            page = 1;
        }

        let url = `/api/regions?page=${page}`;

        if (title) {
            url += `&title=${title}`;
        }

        return new Promise(function(resolve, reject) {
            axios.get(url)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Store a region.
     *
     * @param {Object} param
     * @param {string} param.title
     * @param {string} param.country_iso
     * @returns {Promise}
     */
    storeRegion({ title, country_iso }) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/regions', { title, country_iso })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Update a region.
     *
     * @param id
     * @param {Object} param
     * @param {string} param.title
     * @param {string} param.removed_images
     * @returns {Promise}
     */
    updateRegion(id, { title, country_iso, removed_images }) {
        return new Promise(function(resolve, reject) {
            axios.put(`/api/regions/${id}`, { title, country_iso, removed_images })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Delete a region.
     *
     * @param id
     * @returns {Promise}
     */
    deleteRegion(id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/regions/${id}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    }
}
