<template>
    <div class="w3-admin-main-form full-width">
        <div class="w3-admin-hero">
            <h1 class="w3-admin-hero__title">Klanten</h1>
            <button class="button is-primary w3-admin-hero__button"
                    @click="isCreateCustomerActive = true">Nieuwe klant aanmaken
            </button>
        </div>

        <div class="columns m-t-15">
            <div class="column">
                <div class="card">
                    <div class="card-content">
                        <customers-list></customers-list>

                        <b-modal :active.sync="isCreateCustomerActive">
                            <div class="card">
                                <div class="card-content">
                                    <customer-create-form></customer-create-form>
                                </div>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomerCreateForm from './../components/CustomerCreateForm';
    import CustomersList from './../components/CustomersList';

    export default {
        components: {
            CustomerCreateForm,
            CustomersList
        },

        data() {
            return {
                isCreateCustomerActive: false,
            };
        },
        mounted() {
        },
        methods: {
        }
    };
</script>

<style>
</style>
