<template>
    <div>
        <b-field label="Titel">
            <b-input
                type="text"
                ref="title"
                v-model="form.title"
                placeholder="Title"
                required>
            </b-input>
        </b-field>

        <b-field label="Land">
            <b-autocomplete
                v-model="country"
                ref="autocomplete"
                :data="filteredCountries"
                placeholder="bijv. Nederland"
                field="name"
                open-on-focus
                @select="option => selected = option">

                <template slot="empty">No results for {{ country }}</template>
            </b-autocomplete>
        </b-field>
    </div>
</template>

<script>
    import { regionApi } from "../api";

    export default {
        data() {
            return {
                form: {
                    title: "",
                },
                countries: [],
                country: '',
                selected: null
            };
        },
        created() {
            this.getCountries();
        },
        computed: {
            filteredCountries() {
                return this.countries.filter((option) => {
                    return option.name
                                 .toString()
                                 .toLowerCase()
                                 .indexOf(this.country.toLowerCase()) >= 0
                })
            }
        },
        methods: {
            submitRegionCreate() {
                regionApi.storeRegion({ title: this.form.title , country_iso: this.selected.iso })
                            .then(data => {
                                this.$emit('region-created', data);
                            })
                            .catch(error => {
                                this.$emit('region-creation-failed', error);
                            });
            },
            resetForm() {
                this.form.title = '';
                this.form.selected = null;
                this.$refs.title.focus();
            },
            getCountries() {
                axios.get('/api/countries').then(response => {
                    this.countries = response.data;
                });
            }
        }
    };
</script>

<style>
</style>
