<template>
    <product-update-form :product="product" :product-colors="productColors"></product-update-form>
</template>

<script>
    import ProductUpdateForm from '../components/ProductUpdateForm';

    export default {
        props: {
            product: Object,
            productColors: Array,
        },
        components: {
            ProductUpdateForm
        }
    }
</script>

<style>

</style>
