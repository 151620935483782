<template>
    <div class="w3-modal"
         v-show="isActive">
        <div class="w3-modal__background"
             @click="closeModal()"></div>
        <div class="w3-modal-card">
            <div class="w3-modal-card__description-side">
                <h3 class="w3-modal-card__title">Toevoegen aan bestelling</h3>
                <p class="w3-modal-card__product-title"
                   v-text="productTitle"></p>

                <div class="cart-add-quantity-selector"
                     v-if="quantitySelectorActive">
                    <div v-for="defaultQuantity in defaultQuantities"
                         :key="defaultQuantity"
                         @click="quantity = defaultQuantity"
                         class="cart-add-quantity-selector__option">
                        <div class="w3-button w3-button--light-grey cart-add-quantity-selector__label"
                             :class="{ 'cart-add-quantity-selector__label--active': quantity === defaultQuantity}">
                            <span v-text="defaultQuantity"></span>
                            <span> flessen</span>
                        </div>
                    </div>
                    <div class="cart-add-quantity-selector__option">
                        <div class="w3-button w3-button--light-grey w3-button--text-light cart-add-quantity-selector__label"
                             @click="quantitySelectorActive = false">
                            meer...
                        </div>
                    </div>
                    <div class="cart-add-quantity-selector__option">
                        <div class="w3-button w3-button--primary cart-add-quantity-selector__label"
                             @click="addItemToCart()">
                            <i class="far fa-plus"></i> Toevoegen
                        </div>
                    </div>
                </div>

                <div v-else>
                    <b-input type="number"
                             v-model.number="quantity"
                             :step="orderUnit"
                             :max="stock"></b-input>

                    <div class="w3-button w3-button--primary m-t-25"
                         @click="addItemToCart()">
                        <i class="far fa-plus"></i> Toevoegen
                    </div>
                </div>

                <div class="cart-add-quantity-selector__oversold" v-if="quantity > stock">
                    Let op! Er is onvoldoende voorraad beschikbaar op dit moment.
                    Het gekozen aantal ({{quantity}}) ligt hoger dan de beschikbare voorraad ({{stock}}).
                    Wij nemen z.s.m. contact op voor een passende oplossing.
                </div>
            </div>

            <div class="w3-modal-card__image-side">
                <img :src="imageUrl"
                     class="w3-modal-card__image"
                     alt="">
            </div>

            <div class="w3-modal-card__close"
                 @click="closeModal()">
                <i class="fal fa-times w3-modal-card__close-icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        productId: Number,
        orderUnit: Number,
        active: {
            type: Boolean,
            default: false
        },
        stock: {
            type: Number,
            required: false
        },
        productTitle: {
            type: String,
            default: ""
        },
        imageUrl: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            quantity: this.orderUnit,
            isActive: this.active,
            quantitySelectorActive: true
        };
    },
    computed: {
        defaultQuantities() {
            let quantities = [];

            for (let i = 1; i <= 6; i++) {
                const quantity = this.orderUnit * i;

                if (this.stock && quantity > this.stock) {
                    continue;
                }

                quantities.push(quantity);
            }

            return quantities;
        }
    },
    methods: {
        addItemToCart() {
            this.$store.dispatch("addItemToCart", {
                product_id: this.productId,
                quantity: this.quantity
            });

            this.closeModal();
        },
        closeModal() {
            this.isActive = false;
        }
    },
    watch: {
        isActive: [
            {
                handler: function(isActive) {
                    this.$emit("update:active", isActive);
                }
            },
            {
                immediate: true,
                handler: function(isActive) {
                    if (isActive) {
                        document.documentElement.style.setProperty('overflow', 'hidden');
                        this.quantitySelectorActive = true;
                        this.quantity = this.orderUnit;
                    } else {
                        document.documentElement.style.removeProperty('overflow');
                    }
                }
            }
        ],
        active(active) {
            this.isActive = active;
        }
    }
};
</script>

<style lang="scss">
</style>
