<template>
    <div class="w3-modal"
         v-show="isActive">
        <div class="w3-modal__background"
             @click="closeModal()"></div>
        <div class="w3-modal-card">
            <div class="w3-modal-card__description-side">
                <h3 class="w3-modal-card__title">Toevoegen aan bestelling</h3>
                <p class="w3-modal-card__product-title"
                   v-text="item.product.title"></p>

                <div>
                    <b-input type="number"
                             v-model.number="quantity"
                             :step="item.product.order_unit"
                             :max="item.product.stock_available"></b-input>

                    <div class="w3-button w3-button--primary m-t-25"
                         @click="updateCartItem()">
                        <i class="fas fa-check"></i> opslaan
                    </div>

                    <div class="w3-button w3-button--primary m-t-25"
                         @click="deleteCartItem()">
                        <i class="fas fa-times"></i> verwijderen
                    </div>
                </div>
            </div>

            <div class="w3-modal-card__image-side">
                <img :src="item.product.default_image"
                     class="w3-modal-card__image"
                     alt="">
            </div>

            <div class="w3-modal-card__close"
                 @click="closeModal()">
                <i class="fal fa-times w3-modal-card__close-icon"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        itemId: Number,
        active: {
            type: Boolean,
            default: false
        },
    },
    created() {
        this.getItem();
    },
    data() {
        return {
            isActive: this.active,
            item: {},
            quantity: 0
        };
    },
    methods: {
        getItem() {
            this.item = this.$store.getters.getItem(this.itemId);
            this.quantity = this.item.quantity
        },
        updateCartItem() {
            this.$store.dispatch("updateItemQuantity", {
                item_id: this.itemId,
                quantity: this.quantity
            });

            this.closeModal();
        },
        closeModal() {
            this.isActive = false;
        },
        deleteCartItem() {
            this.$store.dispatch("deleteItemFromCart", this.itemId);
            this.closeModal()
        }
    },
    watch: {
        isActive: [
            {
                handler: function(isActive) {
                    this.$emit("update:active", isActive);
                }
            },
            {
                immediate: true,
                handler: function(isActive) {
                    if (isActive) {
                        document.documentElement.style.setProperty('overflow', 'hidden');
                    } else {
                        document.documentElement.style.removeProperty('overflow');
                    }
                }
            }
        ],
        active(active) {
            this.isActive = active;
        },
        itemId() {
            this.getItem()
        }
    }
};
</script>

