export default {
    /**
     * Store a address.
     *
     * @param address_type_id
     * @param full_name
     * @param customer_id
     * @param address1
     * @param address2
     * @param address3
     * @param house_number
     * @param zipcode
     * @param city
     * @param country_iso_2
     * @returns {Promise}
     */
    storeAddress({ address_type_id, full_name, customer_id, address1, address2, address3, house_number, house_number_addition, zipcode, city, country_iso_2 }) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/addresses', { address_type_id, full_name, customer_id, address1, address2, address3, house_number, house_number_addition, zipcode, city, country_iso_2 })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Store a address by Customer.
     *
     * @param address_type_id
     * @param full_name
     * @param customer_id
     * @param address1
     * @param address2
     * @param address3
     * @param house_number
     * @param zipcode
     * @param city
     * @param country_iso_2
     * @param create_both
     * @returns {Promise}
     */
    storeAddressByCustomer({ address_type_id, full_name, customer_id, address1, address2, address3, house_number, house_number_addition, zipcode, city, country_iso_2, create_both }) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/customer/addresses', { address_type_id, full_name, customer_id, address1, address2, address3, house_number, house_number_addition, zipcode, city, country_iso_2 , create_both })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Update a address.
     *
     * @param id
     * @param address_type_id
     * @param full_name
     * @param customer_id
     * @param address1
     * @param address2
     * @param address3
     * @param house_number
     * @param zipcode
     * @param city
     * @param country_iso_2
     * @returns {Promise}
     */
    updateAddress(id, { address_type_id, full_name, customer_id, address1, address2, address3, house_number, house_number_addition, zipcode, city, country_iso_2 }) {
        return new Promise(function(resolve, reject) {
            axios.put(`/api/addresses/${id}`, { address_type_id, full_name, customer_id, address1, address2, address3, house_number, house_number_addition, zipcode, city, country_iso_2 })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Update a address by Customer.
     *
     * @param id
     * @param address_type_id
     * @param full_name
     * @param customer_id
     * @param address1
     * @param address2
     * @param address3
     * @param house_number
     * @param zipcode
     * @param city
     * @param country_iso_2
     * @returns {Promise}
     */
    updateAddressByCustomer(id, { address_type_id, full_name, customer_id, address1, address2, address3, house_number, house_number_addition, zipcode, city, country_iso_2 }) {
        return new Promise(function(resolve, reject) {
            console.log(address_type_id);
            axios.put(`/api/customer/addresses/${id}`, { address_type_id, full_name, customer_id, address1, address2, address3, house_number, house_number_addition, zipcode, city, country_iso_2 })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Delete a address.
     *
     * @param id
     * @returns {Promise}
     */
    deleteAddress(id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/addresses/${id}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Get a list of addresses.
     *
     * @param page
     * @param search
     * @returns {Promise}
     */
    getAddresses(page, search) {
        let url = `/api/addresses/?page=${page}`;
        if (search) {
            url += `&search=${search}`
        }

        return new Promise(function(resolve, reject) {
            axios.get(url)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Get a list of address types.
     *
     * @returns {Promise}
     */
    getAddressTypes() {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/addresses/types`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Get a list of addresses by customer.
     *
     * @returns {Promise}
     */
    getAddressesByCustomer() {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/customer/addresses`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },


}
