<template>
    <div class="columns">
        <div class="column is-half">
            <b-field label="Kenmerk soort toevoegen of aanmaken">
                <b-autocomplete
                    v-model="featureType"
                    ref="featureTypeAutocomplete"
                    :data="filteredFeatureTypesArray"
                    field="title"
                    placeholder="bv. Smaak"
                    open-on-focus
                    @select="option => featureTypeSelected = option">

                    <template slot="header">
                        <a @click="showAddFeatureType">
                            <span> Nieuwe kenmerk soort toevoegen</span>
                        </a>
                    </template>

                    <template slot="empty">No results for {{ featureType }}</template>
                </b-autocomplete>
            </b-field>
        </div>
        <div class="column is-half"
             v-show="featureTypeSelected !== null">
            <b-field label="Kenmerk waarde toevoegen of aanmaken">
                <b-autocomplete
                    v-model="featureValue"
                    ref="featureValueAutocomplete"
                    :data="filteredFeatureValuesArray"
                    placeholder="bv. Rood"
                    field="title"
                    open-on-focus
                    @select="option => featureValueSelected = option">

                    <template slot="header">
                        <a @click="showAddFeatureValue">
                            <span> Nieuwe kenmerk waarde toevoegen</span>
                        </a>
                    </template>

                    <template slot="empty">No results for {{ featureType }}</template>
                </b-autocomplete>
            </b-field>
        </div>
    </div>
</template>

<script>
    import api from '../api'

    export default {
        data() {
            return {
                featureTypes: [],
                featureValues: [],
                featureTypesData: [],
                featureValuesData: [],
                featureType: '',
                featureValue: '',
                featureTypeSelected: null,
                featureValueSelected: null
            }
        },
        created() {
            this.getFeatureTypes();
        },
        computed: {
            filteredFeatureTypesArray() {
                return this.featureTypes.filter((option) => {
                    return option.title
                        .toString()
                        .toLowerCase()
                        .indexOf(this.featureType.toLowerCase()) >= 0
                })
            },
            filteredFeatureValuesArray() {
                return this.featureValues.filter((option) => {
                    return option.title
                        .toString()
                        .toLowerCase()
                        .indexOf(this.featureValue.toLowerCase()) >= 0
                })
            },
        },
        methods: {
            showAddFeatureType() {
                this.$dialog.prompt({
                    message: `Kenmerk soort`,
                    inputAttrs: {
                        maxlength: 250,
                        value: this.featureType
                    },
                    confirmText: 'Toevoegen',
                    onConfirm: (value) => {
                        api.featureType.storeFeatureType({ title: value }).then(storeResponse => {
                            api.featureType.getFeatureTypes().then(response => {
                                this.featureTypes = response.data.data;
                                this.$refs.featureTypeAutocomplete.setSelected(this.featureTypes.find(val => val.id === storeResponse.id));
                            });
                        });
                    }
                })
            },
            showAddFeatureValue() {
                this.$dialog.prompt({
                    message: `Kenmerk waarde`,
                    inputAttrs: {
                        maxlength: 250,
                        value: this.featureValue
                    },
                    confirmText: 'Toevoegen',
                    onConfirm: (value) => {
                        api.featureValue.storeFeatureValue({ title: value, feature_type_id: this.featureTypeSelected.id }).then(storeResponse => {
                            api.featureValue.getFeatureValuesByFeatureTypeId(this.featureTypeSelected.id).then(response => {
                                this.featureValues = response.data.data;
                                this.$refs.featureValueAutocomplete.setSelected(this.featureValues.find(val => val.id === storeResponse.id));
                            });
                        });
                    }
                })
            },
            getFeatureTypes() {
                api.featureType.getFeatureTypes().then(response => {
                    this.featureTypes = response.data;
                });
            },
            getFeatureValues(id) {
                api.featureValue.getFeatureValuesByFeatureTypeId(id).then(response => {
                    this.featureValues = response.data.data;
                });
            }
        },
        watch: {
            featureTypeSelected(newValue, oldValue) {
                if (this.featureValueSelected !== null) {
                    this.featureValue = '';
                }

                if (typeof newValue !== 'object' || newValue === null) {
                    return;
                }

                if (newValue === oldValue) {
                    return;
                }

                this.getFeatureValues(newValue.id);
            },
            featureValueSelected(newValue) {
                this.$emit('feature-changed', {
                    feature_type: this.featureTypeSelected,
                    feature_value: newValue
                });
            }
        },
    }
</script>

<style>

</style>
