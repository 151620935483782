<template>
    <div>
        <b-field label="Zoeken">
            <b-input
                type="text"
                ref="zoek"
                v-model="search"
                placeholder="Zoeken op naam"
                @input="searchDebounce()">
            </b-input>
        </b-field>

        <b-table
                :data="wineHouses"
                :loading="tableOptions.loading"
                :total="tableOptions.total"
                :per-page="tableOptions.perPage"
                @page-change="onPageChange"
                backend-pagination
                paginated>

            <template slot-scope="props">
                <b-table-column field="id" label="ID" width="40">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="title" label="Wijnhuis naam">
                    {{ props.row.title }}
                </b-table-column>

                <b-table-column field="region_title" label="Regio">
                    {{ props.row.region_title }}
                </b-table-column>

                <b-table-column field="action" label="Actie">
                    <a class="button" :href="`/admin/wine-houses/${props.row.id}/edit`">
                        Aanpassen
                    </a>
                    <button class="button is-danger m-l-5" @click="deleteWineHouse(props.row.id)">
                        Verwijderen
                    </button>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-if="! tableOptions.loading">
                    <div class="content has-text-grey has-text-centered">
                        <p>Geen wijnhuizen gevonden...</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
    import WineHouseApi from "./../api/wineHouse";
    import { reject } from 'lodash-es';
    import debounce from 'lodash/debounce'


    export default {
        props: ['initialWineHouses'],
        data() {
            return {
                wineHouses: this.initialWineHouses,
                tableOptions: {
                    total: 0,
                    page: 1,
                    perPage: 15,
                    loading: true,
                },
                search: '',
            }
        },
        created() {
            this.getWineHouses(1);
        },
        mounted() {
        },
        methods: {
            getWineHouses(page = null) {
                WineHouseApi.getWineHouses(page).then(response => {
                    this.wineHouses = response.data;
                    this.tableOptions.total = response.meta.total;
                    this.tableOptions.loading = false;
                });
            },
            deleteWineHouse(wineHouseId) {
                WineHouseApi.deleteWineHouse(wineHouseId).then(() => {
                    this.wineHouses = reject(this.wineHouses, ({ id }) => id === wineHouseId);
                })
            },
            onPageChange(page) {
                this.tableOptions.page = page;
                this.getWineHouses(page)
            },
            handleWineHouseCreatedEvent() {
                this.getWineHouses(this.tableOptions.page);
            },
            searchDebounce: debounce(function () {
                this.wineHouseSearch();
            }, 400),
            wineHouseSearch() {
                let page = 1;
                WineHouseApi.getWineHouses(page, this.search).then(response => {
                    this.wineHouses = response.data;
                    this.tableOptions.total = response.meta.total;
                    this.tableOptions.loading = false;
                });
            },
        },
    }
</script>

<style>

</style>
