<template>
    <div class="w3-admin-main-form full-width">
        <div class="w3-admin-hero">
            <h1 class="w3-admin-hero__title">Kenmerk soorten</h1>
            <button class="button is-primary w3-admin-hero__button"
                    @click="isComponentModalActive = true">Nieuwe kenmerk soort aanmaken
            </button>
        </div>
        <div class="columns m-t-15">
            <div class="column">
                <div class="card">
                    <div class="card-content">

                        <feature-types-list ref="featureTypesList"></feature-types-list>

                        <b-modal :active.sync="isComponentModalActive"
                                 has-modal-card>
                            <create-feature-types-modal @feature-type-created="handleFeatureTypeCreatedEvent()"></create-feature-types-modal>
                        </b-modal>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import FeatureTypesList from '../components/FeatureTypesList';
    import CreateFeatureTypesModal from '../components/CreateFeatureTypeModal';

    export default {
        components: {
            FeatureTypesList,
            CreateFeatureTypesModal
        },
        data() {
            return {
                isComponentModalActive: false
            }
        },
        methods: {
            handleFeatureTypeCreatedEvent() {
                this.$refs.featureTypesList.handleFeatureTypeCreatedEvent();
            }
        },
    }
</script>

<style>

</style>
