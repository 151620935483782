<template>
    <div>
        <b-field label="Koppel een gebruiker" class="m-t-20">
            <b-autocomplete
                icon="search"
                rounded
                v-model="name"
                :data="data"
                placeholder="Zoek een gebruiker..."
                field="title"
                :loading="isFetching"
                @input="getAsyncData"
                @select="option => addUser(option)">

                <template slot-scope="props">
                    <div class="media">
                        <div class="media-content">
                            {{ props.option.name }}
                            <br>
                            <small>
                                {{ props.option.email }}
                            </small>
                        </div>
                    </div>
                </template>
            </b-autocomplete>
        </b-field>
    </div>
</template>

<script>
    import { debounce } from 'lodash-es';
    import UserApi from "./../api/user";

    export default {
        props: [

        ],

        data() {
            return {
                data: [],
                name: '',
                selected: null,
                isFetching: false
            }
        },
        methods: {
            getAsyncData: debounce(function () {
                this.data = [];
                this.isFetching = true;

                let that = this;
                UserApi.searchUsers(this.name)
                    .then(function(data) {
                        that.data = data.data.data;
                        that.isFetching = false;
                    })
                    .catch(function(error) {
                        console.log(error);
                        that.isFetching = false;
                    });
            }, 300),

            addUser(user) {
                this.$emit('add-user', user);
            }
        }
    };
</script>
