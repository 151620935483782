<template>
    <div>
        <b-field label="Zoeken">
            <b-input
                type="text"
                ref="zoek"
                v-model="search"
                placeholder="Zoeken op naam of artikelcode"
                @input="handleSearch()">
            </b-input>
        </b-field>

        <b-table
            :data="regions"
            :loading="tableOptions.loading"
            :total="tableOptions.total"
            :per-page="tableOptions.perPage"
            @page-change="onPageChange"
            backend-pagination
            paginated>

            <template slot-scope="props">
                <b-table-column field="id" label="ID" width="40">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="title" label="Title">
                    {{ props.row.title }}
                </b-table-column>

                <b-table-column field="action" label="Actie">
                    <a class="button" :href="`/admin/regions/${props.row.id}/edit`">
                        Aanpassen
                    </a>
                    <button class="button is-danger m-l-5" @click="deleteRegion(props.row.id)">
                        Verwijderen
                    </button>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-if="! tableOptions.loading">
                    <div class="content has-text-grey has-text-centered">
                        <p>Geen producten gevonden...</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
    import { regionApi } from '../api';

    export default {
        data() {
            return {
                regions: [],
                tableOptions: {
                    total: 0,
                    page: 1,
                    perPage: 15,
                    loading: true,
                },
                search: '',
            };
        },
        created() {
            this.getRegions();
        },
        mounted() {
        },
        methods: {
            getRegions(page = 1, search = null) {
                regionApi.getRegions(page, search)
                         .then(response => {
                             this.regions              = response.data;
                             this.tableOptions.total   = response.meta.total;
                             this.tableOptions.loading = false;
                         });
            },
            deleteRegion(regionId) {
                regionApi.deleteRegion(regionId).then(() => {
                    this.getRegions(this.tableOptions.page, this.search);
                });
            },
            onPageChange(page) {
                this.tableOptions.page = page;
                this.getRegions(page, this.search);
            },
            handleRegionCreatedEvent() {
                this.getRegions(this.tableOptions.page, this.search);
            },
            handleSearch() {
                this.tableOptions.page = 1;
                this.getRegions(this.tableOptions.page, this.search);
            }
        }
    };
</script>

<style>
</style>
