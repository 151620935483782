<template>
    <div class="w3-admin-main-form">
        <form @submit.prevent="submitAddressUpdate">
            <div class="w3-admin-hero">
                <h1 class="w3-admin-hero__title">Adres aanpassen</h1>
                <button class="button is-primary w3-admin-hero__button">
                    Opslaan
                </button>
            </div>

            <div class="w3-admin-main-form__item" v-if="!customer">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Klant</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <customer-input-autocomplete
                        @add-customer="selectCustomer($event)"
                        label="Adres voor klant">
                    </customer-input-autocomplete>
                </div>
            </div>

            <!--<customer-input-autocomplete-->
                <!--v-if="!customer"-->
                <!--@add-customer="selectCustomer($event)"-->
                <!--label="Adres voor klant">-->
            <!--</customer-input-autocomplete>-->

            <div class="address-input-fields" v-if="customer">

                <div class="w3-admin-main-form__item">
                    <div class="w3-admin-main-form__label">
                        <p class="w3-admin-main-form__title">Klant</p>
                    </div>
                    <div class="w3-admin-main-form__input">
                        <b-field label="Klant">
                            <b-input v-model="customer.name" disabled></b-input>
                        </b-field>
                        <div><a @click="customer = ''"><small >Wijzigen</small></a></div>
                    </div>
                </div>

                <!--<b-field label="Klant">-->
                    <!--<b-input v-model="customer.name" disabled></b-input>-->
                <!--</b-field>-->
                <!--<div><a @click="customer = ''"><small >Wijzigen</small></a></div>-->

                <div class="w3-admin-main-form__item">
                    <div class="w3-admin-main-form__label">
                        <p class="w3-admin-main-form__title">Naam</p>
                    </div>
                    <div class="w3-admin-main-form__input">
                        <b-field label="Naam">
                            <b-input v-model="form.full_name" placeholder="Naam"></b-input>
                        </b-field>
                    </div>
                </div>

                <div class="w3-admin-main-form__item">
                    <div class="w3-admin-main-form__label">
                        <p class="w3-admin-main-form__title">Soort Adres</p>
                    </div>
                    <div class="w3-admin-main-form__input">
                        <b-field label="Soort Adres">
                            <b-select placeholder="Selecteer een soort" v-model="form.address_type_id" expanded>
                                <option
                                    v-for="type in address_types"
                                    :value="type.id"
                                    :key="type.id">
                                    {{ type.title }}
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>

                <div class="w3-admin-main-form__item">
                    <div class="w3-admin-main-form__label">
                        <p class="w3-admin-main-form__title">Adres</p>
                    </div>
                    <div class="w3-admin-main-form__input">
                        <b-field label="Adres">
                            <b-input v-model="form.address1" placeholder="Adres"></b-input>
                        </b-field>
                        <b-field v-if="form.country_iso_2 !== 'NL'">
                            <b-input v-model="form.address2" placeholder="Adres regel 2"></b-input>
                        </b-field>
                        <b-field v-if="form.country_iso_2 !== 'NL'">
                            <b-input v-model="form.address3" placeholder="Adres regel 3"></b-input>
                        </b-field>
                    </div>
                </div>

                <div class="w3-admin-main-form__item">
                    <div class="w3-admin-main-form__label">
                        <p class="w3-admin-main-form__title">Huisnummer</p>
                    </div>
                    <div class="w3-admin-main-form__input">
                        <b-field label="Huisnummer">
                            <b-input v-model="form.house_number" placeholder="b.v. 15"></b-input>
                        </b-field>
                        <b-field label="Toevoeging">
                            <b-input v-model="form.house_number_addition" placeholder="Toev."></b-input>
                        </b-field>
                    </div>
                </div>

                <div class="w3-admin-main-form__item">
                    <div class="w3-admin-main-form__label">
                        <p class="w3-admin-main-form__title">Postcode</p>
                    </div>
                    <div class="w3-admin-main-form__input">
                        <b-field label="Postcode">
                            <b-input v-model="form.zipcode" placeholder="b.v. 1234 AB"></b-input>
                        </b-field>
                    </div>
                </div>

                <div class="w3-admin-main-form__item">
                    <div class="w3-admin-main-form__label">
                        <p class="w3-admin-main-form__title">Plaats</p>
                    </div>
                    <div class="w3-admin-main-form__input">
                        <b-field label="Plaats">
                            <b-input v-model="form.city" placeholder="b.v. Utrecht"></b-input>
                        </b-field>
                    </div>
                </div>

                <div class="w3-admin-main-form__item">
                    <div class="w3-admin-main-form__label">
                        <p class="w3-admin-main-form__title">Land</p>
                    </div>
                    <div class="w3-admin-main-form__input">
                        <b-field label="Land" >
                            <b-select placeholder="Kies een land" v-model="form.country_iso_2" expanded>
                                <option
                                    value="NL">
                                    Nederland (NL)
                                </option>

                                <option
                                    value="BE">
                                    Belgie (BE)
                                </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import AddressApi from "./../api/address";
    import CustomerInputAutocomplete from "./CustomerInputAutocomplete";

    export default {
        components: {
            CustomerInputAutocomplete,
        },

        props: [
            'address'
        ],

        data() {
            return {
                form: {
                    address_type_id: this.address.address_type_id,
                    full_name: this.address.full_name,
                    customer_id: this.address.customer_id,
                    address1: this.address.address1,
                    address2: this.address.address2,
                    address3: this.address.address3,
                    house_number: this.address.house_number,
                    house_number_addition: this.address.addition,
                    zipcode: this.address.zipcode,
                    city: this.address.city,
                    country_iso_2: this.address.country_iso_2,
                },
                address_types: [],
                customer: this.address.customer,
            };
        },

        methods: {
            submitAddressUpdate() {
                AddressApi.updateAddress(this.address.id, this.form)
                          .then(function(data) {
                              window.location.href = data.redirect_to;
                          })
                          .catch(function(error) {
                              console.log(error);
                          });
            },

            getAddressTypes() {
                let that = this;
                AddressApi.getAddressTypes()
                          .then(function(data) {
                              that.address_types = data.data;
                          })
                          .catch(function(error) {
                              console.log(error);
                          });
            },

            selectCustomer(customer) {
                this.customer = customer;
                this.form.customer_id = customer.id;
            }
        },

        created() {
            this.getAddressTypes();
        }
    };
</script>

<style>
</style>
