export default {
    /**
     * Get the users current cart.
     *
     * @returns {Promise}
     */
    getCart() {
        return new Promise(function(resolve, reject) {
            axios.get('/api/cart')
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Get the users current cart.
     *
     * @param product_id
     * @param quantity
     * @returns {Promise}
     */
    addItemToCart(product_id, quantity) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/cart/items', { product_id, quantity })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Get the users current cart.
     *
     * @param item_id
     * @param quantity
     * @returns {Promise}
     */
    updateItemQuantity(item_id, quantity) {
        return new Promise(function(resolve, reject) {
            axios.put(`/api/cart/items/${item_id}`, { quantity })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Get the users current cart.
     *
     * @param item_id
     * @returns {Promise}
     */
    deleteItemFromCart(item_id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/cart/items/${item_id}`)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    }
}
