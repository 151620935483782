<template>
    <div class="w3-admin-main-form">
        <form @submit.prevent="submitWineHouseUpdate">
            <div class="w3-admin-hero">
                <h1 class="w3-admin-hero__title">Wijnhuis aanpassen</h1>
                <button class="button is-primary w3-admin-hero__button">Opslaan
                </button>
            </div>

            <div class="notification is-success" v-show="is_saved">
                <button type="button" class="delete" @click.prevent="is_saved = false"></button>
                Wijzigingen zijn opgeslagen!
                <i class="far fa-thumbs-up"></i>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Wijnhuis titel</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Naam">
                        <b-input
                            type="text"
                            ref="title"
                            v-model="form.title"
                            required>
                        </b-input>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Streek</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Streek" :type="autocompleteType">
                        <b-autocomplete
                            v-model="region"
                            ref="autocomplete"
                            :data="filteredRegions"
                            placeholder=""
                            @input="getRegionData"
                            :loading="loading"
                            field="title"
                            open-on-focus
                            @select="option => selected = option">

                            <template slot="empty">No results for {{ region }}</template>
                        </b-autocomplete>
                    </b-field>

                    <p v-if="this.selected === null" class="help is-danger">
                        Selecteer een streek
                    </p>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Logo</p>
                </div>
                <div class="w3-admin-main-form__input">

                    <div class="w3-admin-main-form-file-list">
                        <div v-if="initialWineHouse.logo" class="w3-admin-main-form-file-list__item">
                            <div class="w3-admin-main-form-file-list__item-card">
                                <div class="w3-admin-main-form-file-list__image-wrapper">
                                    <img :src="initialWineHouse.logo.public_path"
                                         class="w3-admin-main-form-file-list__image"
                                         alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- New logo -->
                    <div class="w3-admin-main-form-file-list">
                        <div v-if="logoFile.preview_url" class="w3-admin-main-form-file-list__item">
                            <div class="w3-admin-main-form-file-list__item-card">
                                <div class="w3-admin-main-form-file-list__image-wrapper">
                                    <img :src="logoFile.preview_url"
                                        class="w3-admin-main-form-file-list__image"
                                        alt="">
                                </div>
                                <p>Voorbeeld nieuw logo</p>
                            </div>
                        </div>
                    </div>

                    <b-field>
                        <b-upload v-model="logoDropFile"
                                drag-drop
                                accept="image/png,image/jpeg">
                            <section class="section">
                                <div class="content has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="upload"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>Sleep uw afbeelding hierheen of klik om te uploaden</p>
                                </div>
                            </section>
                        </b-upload>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Citaat</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field>
                        <b-input
                            maxlength="190" type="textarea"
                            v-model="form.quote">
                        </b-input>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Beschrijving links</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <mavon-editor v-model="form.description_left" v-bind="editorOptions"/>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Beschrijving rechts</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <mavon-editor v-model="form.description_right" v-bind="editorOptions"/>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Wijnhuis afbeeldingen</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <div class="w3-admin-main-form-file-list">
                        <div v-for="image in filteredImages" class="w3-admin-main-form-file-list__item">
                            <div class="w3-admin-main-form-file-list__item-card">
                                <div class="w3-admin-main-form-file-list__image-wrapper">
                                    <img :src="`/storage/wine_house_images/${image.filename}`"
                                         class="w3-admin-main-form-file-list__image"
                                         alt="">
                                </div>

                                <button class="delete is-small w3-admin-main-form-file-list__delete-button"
                                        type="button"
                                        @click="deleteImage(image.id)">
                                </button>
                            </div>
                        </div>
                    </div>

                    <b-field>
                        <b-upload v-model="imageDropFiles"
                                  multiple
                                  drag-drop
                                  accept="image/png,image/jpeg">
                            <section class="section">
                                <div class="content has-text-centered">
                                    <p>
                                        <b-icon
                                                icon="upload"
                                                size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>Sleep uw afbeedlingen hierheen of klik om te uploaden</p>
                                </div>
                            </section>
                        </b-upload>
                    </b-field>
                </div>
            </div>
        </form>

        <div class="notification is-success" v-show="is_saved">
            <button class="delete" @click="is_saved = false"></button>
            Wijzigingen zijn opgeslagen!
            <i class="far fa-thumbs-up"></i>
        </div>
    </div>
</template>

<script>
    import { wineHouseApi, regionApi } from "../api";
    import { debounce } from 'lodash-es';

    export default {
        props: ['initialWineHouse'],
        data() {
            return {
                form: {
                    title: this.initialWineHouse.title,
                    quote: this.initialWineHouse.quote,
                    description_left: this.initialWineHouse.description_left ? this.initialWineHouse.description_left : '',
                    description_right: this.initialWineHouse.description_right ? this.initialWineHouse.description_right : '',
                    removed_images: [],
                },
                editorOptions: {
                    toolbars: {
                        bold: true,
                        italic: true,
                    },
                    language: 'en',
                    subfield: false
                },
                logoDropFile: null,
                region: '',
                selected: null,
                regions: [],
                loading: false,
                imageDropFiles: [],
                images: this.initialWineHouse.images,
                is_saved: false,
            };
        },
        created() {
            this.loading = true;

            this.getRegions(1, this.initialWineHouse.region.title).then(() => {
                this.$refs.autocomplete.setSelected(this.initialWineHouse.region);
                this.loading = false;
            });
        },
        computed: {
            filteredRegions() {
                return this.regions.filter((option) => {
                    return option.title
                                 .toString()
                                 .toLowerCase()
                                 .indexOf(this.region.toLowerCase()) >= 0
                })
            },
            filteredImages() {
                return this.images.filter(({ id }) => {
                    return this.form.removed_images.findIndex(removedImageId => removedImageId === id) === -1;
                })
            },
            autocompleteType() {
                return !this.selected ? 'is-danger' : '';
            },
            logoFile() {
                let obj = {
                    preview_url: '',
                    file: null
                };

                if (this.logoDropFile !== null && this.logoDropFile[0]) {
                    obj.preview_url = URL.createObjectURL(this.logoDropFile[0]);
                    obj.file = this.logoDropFile[0];
                }

                return obj;
            }
        },
        methods: {
            submitWineHouseUpdate() {
                if (! this.selected) {
                    return;
                }

                const test = wineHouseApi.updateWineHouse(this.initialWineHouse.id, {
                    title: this.form.title,
                    region_id: this.selected.id,
                    quote: this.form.quote,
                    description_left: this.form.description_left,
                    description_right: this.form.description_right,
                    removed_images: this.form.removed_images
                })

                let requests = [test];

                if (this.logoDropFile !== null) {
                    requests.push(wineHouseApi.storeWineHouseLogo(this.initialWineHouse.id, this.logoFile.file));
                }

                Promise.all(requests).then(responses => {
                    this.is_saved = true;
                    // window.location.href = responses[0].redirect_to
                }).catch(function(error) {
                    console.log(error);
                });
            },
            submitWineHouseLogo() {
                wineHouseApi.storeWineHouseLogo(this.initialWineHouse.id, this.logoFile.file);
            },
            getRegions(page, title) {
                return new Promise((resolve, reject) => {
                    regionApi.getRegions(page, title)
                             .then(response => {
                                 this.regions = response.data;
                                 resolve(response);
                             })
                             .catch(error => {
                                 reject(error);
                             })
                });
            },
            getRegionData: debounce(function() {
                this.loading = true;

                regionApi.getRegions(1, this.region).then(response => {
                    this.regions = response.data;
                    this.loading = false;
                });
            }, 500),

            deleteImage(imageId) {
                this.form.removed_images.push(imageId);
            },
        },
        watch: {
            imageDropFiles(files) {
                if (files.length === 0) {
                    return;
                }

                const formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    formData.append(`images[${i}]`, files[i]);
                }

                axios.post(`/api/wine-houses/${this.initialWineHouse.id}/images`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.images = response.data.images;
                    this.imageDropFiles = [];
                }).catch(error => {
                    console.log(error)
                })
            }
        },
    };
</script>

<style>
</style>
