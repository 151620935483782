<template>
    <div>
        <b-field label="Wijn huis zoeken of aanmaken">
            <b-autocomplete
                v-model="wineHouse"
                ref="autocomplete"
                :data="wineHouses"
                field="title"
                :loading="loading"
                placeholder="bijv. Domaine Louis Hauller"
                @input="getWineHouseData"
                @select="selectWineHouse"
                open-on-focus>

                <template slot="header">
                    <a @click="isComponentModalActive = true">
                        <span> Nieuw wijn huis aanmaken... </span>
                    </a>
                </template>

                <template slot="empty">No results for {{ wineHouse }}</template>
            </b-autocomplete>
        </b-field>

        <b-modal :active.sync="isComponentModalActive" has-modal-card>
            <wine-house-create-modal @wine-house-created="handleWineHouseCreatedEvent"></wine-house-create-modal>
        </b-modal>
    </div>
</template>

<script>
    import api from '../api';
    import WineHouseCreateModal from "./WineHouseCreateModal";
    import { debounce } from 'lodash-es';

    export default {
        components: { WineHouseCreateModal },
        props: ['initialWineHouse'],
        data() {
            return {
                loading: false,
                wineHouses: [],
                wineHouse: '',
                selected: null,
                isComponentModalActive: false
            }
        },
        created() {
            this.getWineHouses();
        },
        mounted() {
            this.$refs.autocomplete.setSelected(this.initialWineHouse);
        },
        computed: {
            filteredWineHouses() {
                return this.wineHouses.filter((option) => {
                    return option.title
                                 .toString()
                                 .toLowerCase()
                                 .indexOf(this.wineHouse.toLowerCase()) >= 0
                })
            }
        },
        methods: {
            selectWineHouse(option) {
                this.selected = option;

                this.$emit('wine-house-selected', option);
            },
            getWineHouses() {
                this.loading = true;
                api.wineHouse.getWineHouses().then(response => {
                    this.wineHouses = response.data;
                    this.loading = false;
                })
            },
            handleWineHouseCreatedEvent(payload) {
                this.loading = true;
                api.wineHouse.getWineHouses(1, payload.wine_house.title).then(response => {
                    this.wineHouses = response.data;
                    this.$refs.autocomplete.setSelected(payload.wine_house);
                    this.loading = false;
                });
            },
            getWineHouseData: debounce(function () {
                this.loading = true;

                api.wineHouse.getWineHouses(1, this.wineHouse).then(response => {
                    this.wineHouses = response.data;
                    this.loading = false;
                });
            }, 500)
        },
    }
</script>

<style>

</style>
