<template>
    <b-field label="Aantal">
        <b-input v-model="newValue" type="number" :step="this.step"></b-input>
    </b-field>
</template>

<script>
    import { debounce } from 'lodash-es';

    export default {
        props: {
            value: {
                type: Number
            },
            step: {
                type: Number,
                default: 1
            },
            itemId: {
                type: Number,
            }
        },
        data() {
            return {
                newValue: this.value
            }
        },
        methods: {
            updateItemQuantity: debounce(function (item_id, quantity) {
                this.$store.dispatch('updateItemQuantity', {
                    item_id,
                    quantity
                })
            }, 500),
        },
        watch: {
            newValue(newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }

                this.updateItemQuantity(this.itemId, newValue)
            }
        },
    }
</script>

<style>

</style>
