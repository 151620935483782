<template>

    <div id="DashboardPopularProducts" class="column column-eq-height is-12-mobile is-6-tablet is-4-desktop is-3-fullhd">
        <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--content is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
            <div class="columns is-mobile">
                <div class="column is-narrow">
                    <h3 class="w3-header w3-header--tertiary w3-header--primary">Populaire wijnen</h3>
                </div>
                <!--<div class="column has-text-right p-l-0">-->
                    <!--<a href="#" class="w3-button w3-button&#45;&#45;link-primary"><i class="fal fa-angle-right"></i> bekijk meer</a>-->
                <!--</div>-->
            </div>

            <div class="columns is-mobile m-b-0">
                <div class="column">
                    <strong>Berekend over alle bestellingen</strong>
                </div>
            </div>

            <ul class="w3-list">
                <li v-for="(product, index) in user.popular_products">
                    <a :href="product.product_url" :title="product.title">
                        <strong>{{ index + 1 }}</strong> {{ product.title }} <i :class="getColorClass(product.color)"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>

</template>
<script>
    import UserApi from "./../api/user";
    import { convertWineColorStringToClass } from "../utilities/color";

    export default {
        data() {
            return {
                user: {
                    popular_products: []
                }
            }
        },
        methods: {
            getColorClass(color) {
                return convertWineColorStringToClass(color);
            }
        },
        mounted() {
            UserApi.getCurrentUser().then((data) => {
                this.user = data;
            });
        }
    }
</script>
