<template>
    <div>
        <b-field>
            <b-input v-model="reference" type="textarea" @input="setReference"></b-input>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'OrderReferenceInputBox',
        data() {
            return {
                reference: '',
            }
        },
        methods: {
            setReference() {
                this.$emit('reference', this.reference);
            }
        },
    }
</script>
