<template>

    <div id="DashboardOrders" class="column column-eq-height is-12-mobile is-10-tablet is-6-desktop is-4-fullhd">
        <div class="w3-card w3-card--content w3-card--rounded w3-card--eq-height is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
            <div class="columns is-mobile">
                <div class="column is-narrow">
                    <h3 class="w3-header w3-header--tertiary w3-header--primary">Uw bestellingen</h3>
                </div>
                <div class="column has-text-right">
                    <a href="#" class="w3-button w3-button--link-primary"><i class="fal fa-angle-right"></i> alle bestellingen</a>
                </div>
            </div>

            <div class="columns is-mobile m-b-0">
                <div class="column is-4-mobile is-3-tablet is-4-desktop is-3-widescreen p-r-0">
                    <strong>Ordernummer</strong>
                </div>
                <div class="column is-4-mobile is-3-tablet p-r-0">
                    <strong>Besteldatum</strong>
                </div>
                <div class="column is-4-mobile is-3-tablet">
                    <strong>Referentie</strong>
                </div>
                <div class="column is-hidden-mobile is-2-desktop is-3-widescreen"></div>
            </div>

            <div class="columns is-mobile is-multiline is-marginless--bottom" v-for="order in user.recent_orders">
                <div class="column is-4-mobile is-3-tablet is-4-desktop is-3-widescreen p-r-0">
                    <span>{{ order.id }}</span>
                </div>
                <div class="column is-4-mobile is-3-tablet p-r-0">
                    <span>{{ order.date }}</span>
                </div>
                <div class="column is-4-mobile is-3-tablet">
                    <span>{{ order.reference }}</span>
                    <!--<span class="w3-indicator w3-indicator&#45;&#45;warning"></span> <span>betaald</span>-->
                    <!--<span class="w3-indicator w3-indicator&#45;&#45;success"></span> <span>geleverd</span>-->
                </div>
                <div class="column is-hidden-mobile is-3-tablet is-2-desktop is-3-widescreen">
                    <a :href="order.order_url" class="w3-button w3-button--primary w3-button--smallest is-pulled-right">
                        <span class="is-hidden-desktop-only">bekijken</span>
                        <i class="far fa-repeat is-hidden-widescreen is-hidden-touch"></i>
                    </a>
                </div>
            </div>

        </div>
    </div>

</template>
<script>
    import UserApi from "./../api/user";

    export default {
        data() {
            return {
                user: {}
            }
        },
        mounted() {
            UserApi.getCurrentUser().then((data) => {
                this.user = data;
            });
        }
    }
</script>
