export default {
    /**
     * get regions.
     *
     * @param {Number=} page
     * @param {String=} title
     * @returns {Promise}
     */
    getCountryFlags(page, title) {
        if (! page) {
            page = 1;
        }

        let url = `/api/country-flags?page=${page}`;

        if (title) {
            url += `&title=${title}`;
        }

        return new Promise(function(resolve, reject) {
            axios.get(url)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },


    /**
     * Delete a region.
     *
     * @param id
     * @returns {Promise}
     */
    deleteCountryFlag(countryIso) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/country-flags/${countryIso}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    }
}
