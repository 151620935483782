<template>
    <div class="w3-delivery-address-row" @click="selectAddress" :class="{ active: active === deliveryAddress.id }">
        <div class="radio-button" :class="{ active: active === deliveryAddress.id }"></div>
        <div class="address-box">
            <div class="fullname">
                {{ deliveryAddress.full_name }}
            </div>
            <div class="address">
                {{ deliveryAddress.address1 }} {{ deliveryAddress.house_number }} {{ deliveryAddress.address2 }} {{ deliveryAddress.address3 }}
            </div>
            <div class="postcode-city">
                {{ deliveryAddress.zipcode }}, {{ deliveryAddress.city }}
            </div>
            <div class="country">
                {{ deliveryAddress.country_iso_2 }}
            </div>
        </div>
        <div class="edit-address" @click="editAddress">
            <i class="far fa-pencil-alt"></i>
        </div>
        <div class="delete-address" @click="deleteAddress">
            <i class="far fa-trash-alt"></i>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DeliveryAddressesRow',
        props: [
            'deliveryAddress',
            'active'
        ],
        methods: {
            selectAddress() {
                this.$emit('select-address', this.deliveryAddress);
            },

            editAddress() {
                this.$emit('edit-address', this.deliveryAddress);
            },

            deleteAddress() {
                this.$emit('delete-address', this.deliveryAddress);
            }
        },
    }
</script>

<style>

</style>
