export default {

    /**
     * Update a user.
     *
     * @param id
     * @param current_password
     * @param new_password
     * @param new_password_validation
     * @returns {Promise}
     */
    updatePassword(id, { current_password, new_password, new_password_validation }) {
        return new Promise(function(resolve, reject) {
            axios.put(`/api/my-account/${id}`, { current_password, new_password, new_password_validation })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    }
}
