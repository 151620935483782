<template>

</template>

<script>
import { Component } from "vue-instantsearch";

export default {
    mixins: [ Component ],
    computed: {
        disjunctiveFacetsRefinements() {
            return this.searchStore._helper.state.disjunctiveFacetsRefinements;
        },
        query() {
            return this.searchStore._helper.state.query;
        },
        page() {
            return this.searchStore._helper.state.page;
        }
    },
    mounted() {
        const params = new URLSearchParams(document.location.search);

        if (params.get('favorites') !== 'true') {
            this.mountSavedState();
        }
    },
    methods: {
        mountSavedState() {
            const query = localStorage.getItem('query');
            this.searchStore._helper.setQuery(query);

            try {
                const refinements = JSON.parse(localStorage.getItem('refinements'));
                this.setRefinements(refinements)
            } catch (e) {
                return;
            }

            const page = parseInt(localStorage.getItem('page'));
            if (page >= 0) {
                this.searchStore._helper.setPage(page);
            }
        },
        setRefinements(refinements) {
            Object.keys(refinements).forEach(refinement => {
                refinements[refinement].forEach(value => {
                    this.searchStore._helper.addDisjunctiveFacetRefinement(refinement, value);
                })
            });
        }
    },
    watch: {
        disjunctiveFacetsRefinements: function(facets) {
            localStorage.setItem('refinements', JSON.stringify(facets));
        },
        query(query) {
            if (! query) {
                query = '';
            }

            localStorage.setItem('query', query);
        },
        page(page) {
            localStorage.setItem('page', page);
        }
    }
};
</script>

