<template>
    <div>
        <b-table
            :data="featureTypes"
            :loading="tableOptions.loading"
            :total="tableOptions.total"
            :per-page="tableOptions.perPage"
            @page-change="onPageChange"
            backend-pagination
            paginated>

            <template slot-scope="props">
                <b-table-column field="id"
                                label="ID"
                                width="40">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="title"
                                label="Title">
                    {{ props.row.title }}
                </b-table-column>

                <b-table-column field="action"
                                label="Actie">
                    <a class="button"
                       :href="`/admin/feature-types/${props.row.id}/edit`">
                        Aanpassen
                    </a>
                    <button class="button is-danger m-l-5"
                            @click="deleteFeatureType(props.row.id)">
                        Verwijderen
                    </button>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section"
                         v-if="! tableOptions.loading">
                    <div class="content has-text-grey has-text-centered">
                        <p>Geen producten gevonden...</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
    import { featureTypeApi } from "../api";

    export default {
        data() {
            return {
                featureTypes: [],
                tableOptions: {
                    total: 0,
                    page: 1,
                    perPage: 15,
                    loading: true,
                },
            }
        },
        created() {
            this.getFeatureTypes();
        },
        methods: {
            getFeatureTypes(page = null) {
                featureTypeApi.getFeatureTypes(page).then(response => {
                    this.featureTypes         = response.data;
                    this.tableOptions.total   = response.meta.total;
                    this.tableOptions.loading = false;
                });
            },
            deleteFeatureType(featureTypeId) {
                featureTypeApi.deleteFeatureType(featureTypeId).then(() => {
                    this.getFeatureTypes(this.tableOptions.page);
                });
            },
            onPageChange(page) {
                this.tableOptions.page = page;
                this.getFeatureTypes(page)
            },
            handleFeatureTypeCreatedEvent() {
                this.getFeatureTypes(this.tableOptions.page);
            }
        },
    }
</script>

<style>

</style>
