<template>
    <form @submit.prevent="submitUserCreate">

        <b-field label="Naam">
            <b-input v-model="form.name" required></b-input>
        </b-field>

        <b-field label="Email">
            <b-input type="email" v-model="form.email" required></b-input>
        </b-field>

        <b-field label="Telefoon">
            <b-input v-model="form.phone"></b-input>
        </b-field>

        <button class="button is-primary"
                type="submit">
            Opslaan
        </button>
    </form>
</template>

<script>
    import UserApi from "./../api/user";

    export default {
        data() {
            return {
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    password: '',
                }
            };
        },
        methods: {
            submitUserCreate() {
                UserApi.storeUser(this.form)
                           .then(function(data) {
                               window.location.href = data.redirect_to;
                           })
                           .catch(function(error) {
                               console.log(error);
                           });
            }
        }
    };
</script>

<style>
</style>
