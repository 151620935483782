export default {
    /**
     * Add a product to the current user's favorites
     *
     * @returns {Promise}
     */
    addProduct(product_id) {
        return new Promise(function(resolve, reject) {
            axios.post(`/api/users/favorites`, {
                product_id
            })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Remove a product from the current user's favorites
     *
     * @returns {Promise}
     */
    removeProduct(product_id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/users/favorites/product/${product_id}`)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
}
