<template>
    <div class="w3-cart-totals" v-if="cart">
        <div class="columns">
            <div class="column is-6 is-offset-6">
                <div class="price-total-box">
                    <div class="price-row">
                        <span class="price-label">Subtotaal excl. BTW:</span><span class="price-value">{{ subtotal_exl_vat | toString | currency }}</span>
                    </div>

                    <div class="price-row">
                        <span class="price-label">Korting excl. BTW:</span><span class="price-value">{{ discount_exl_vat | toString | currency }}</span>
                    </div>

                    <div class="price-row">
                        <span class="price-label">Verzendkosten excl. BTW:</span><span class="price-value">{{ delivery_costs_exl_vat | toString | currency }}</span>
                    </div>

                    <div class="price-row" v-if="delivery_costs_exl_vat !== 0">
                        <span class="price-label is-size-7">(Bij 120 fl. of een waarde van 500 euro wordt uw bestelling franco geleverd en betaald u geen transportkosten)</span>
                    </div>

                    <div class="price-row big total">
                        <span class="price-label">Totaal excl. BTW:</span><span class="price-value">{{ total_exl_vat | toString | currency }}</span>
                    </div>

                    <div class="price-row">
                        <span class="price-label">BTW(21%):</span><span class="price-value">{{ total_vat | toString | currency }}</span>
                    </div>

                    <div class="price-row biggest">
                        <span class="price-label">Totaal incl. BTW:</span><span class="price-value">{{ total_inc_vat | toString | currency }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                //TODO: discount implementeren
                discount_exl_vat: '0',
//                delivery_costs_exl_vat: 5.95,
            }
        },
        computed: {
            cart() {
                return this.$store.state.cart;
            },
            subtotal_exl_vat() {
                let total = 0;
                this.cart.items.forEach(function(item) {
                    total = total + item.total_price;
                });
                return total;
            },
            delivery_costs_exl_vat() {
                return this.cart.shipping_cost;
            },
            total_exl_vat() {
                return this.delivery_costs_exl_vat + this.subtotal_exl_vat;
            },
            total_vat() {
                return this.total_exl_vat * 0.21;
            },
            total_inc_vat() {
                return this.total_exl_vat + this.total_vat;
            }
        },
        filters: {
            toString: function (value) {
                return value = value.toString();
            }
        }
    }
</script>

<style>

</style>
