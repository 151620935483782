<template>
    <div>
        <b-field label="Zoeken">
            <b-input
                type="text"
                ref="zoek"
                v-model="search"
                placeholder="Zoeken op naam of artikelcode"
                @input="handleSearch()">
            </b-input>
        </b-field>

        <b-table
            :data="isEmpty ? [] : customers"
            :loading="loading"
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            backend-pagination
            paginated>

            <template slot-scope="props">
                <b-table-column field="id" label="ID" width="40">
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column field="name" label="Naam">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column field="action">
                    <button class="button" @click="editCustomer(props.row.id)">Aanpassen</button>
                    <button class="button is-danger m-l-5" @click="deleteCustomer(props.row.id)">Verwijderen</button>
                </b-table-column>
                <b-table-column field="point_balance" label="Kurken Saldo">
                    <img src="/android-chrome-192x192.png" width="20px" class="w3-admin-customers__points-icon" />
                    {{ props.row.point_balance }}
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-if="loading === false">
                    <div class="content has-text-grey has-text-centered">
                        <p>Geen gebruikers gevonden...</p>
                    </div> <!-- .content -->
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
    import CustomerApi from "./../api/customer";

    export default {
        data() {
            return {
                customers: [],
                total: 0,
                page: 1,
                perPage: 15,
                loading: false,
                isEmpty: false,
                search: '',
            };
        },
        methods: {
            getCustomers() {
                this.loading = true;
                CustomerApi.getCustomers(this.page, this.search)
                           .then(data => {
                               this.customers = data.data.data;
                               this.total = data.data.total;
                               this.perPage = data.data.per_page;
                               this.loading = false;
                           })
                           .catch(error => {
                               console.log(error);
                           });
            },

            onPageChange(page) {
                this.page = page;
                this.getCustomers()
            },

            editCustomer(id) {
                window.location.href = '/admin/customers/' + id + '/edit';
            },

            deleteCustomer(customerId) {
                CustomerApi.deleteCustomer(customerId).then(() => {
                    this.getCustomers();
                });
            },
            handleSearch() {
                this.page = 1;
                this.getCustomers(this.page, this.search);
            }
        },

        created() {
            this.getCustomers()
        }
    };
</script>

<style>
</style>
