<template>
    <div class="w3-admin-main-form full-width">

        <div class="w3-admin-hero">
            <h1 class="w3-admin-hero__title">Wijnhuizen</h1>
            <button class="button is-primary w3-admin-hero__button"
                    @click="isComponentModalActive = true">Nieuw wijnhuis aanmaken
            </button>
        </div>

        <div class="columns m-t-15">
            <div class="column">
                <div class="card">
                    <div class="card-content">
                        <wine-houses-list ref="wineHousesList"></wine-houses-list>

                        <b-modal :active.sync="isComponentModalActive" has-modal-card>
                            <wine-house-create-modal @wine-house-created="handleWineHouseCreatedEvent()"></wine-house-create-modal>
                        </b-modal>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WineHousesList from "../components/WineHousesList";
    import WineHouseCreateModal from "../components/WineHouseCreateModal";

    export default {
        components: {
            WineHouseCreateModal,
            WineHousesList
        },
        props: ['initialWineHouses'],
        data() {
            return {
                isComponentModalActive: false
            }
        },
        created() {
        },
        mounted() {
        },
        methods: {
            handleWineHouseCreatedEvent() {
                this.$refs.wineHousesList.handleWineHouseCreatedEvent();
            }
        },
    }
</script>

<style>

</style>
