<template>
 <div>
     <form @submit.prevent="submitAddressCreate">
         <div class="columns">
             <div class="column">
                 <div class="field">
                     <label class="label">Naam</label>
                     <div class="control">
                         <input class="input" :class="{ 'is-danger': errors.has('naam') }" name="naam" type="text" v-model="form.full_name" v-validate="'required'" placeholder="Naam">
                     </div>
                     <p class="help is-danger">{{ errors.first('naam') }}</p>
                 </div>
             </div>
         </div>

         <div class="columns">
             <div class="column">
                 <label class="label">Adres</label>
                 <div class="control">
                     <input class="input" :class="{ 'is-danger': errors.has('adres') }"  name="adres" type="text" v-model="form.address1" v-validate="'required'" placeholder="Adres">
                 </div>
                 <p class="help is-danger">{{ errors.first('adres') }}</p>

                 <div v-if="form.country_iso_2 !== 'NL'">
                     <label class="label"></label>
                     <div class="control">
                         <input class="input" name="address2" type="text" v-model="form.address2" placeholder="Adres regel 2">
                     </div>

                     <label class="label"></label>
                     <div class="control">
                         <input class="input" name="address3" type="text" v-model="form.address3" placeholder="Adres regel 3">
                     </div>
                 </div>
             </div>

             <div class="column">
                 <div class="field">
                     <label class="label">Huisnummer</label>
                     <div class="control">
                         <input class="input" :class="{ 'is-danger': errors.has('huisnummer') }" name="huisnummer" type="text" v-model="form.house_number" v-validate="'required|numeric'" placeholder="b.v. 15">
                     </div>
                     <p class="help is-danger">{{ errors.first('huisnummer') }}</p>
                 </div>
             </div>
         </div>

         <div class="columns">
             <div class="column">
                 <div class="field">
                     <label class="label">Postcode</label>
                     <div class="control">
                         <input class="input" :class="{ 'is-danger': errors.has('postcode') }" name="postcode" type="text" v-model="form.zipcode" v-validate="'required'" placeholder="b.v. 1234 AB">
                     </div>
                     <p class="help is-danger">{{ errors.first('postcode') }}</p>
                 </div>
             </div>
             <div class="column">
                 <div class="field">
                     <label class="label">Plaats</label>
                     <div class="control">
                         <input class="input" :class="{ 'is-danger': errors.has('plaats') }" name="plaats" type="text" v-model="form.city" v-validate="'required'" placeholder="b.v. Utrecht">
                     </div>
                     <p class="help is-danger">{{ errors.first('plaats') }}</p>
                 </div>
             </div>
         </div>

         <div class="columns">
             <div class="column">
                 <b-field label="Land" >
                     <b-select placeholder="Kies een land" v-model="form.country_iso_2" expanded>
                         <option
                             value="NL">
                             Nederland (NL)
                         </option>

                         <option
                             value="BE">
                             Belgie (BE)
                         </option>
                     </b-select>
                 </b-field>
             </div>
             <div class="column"></div>
         </div>
         <div class="field" v-if="status === 'new'">
             <b-checkbox v-model="form.create_both">
                 Ook toevoegen als factuuradres
             </b-checkbox>
         </div>

         <button class="w3-button w3-button--primary m-t-20">Opslaan</button>
     </form>
 </div>
</template>

<script>
    import AddressApi from "./../api/address";
    export default {
        name: 'InvoiceAddressForm',
        props: [
            'status',
            'invoiceAddress'
        ],
        data() {
            return {
                form: {
                    id: '',
                    address_type_id: 2,
                    full_name: '',
                    address1: '',
                    address2: '',
                    house_number: '',
                    zipcode: '',
                    city: '',
                    country_iso_2: 'NL',
                    create_both: false,
                },
            }
        },
        methods: {
            submitAddressCreate() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        if(this.status === 'new') {
                            let that = this;
                            AddressApi.storeAddressByCustomer(this.form)
                                      .then(function(data) {
                                          that.$emit('success');
                                          if(that.form.create_both === true) {
                                              location.reload();
                                          }
                                          that.resetForm();
                                      })
                                      .catch(function(error) {
                                          console.log(error);
                                      });
                        }
                        if(this.status === 'edit') {
                            let that = this;
                            AddressApi.updateAddressByCustomer(this.form.id, this.form)
                                      .then(function(data) {
                                          that.$emit('success');
                                          that.resetForm();
                                      })
                                      .catch(function(error) {
                                          console.log(error);
                                      });
                        }
                    }
                });
            },
            resetForm() {
                this.form.address_type_id = 2;
                this.form.full_name = '';
                this.form.address1 = '';
                this.form.address2 = '';
                this.form.house_number = '';
                this.form.zipcode = '';
                this.form.city = '';
                this.form.country_iso_2 = '';
                this.form.create_both = false;
            }
        },
        created() {
            if(this.invoiceAddress) {
                this.form.id = this.invoiceAddress.id;
                this.form.customer_id = this.invoiceAddress.customer_id;
                this.form.full_name = this.invoiceAddress.full_name;
                this.form.address1 = this.invoiceAddress.address1;
                this.form.address2 = this.invoiceAddress.address2;
                this.form.house_number = this.invoiceAddress.house_number;
                this.form.zipcode = this.invoiceAddress.zipcode;
                this.form.city = this.invoiceAddress.city;
                this.form.country_iso_2 = this.invoiceAddress.country_iso_2;
            }
        }
    }
</script>

<style>

</style>
