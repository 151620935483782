<template>
 <div>
     <form @submit.prevent="submitAddressEdit">
         <div class="columns">
             <div class="column">
                 <div class="field">
                     <label class="label">Naam</label>
                     <div class="control">
                         <input class="input" :class="{ 'is-danger': errors.has('naam') }" name="naam" type="text" v-model="form.full_name" v-validate="'required'" placeholder="Naam">
                     </div>
                     <p class="help is-danger">{{ errors.first('naam') }}</p>
                 </div>
             </div>
         </div>

         <div class="columns">
             <div class="column">
                 <label class="label">Adres</label>
                 <div class="control">
                     <input class="input" :class="{ 'is-danger': errors.has('adres') }"  name="adres" type="text" v-model="form.address1" v-validate="'required'" placeholder="Adres">
                 </div>
                 <p class="help is-danger">{{ errors.first('adres') }}</p>

                 <div v-if="form.country_iso_2 !== 'NL'">
                     <label class="label"></label>
                     <div class="control">
                         <input class="input" name="address2" type="text" v-model="form.address2" placeholder="Adres regel 2">
                     </div>

                     <label class="label"></label>
                     <div class="control">
                         <input class="input" name="address3" type="text" v-model="form.address3" placeholder="Adres regel 3">
                     </div>
                 </div>
             </div>

             <div class="column">
                 <div class="field">
                     <label class="label">Huisnummer</label>
                     <div class="control">
                         <input class="input" :class="{ 'is-danger': errors.has('huisnummer') }" name="huisnummer" type="text" v-model="form.house_number" v-validate="'required|numeric'" placeholder="b.v. 15">
                     </div>
                     <p class="help is-danger">{{ errors.first('huisnummer') }}</p>
                 </div>
             </div>
         </div>

         <div class="columns">
             <div class="column">
                 <div class="field">
                     <label class="label">Postcode</label>
                     <div class="control">
                         <input class="input" :class="{ 'is-danger': errors.has('postcode') }" name="postcode" type="text" v-model="form.zipcode" v-validate="'required'" placeholder="b.v. 1234 AB">
                     </div>
                     <p class="help is-danger">{{ errors.first('postcode') }}</p>
                 </div>
             </div>
             <div class="column">
                 <div class="field">
                     <label class="label">Plaats</label>
                     <div class="control">
                         <input class="input" :class="{ 'is-danger': errors.has('plaats') }" name="plaats" type="text" v-model="form.city" v-validate="'required'" placeholder="b.v. Utrecht">
                     </div>
                     <p class="help is-danger">{{ errors.first('plaats') }}</p>
                 </div>
             </div>
         </div>

         <div class="columns">
             <div class="column">
                 <b-field label="Land" >
                     <b-select placeholder="Kies een land" v-model="form.country_iso_2" expanded>
                         <option
                             value="NL">
                             Nederland (NL)
                         </option>

                         <option
                             value="BE">
                             Belgie (BE)
                         </option>
                     </b-select>
                 </b-field>
             </div>
             <div class="column"></div>
         </div>

         <button class="w3-button w3-button--primary m-t-20">Afleveradres aanpassen</button>
     </form>
 </div>
</template>

<script>
    import AddressApi from "./../api/address";
    export default {
        name: 'DeliveryAddressEditForm',
        props: [
            'deliveryAddress',
        ],
        data() {
            return {
                form: {
                    id: '',
                    address_type_id: 1,
                    customer_id: '',
                    full_name: '',
                    address1: '',
                    address2: '',
                    house_number: '',
                    zipcode: '',
                    city: '',
                    country_iso_2: 'NL',
                },
            }
        },
        methods: {
            submitAddressEdit() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        let that = this;
                        AddressApi.updateAddressByCustomer(this.form.id, this.form)
                                  .then(function(data) {
                                      that.$emit('success');
                                      that.resetForm();
                                  })
                                  .catch(function(error) {
                                      console.log(error);
                                  });
                    }
                });
            },
            resetForm() {
                this.form.address_type_id = '';
                this.form.full_name = '';
                this.form.address1 = '';
                this.form.address2 = '';
                this.form.house_number = '';
                this.form.zipcode = '';
                this.form.city = '';
                this.form.country_iso_2 = '';
            }
        },
        created() {
            this.form.id = this.deliveryAddress.id;
            this.form.customer_id = this.deliveryAddress.customer_id;
            this.form.full_name = this.deliveryAddress.full_name;
            this.form.address1 = this.deliveryAddress.address1;
            this.form.address2 = this.deliveryAddress.address2;
            this.form.house_number = this.deliveryAddress.house_number;
            this.form.zipcode = this.deliveryAddress.zipcode;
            this.form.city = this.deliveryAddress.city;
            this.form.country_iso_2 = this.deliveryAddress.country_iso_2;
        }
    }
</script>

<style>

</style>
