<template>
    <form @submit.prevent="submitWineHouseCreate">
        <b-field label="Titel">
            <b-input v-model="form.title"></b-input>
        </b-field>

        <b-field label="Streek" :type="autocompleteType">
            <b-autocomplete
                v-model="region"
                ref="autocomplete"
                :data="filteredRegions"
                @input="getRegionData"
                :loading="loading"
                placeholder=""
                field="title"
                open-on-focus
                @select="option => selected = option">

                <template slot="empty">Geen resultaten voor: "{{ region }}"</template>
            </b-autocomplete>
        </b-field>

        <p v-if="this.selected === null" class="help is-danger">
            Selecteer een streek
        </p>
    </form>
</template>

<script>
    import api from '../api';
    import WineHouseApi from "./../api/wineHouse";
    import { debounce } from 'lodash-es';

    export default {
        data() {
            return {
                form: {
                    title: "",
                },
                region: '',
                selected: null,
                regions: [],
                loading: false
            };
        },
        created() {
            this.getRegions();
        },
        computed: {
            filteredRegions() {
                return this.regions.filter((option) => {
                    return option.title
                        .toString()
                        .toLowerCase()
                        .indexOf(this.region.toLowerCase()) >= 0
                })
            },
            selectedId() {
                return this.selected === null ? null : this.selected.id;
            },
            autocompleteType() {
                return !this.selected ? 'is-danger' : '';
            }
        },
        methods: {
            submitWineHouseCreate() {
                if (this.selected === null) {
                    return;
                }

                return new Promise((resolve, reject) => {
                    WineHouseApi.storeWineHouse({ title: this.form.title, region_id: this.selectedId })
                                .then(data => {
                                    resolve(data);
                                })
                                .catch(error => {
                                    reject(error);
                                });
                });
            },
            getRegions() {
                api.region.getRegions().then(response => {
                    this.regions = response.data;
                })
            },
            getRegionData: debounce(function () {
                this.loading = true;

                api.region.getRegions(1, this.region).then(response => {
                    this.regions = response.data;
                    this.loading = false;
                });
            }, 500)
        }
    };
</script>

<style>
</style>
