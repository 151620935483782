<template>
    <div class="w3-admin-main-form">
        <form @submit.prevent="submitUserUpdate">
            <div class="w3-admin-hero">
                <h1 class="w3-admin-hero__title">Gebruiker aanpassen</h1>
                <button class="button is-primary w3-admin-hero__button">
                    Opslaan
                </button>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Naam</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Naam">
                        <b-input v-model="form.name"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Email</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Email">
                        <b-input type="email" v-model="form.email"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Telefoon</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Telefoon">
                        <b-input v-model="form.phone"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Klanten</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <user-customer-list
                        :customers="form.customers"
                        @add-customer="addCustomer($event)"
                        @delete-customer="deleteCustomer($event)"></user-customer-list>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { reject } from 'lodash-es';
    import UserApi from "./../api/user";
    import UserCustomerList from "./UserCustomerList";

    export default {
        components: {
            UserCustomerList,
        },

        props: [
            'user'
        ],

        data() {
            return {
                form: {
                    name: this.user.name,
                    email: this.user.email,
                    phone: this.user.phone,
                    customers: this.user.customers,
                }
            };
        },
        methods: {
            submitUserUpdate() {
                UserApi.updateUser(this.user.id ,this.form)
                           .then(function(data) {
                               window.location.href = data.redirect_to;
                           })
                           .catch(function(error) {
                               console.log(error);
                           });
            },

            addCustomer(customer) {
                this.form.customers.push(customer);
            },

            deleteCustomer(id) {
                this.form.customers = reject(this.form.customers, { id: id });
            }
        }
    };
</script>

<style>
</style>
