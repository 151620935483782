import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {
            favorites: [],
            favoriteIds: [],
            selected_customer: {},
            recent_orders: [],
        },
        cart: {
            items: [],
        },
        recent_orders: []
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
        },
        setCart(state, cart) {
            state.cart = cart;
        },
    },
    getters: {
        amountInCart: state => {
            return state.cart ? state.cart.items.length : 0;
        },
        favoriteIds: state => {
            return state.user.favoriteIds;
        },
        cartTotalPrice: state => {
            let total = 0;

            state.cart.items.forEach(({ product, quantity }) => {
                total = total + (Number(product.price_retail) * quantity);
            });

            return total;
        },
        getProductAmountInCart: state => product_id => {
            let totalQuantity = 0;

            state.cart.items.filter(({ product }) => product.id === product_id).forEach(({ quantity }) => {
                totalQuantity = totalQuantity + quantity;
            });

            return totalQuantity;
        },
        getItem: state => item_id => {
            return state.cart.items.find(item => {
                return item.id === item_id;
            })
        },
        getItemByProductId: state => product_id => {
            return state.cart.items.find(item => {
                return item.product_id === product_id;
            })
        }
    },
    actions: {
        setUser({ commit }) {
            return new Promise((resolve) => {
                api.user.getCurrentUser()
                   .then(data => {
                       commit('setUser', data);
                       resolve();
                   });
            });
        },
        setCart({ commit }) {
            return new Promise((resolve) => {
                api.cart.getCart()
                   .then(data => {
                       commit('setCart', data);
                       resolve();
                   });
            });
        },
        addItemToCart({ commit, dispatch, state }, { product_id, quantity }) {
            api.cart.addItemToCart(product_id, quantity)
               .then(() => {
                   dispatch('setCart');
               })
        },
        updateItemQuantity({ commit, dispatch, state }, { item_id, quantity }) {
            api.cart.updateItemQuantity(item_id, quantity)
               .then(() => {
                   dispatch('setCart');
               })
        },
        deleteItemFromCart({ commit, dispatch, state }, item_id) {
            api.cart.deleteItemFromCart(item_id)
               .then(() => {
                   dispatch('setCart');
               })
        },
        addProductToFavorites({ commit, dispatch, state }, product_id) {
            api.favorite.addProduct(product_id)
               .then(() => {
                   dispatch('setUser');
               })
        },
        removeProductFromFavorites({ commit, dispatch, state }, product_id) {
            api.favorite.removeProduct(product_id)
               .then(() => {
                   dispatch('setUser');
               })
        },
    }
})
