<template>
    <form action="">
        <div class="modal-card"
             style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Kenmerk soort aanmaken
                </p>
            </header>
            <section class="modal-card-body">
                <feature-type-create-form @feature-type-created="featureTypeCreatedEvent"
                                          ref="featureTypeCreateForm"></feature-type-create-form>
            </section>
            <footer class="modal-card-foot">
                <button class="button"
                        type="button"
                        @click="$parent.close()">
                    Sluiten
                </button>
                <button type="submit"
                        class="button is-dark"
                        @click.prevent="handleFeatureTypeCreate()">
                    Opslaan
                </button>
                <button type="submit"
                        class="button is-primary"
                        @click.prevent="handleFeatureTypeCreate(true)">
                    Opslaan en bekijken
                </button>
            </footer>
        </div>
    </form>
</template>

<script>
    import FeatureTypeCreateForm from './FeatureTypeCreateForm';

    export default {
        components: {
            FeatureTypeCreateForm
        },
        data() {
            return {
                redirectAfterCreation: false
            }
        },
        methods: {
            handleFeatureTypeCreate(withRedirect = false) {
                this.$refs.featureTypeCreateForm.submitFeatureTypeCreate();
                this.redirectAfterCreation = withRedirect;
            },
            featureTypeCreatedEvent(data) {
                if (this.redirectAfterCreation) {
                    window.location.href = data.redirect_to;
                    return;
                }

                this.$refs.featureTypeCreateForm.resetForm();
                this.$emit('feature-type-created');
                this.$parent.close();
            }
        },
    }
</script>

<style>

</style>
