export default {
    /**
     * Get products.
     *
     * @param page
     * @param search
     * @returns {Promise}
     */
    getProducts(page, search) {
        if (!page) {
            page = 1;
        }

        return new Promise(function(resolve, reject) {
            let url = `/api/products?page=${page}`;
            if (search) {
                url += `&search=${search}`
            }

            axios.get(url)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Store a product.
     *
     * @param {Object} param
     * @param {string} param.title
     * @param {string} param.description
     * @returns {Promise}
     */
    storeProduct({ title, description }) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/products', { title, description })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Update a product.
     *
     * @param id
     * @param {Object} param
     * @param {string} param.title
     * @param {string} param.alternative_title
     * @param {string} param.description
     * @param {string} param.food_pair_description
     * @param {string} param.stock_available
     * @param {string} param.order_unit
     * @param {string} param.color_id
     * @param {Array} param.feature_values
     * @param {string} param.wine_house_id
     * @param {string} param.reference
     * @param {string} param.price
     * @param {string} param.price_retail
     * @param {string} param.price_discount
     * @param {Array} param.removed_images
     * @param {Array} param.removed_documents
     * @returns {Promise}
     */
    updateProduct(id, { title, alternative_title, description, food_pair_description, stock_available, order_unit, color_id, feature_values, wine_house_id, reference, price, price_retail, price_discount, is_highlighted, removed_images, removed_documents }) {
        return new Promise(function(resolve, reject) {
            axios.put(`/api/products/${id}`, {
                title,
                alternative_title,
                description,
                food_pair_description,
                stock_available,
                order_unit,
                color_id,
                feature_values,
                wine_house_id,
                reference,
                price,
                price_retail,
                price_discount,
                is_highlighted,
                removed_images,
                removed_documents
            })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Delete a product.
     *
     * @param id
     * @returns {Promise}
     */
    deleteProduct(id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/products/${id}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
}
