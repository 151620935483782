<template>
    <div class="w3-admin-main-form">
        <form @submit.prevent="submitFeatureTypeUpdate">
            <div class="w3-admin-hero">
                <h1 class="w3-admin-hero__title">Kenmerk soort aanpassen</h1>
                <button class="button is-primary w3-admin-hero__button">
                    Opslaan
                </button>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Kenmerk soort titel</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Titel">
                        <b-input
                            type="text"
                            ref="title"
                            v-model="form.title"
                            required>
                        </b-input>
                    </b-field>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { featureTypeApi } from "./../api";

    export default {
        props: ['initialFeatureType'],
        data() {
            return {
                form: {
                    title: this.initialFeatureType.title,
                }
            };
        },
        methods: {
            submitFeatureTypeUpdate() {
                featureTypeApi.updateFeatureType(this.initialFeatureType.id, this.form)
                              .then(function(data) {
                                  window.location.href = data.redirect_to;
                              })
                              .catch(function(error) {
                                  console.log(error);
                              });
            }
        }
    };
</script>

<style>
</style>
