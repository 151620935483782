<template>
    <div id="my-account-dropdown">
        <div class="dropdown w3-dropdown has-text-left">
            <div class="dropdown-trigger w3-dropdown-trigger">
                <button class="w3-button w3-button--white" aria-haspopup="true" aria-controls="my-account-dropdown-menu">
                    <span>{{ user.name }} - {{ user.selected_customer.name }}</span>
                    <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
            </div>
            <div class="dropdown-menu" id="my-account-dropdown-menu" role="menu">
                <div class="dropdown-content">
                    <a :href="routeSelectCustomer" class="dropdown-item" v-if="hasMultipleCustomers">
                        <i class="fas fa-users fa-fw"></i>
                        Kies een klant account
                    </a>
                    <a :href="routeMyAccount" class="dropdown-item">
                        <i class="fas fa-user fa-fw"></i>
                        Mijn account
                    </a>
                    <a :href="routeOrderHistory" class="dropdown-item">
                        <i class="fas fa-box-up fa-fw"></i>
                        Bestelhistorie
                    </a>
                    <hr class="dropdown-divider">
                    <a href="#" class="dropdown-item" onclick="event.preventDefault(); document.getElementById('logout-form').submit();">
                        <i class="fas fa-sign-out fa-fw"></i>
                        Logout
                    </a>
                </div>
            </div>
        </div>


    </div>
</template>
<script>
    export default {
        props: [
            'routeSelectCustomer',
            'routeMyAccount',
            'routeOrderHistory'
        ],
        data() {
            return {}
        },
        mounted() {
            let dropdown = document.querySelector('.w3-dropdown');
            dropdown.addEventListener('click', function(event) {
                event.stopPropagation();
                dropdown.classList.toggle('is-active');
            });
        },
        computed: {
            user: function () {
                return this.$store.state.user;
            },
            hasMultipleCustomers: function() {
                if (this.user && this.user.customers) {
                    if (this.user.customers.length > 1) {
                        return true;
                    }
                }

                return false;
            }
        },
    }
</script>
