<template>
    <div>
        <feature-type-update-form :initial-feature-type="featureType"></feature-type-update-form>
    </div>
</template>

<script>
    import FeatureTypeUpdateForm from '../components/FeatureTypeUpdateForm';

    export default {
        props: ['featureType'],
        components: {
            FeatureTypeUpdateForm
        }
    }
</script>

<style>

</style>
