<template>
    <div>
        <b-field v-for="dayString in days" :key="dayString">
            <b-radio-button
                @input="setDay"
                v-model="day"
                :native-value="dayString"
                type="is-success is-light is-outlined"
            >
                <span>{{ dayString }}</span>
            </b-radio-button>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'OrderDeliveryDayRadio',
        props: {
            days: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                day: '',
            }
        },
        created() {
            if (this.days.length === 1) {
                this.day = this.days[0];
                this.setDay();
            }
        },
        methods: {
            setDay() {
                this.$emit('day', this.day);
            }
        },
    }
</script>
