<template>
    <div>
        <b-field label="Land">
            <b-autocomplete
                v-model="country"
                ref="autocomplete"
                :data="filteredCountries"
                placeholder="bijv. Nederland"
                field="name"
                open-on-focus
                @select="option => selected = option">

                <template slot="empty">No results for {{ country }}</template>
            </b-autocomplete>
        </b-field>

        <b-field v-if="selected.iso">
            <b-upload v-model="imageDropFiles"
                      drag-drop
                      accept="image/png">
                <section class="section">
                    <div class="content has-text-centered">
                        <p>
                            <b-icon
                                icon="upload"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>Sleep uw afbeedlingen hierheen of klik om te uploaden</p>
                    </div>
                </section>
            </b-upload>
        </b-field>
    </div>
</template>

<script>
    import countryFlagApi from "../api/countryFlag";

    export default {
        data() {
            return {
                form: {
                    country_iso: "",
                },
                imageDropFiles: [],
                images: [],
                countries: [],
                country: '',
                selected: {
                    iso: '',
                    name: '',
                }
            };
        },
        created() {
            this.getCountries();
        },
        computed: {
            filteredCountries() {
                return this.countries.filter((option) => {
                    return option.name
                                 .toString()
                                 .toLowerCase()
                                 .indexOf(this.country.toLowerCase()) >= 0
                })
            }
        },
        methods: {
            submitRegionCreate() {
                // countryFlagApi.storeRegion({ title: this.form.title , country_iso: this.selected.iso })
                //             .then(data => {
                //                 this.$emit('region-created', data);
                //             })
                //             .catch(error => {
                //                 this.$emit('region-creation-failed', error);
                //             });
            },
            resetForm() {
                // this.selected = null;
            },
            getCountries() {
                axios.get('/api/countries').then(response => {
                    this.countries = response.data;
                });
            },
        },

        watch: {
            imageDropFiles(files) {
                if (files.length === 0) {
                    return;
                }

                const formData = new FormData();
                formData.append('iso', this.selected.iso);

                for (let i = 0; i < files.length; i++) {
                    formData.append(`images[${i}]`, files[i]);
                }

                axios.post(`/api/country-flags`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.$emit('country-flag-created');
                }).catch(error => {
                    console.log(error)
                })
            }
        },
    };
</script>

<style>
</style>
