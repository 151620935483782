<template>
    <form action="">
        <div class="modal-card"
             style="width: auto; overflow: unset;">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Wijnstreek aanmaken
                </p>
            </header>
            <section class="modal-card-body" style="overflow: unset">
                <region-create-form @region-created="regionCreatedEvent" ref="regionCreateForm"></region-create-form>
            </section>
            <footer class="modal-card-foot">
                <button class="button"
                        type="button"
                        @click="$parent.close()">
                    Sluiten
                </button>
                <button type="submit" class="button is-dark" @click.prevent="handleRegionCreate()">
                    Opslaan
                </button>
                <button type="submit" class="button is-primary" @click.prevent="handleRegionCreate(true)">
                    Opslaan en bekijken
                </button>
            </footer>
        </div>
    </form>
</template>

<script>
    import RegionCreateForm from './RegionCreateForm';

    export default {
        components: {
            RegionCreateForm
        },
        data() {
            return {
                redirectAfterCreation: false,
            }
        },
        methods: {
            handleRegionCreate(withRedirect = false) {
                this.$refs.regionCreateForm.submitRegionCreate();
                this.redirectAfterCreation = withRedirect;
            },
            regionCreatedEvent(data) {
                if (this.redirectAfterCreation) {
                    window.location.href = data.redirect_to;
                    return;
                }

                this.$refs.regionCreateForm.resetForm();
                this.$emit('region-created');
                this.$parent.close();
            }
        },
    }
</script>

<style>

</style>
