<template>
    <form action="">
        <div class="modal-card"
             style="width: auto; overflow: unset;">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Land vlag aanmaken
                </p>
            </header>
            <section class="modal-card-body" style="overflow: unset">
                <country-flag-create-form @country-flag-created="countryFlagCreatedEvent" ref="countryFlagCreateForm"></country-flag-create-form>
            </section>
            <footer class="modal-card-foot">
                <button class="button"
                        type="button"
                        @click="$parent.close()">
                    Sluiten
                </button>
            </footer>
        </div>
    </form>
</template>

<script>
    import CountryFlagCreateForm from './CountryFlagCreateForm';

    export default {
        components: {
            CountryFlagCreateForm
        },
        data() {
            return {
                redirectAfterCreation: false,
            }
        },
        methods: {
            handleCountryFlagCreate(withRedirect = false) {
                this.$refs.countryFlagCreateForm.submitRegionCreate();
                this.redirectAfterCreation = withRedirect;
            },
            countryFlagCreatedEvent(data) {
                if (this.redirectAfterCreation) {
                    window.location.href = data.redirect_to;
                    return;
                }

                this.$refs.countryFlagCreateForm.resetForm();
                this.$emit('country-flag-created');
                this.$parent.close();
            }
        },
    }
</script>

<style>

</style>
