<template>
    <form action="">
        <div class="modal-card"
             style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Product verwijderen</p>
            </header>
            <section class="modal-card-body">
                {{this.productname}} echt verwijderen?
            </section>
            <footer class="modal-card-foot">
                <button class="button"
                        type="button"
                        @click="$parent.close()">
                    Nee
                </button>
                <button class="button"
                        type="button"
                        @click="doDelete()">
                    Ja
                </button>
            </footer>
        </div>
    </form>
</template>

<script>

    export default {
        data() {
            return {
                redirectAfterCreation: false,
            }
        },
        methods: {
            doDelete() {
                this.$emit('delete-me', this.productid);
                this.$parent.close()
            }
        },
        props: {
            productname: String,
            productid: 0,
        }
    }
</script>

<style>

</style>
