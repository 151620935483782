<template>
    <div class="w3-admin-main-form full-width">
        <div class="w3-admin-hero">
            <h1 class="w3-admin-hero__title">Land vlaggen</h1>
            <button class="button is-primary w3-admin-hero__button"
                    @click="isComponentModalActive = true">Nieuwe land vlag
            </button>
        </div>
        <div class="columns m-t-15">
            <div class="column">
                <div class="card">
                    <div class="card-content">
                        <country-flag-list ref="countryFlagList"></country-flag-list>

                        <b-modal :active.sync="isComponentModalActive" has-modal-card>
                            <country-flag-create-modal @country-flag-created="handleCountryFlagCreatedEvent()"></country-flag-create-modal>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CountryFlagList from '../components/CountryFlagList';
    import CountryFlagCreateModal from '../components/CountryFlagCreateModal';

    export default {
        components: {
            CountryFlagList,
            CountryFlagCreateModal
        },
        props: ['initialCountryFlags'],
        data() {
            return {
                isComponentModalActive: false,
            };
        },
        mounted() {
        },
        methods: {
            handleCountryFlagCreatedEvent() {
                this.$refs.countryFlagList.handleCountryFlagCreatedEvent();
            }
        }
    };
</script>

<style>
</style>
