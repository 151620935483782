<template>
    <div>
        <b-field>
            <b-input v-model="comment" type="textarea" @input="setComment"></b-input>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'OrderCommentInputBox',
        data() {
            return {
                comment: '',
            }
        },
        methods: {
            setComment() {
                this.$emit('comment', this.comment);
            }
        },
    }
</script>
