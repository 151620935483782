<template>
    <div class="w3-admin-main-form">
        <form @submit.prevent="submitCustomerUpdate">
            <div class="w3-admin-hero">
                <h1 class="w3-admin-hero__title">Klant aanpassen</h1>
                <button class="button is-primary w3-admin-hero__button">Opslaan
                </button>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Klantnaam</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Naam">
                        <b-input v-model="form.name"></b-input>
                    </b-field>
                </div>
            </div>

            <!--<b-field label="Naam">-->
                <!--<b-input v-model="form.name"></b-input>-->
            <!--</b-field>-->

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Gebruikers</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <label class="label">Gebruikers</label>
                    <customer-user-list
                        :users="form.users"
                        @add-user="addUser($event)"
                        @delete-user="deleteUser($event)"></customer-user-list>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Kurken</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Saldo">
                        <b-input v-model="form.point_balance"></b-input>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Adressen</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <label class="label">Adressen</label>
                    <customer-address-list
                        :addresses="form.addresses"
                        :customer="customer"></customer-address-list>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">SnelStart relatie</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <label class="label">Zoeken</label>
                    <customer-relation-search :relation-id="form.snel_start_relation_guid" @relation-selected="handleRelationSelectedEvent"></customer-relation-search>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import { reject } from 'lodash-es';
    import CustomerApi from "./../api/customer";
    import CustomerUserList from "./CustomerUserList";
    import CustomerAddressList from "./CustomerAddressList";
    import CustomerRelationSearch from "./CustomerRelationSearch";

    export default {
        components: {
            CustomerUserList,
            CustomerAddressList,
            CustomerRelationSearch
        },

        props: [
            'customer'
        ],

        data() {
            return {
                form: {
                    name: this.customer.name,
                    users: this.customer.users,
                    point_balance: this.customer.point_balance,
                    addresses: this.customer.addresses,
                    snel_start_relation_guid: this.customer.snel_start_relation_guid
                }
            };
        },
        methods: {
            submitCustomerUpdate() {
                CustomerApi.updateCustomer(this.customer.id ,this.form)
                           .then(function(data) {
                               window.location.href = data.redirect_to;
                           })
                           .catch(function(error) {
                               console.log(error);
                           });
            },

            addUser(user) {
                this.form.users.push(user);
            },

            deleteUser(id) {
                this.form.users = reject(this.form.users, { id: id });
            },
            handleRelationSelectedEvent(payload) {
                if (payload !== null && payload.id) {
                    this.form.snel_start_relation_guid = payload.id;
                    return;
                }

                this.form.snel_start_relation_guid = this.customer.snel_start_relation_guid;
            }
        },
    };
</script>

<style>
</style>
