<template>
    <div class="w3-checkout-confirmation">
        <h2 class="w3-checkout-confirmation__title is-size-3 has-text-centered">Hartelijk dank voor je bestelling!</h2>

        <div class="columns">
            <div class="column">
                <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--contact-person is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
                    <div class="w3-card--content">
                        <div class="content">
                            <p>Beste <span v-text="user.name"></span>,</p>
                            <p>Hartelijk dank voor je mooie bestelling. Met plezier zullen we je order gereed maken en zal onze transporteur het komen brengen.</p>
                            <p>Zoals je weet staat W3 Wijnen graag bij jou op tafel!</p>
                            <p>Santé,</p>
                            <p>Judith & Wesley</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="column">
                <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--contact-person is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
                    <div class="columns is-gapless is-full-height">
                        <div class="column">
                            <div
                                class="w3-card--contact-person__image"
                                style="background-image: url(/img/w3-wijnen-contact.webp);">
                            </div>
                        </div>
                        <div class="column">
                            <div class="w3-card--content">
                                <h3 class="w3-header w3-header--tertiary w3-header--primary m-b-30">Contact</h3>
                                <a href="mailto:info@w3wijnen.nl">info@w3wijnen.nl</a>
                                <div class="m-t-5">
                                    <small>W3 Wijnen B.V.</small><br />
                                    <small>Archimedesbaan 18a</small><br />
                                    <small>3439 ME Nieuwegein</small>
                                </div>
                                <div class="m-t-20">
                                </div>
                                <strong>Judith Blume</strong>
                                <div class="m-t-0">
                                    <small>0031 6 223 78 986</small>
                                </div>
                                <div class="m-t-20">
                                </div>
                                <strong>Wesley van Dinteren</strong>
                                <div class="m-t-0">
                                    <small>0031 6 10 25 15 21</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--contact-person is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
                    <div class="w3-card--content">
                        <order-information-box :order-id="order.id"></order-information-box>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import OrderInformationBox from '../components/OrderInformationBox';
    export default {
        props: [
            'order'
        ],
        components: {
            OrderInformationBox,
        },
        computed: {
            user() {
                return this.$store.state.user;
            }
        },
    }
</script>

<style>

</style>
