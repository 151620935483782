<template>
    <div class="w3-order-information">
        <h2 class="w3-header--primary">
            Bestelinformatie
        </h2>
        <div class="columns m-t-10">
            <div class="column">
                <div class="w3-order-information__label">
                    Bestelnummer
                </div>
                <div class="w3-order-information__label">
                    Datum
                </div>
                <div class="w3-order-information__label">
                    Bezorgdatum
                </div>
                <div class="w3-order-information__label">
                    Bezorgtijdvak
                </div>
                <div class="w3-order-information__label" v-show="order.comment">
                    Opmerking
                </div>
            </div>
            <div class="column">
                <div class="w3-order-information__value">
                    {{ order.id }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.created_at }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.delivery_day }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.delivery_time_slot }}
                </div>
                <div class="w3-order-information__value" v-show="order.comment">
                    {{ order.comment }}
                </div>
            </div>

        </div>

        <div class="columns">
            <div class="column">
                <h3 class="w3-header--secondary">
                    Afleveradres
                </h3>
                <div class="w3-order-information__value">
                    {{ order.customer_name }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.delivery_address_name }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.delivery_address_1 }} {{ order.delivery_address_2 }} {{ order.delivery_address_3 }} {{ order.delivery_address_house_number }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.delivery_address_zipcode }} {{ order.delivery_address_city }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.delivery_address_country_iso_2 }}
                </div>
            </div>

            <div class="column">
                <h3 class="w3-header--secondary">
                    Factuuradres
                </h3>
                <div class="w3-order-information__value">
                    {{ order.customer_name }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.invoice_address_name }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.invoice_address_1 }} {{ order.invoice_address_2 }} {{ order.invoice_address_3 }} {{ order.invoice_address_house_number }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.invoice_address_zipcode }} {{ order.invoice_address_city }}
                </div>
                <div class="w3-order-information__value">
                    {{ order.invoice_address_country_iso_2 }}
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column">
                <b-table :data="order.items">
                    <template slot-scope="props">
                        <b-table-column field="id" label="Referentie">
                            {{ props.row.product.reference }}
                        </b-table-column>
                        <b-table-column field="product" label="Product">
                            {{ props.row.product.title }}
                        </b-table-column>
                        <b-table-column field="amount" label="Aantal">
                            {{ props.row.quantity }}
                        </b-table-column>
                        <b-table-column field="price" label="Stukprijs">
                            {{ props.row.price | currency }}
                        </b-table-column>
                        <b-table-column field="total_price" label="Totaalprijs">
                            {{ props.row.total_tax_excl | currency }}
                        </b-table-column>
                    </template>
                </b-table>
            </div>
        </div>
        <!-- TODO: Order totaal weergeven -->

    </div>
</template>

<script>
    import orderApi from "./../api/order";
    export default {
        name: 'OrderInformationBox',
        props: [
            'orderId'
        ],
        data() {
            return {
                order: [],
            }
        },
        methods: {
            getOrder() {
                orderApi.getOrder(this.orderId).then(response => {
                    this.order = response.data;
                });
            }
        },
        created() {
            this.getOrder();
        }
    }
</script>

<style>

</style>
