<template>
    <form action="">
        <div class="modal-card"
             style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Product aanmaken</p>
            </header>
            <section class="modal-card-body">
                <product-create-form @product-created="productCreatedEvent" ref="productCreateForm"></product-create-form>
            </section>
            <footer class="modal-card-foot">
                <button class="button"
                        type="button"
                        @click="$parent.close()">
                    Sluiten
                </button>
                <button type="submit" class="button is-dark" @click.prevent="handleProductCreate()">
                    Opslaan
                </button>
                <button type="submit" class="button is-primary" @click.prevent="handleProductCreate(true)">
                    Opslaan en bekijken
                </button>
            </footer>
        </div>
    </form>
</template>

<script>
    import ProductCreateForm from './ProductCreateForm';

    export default {
        components: {
            ProductCreateForm
        },
        data() {
            return {
                redirectAfterCreation: false,
            }
        },
        methods: {
            handleProductCreate(withRedirect = false) {
                this.$refs.productCreateForm.submitProductCreate();
                this.redirectAfterCreation = withRedirect;
            },
            productCreatedEvent(data) {
                if (this.redirectAfterCreation) {
                    window.location.href = data.redirect_to;
                    return;
                }

                this.$refs.productCreateForm.resetForm();
                this.$emit('product-created');
                this.$parent.close();
            }
        },
    }
</script>

<style>

</style>
