<template>
 <div class="w3-edit-address-modal">
     <b-modal :active.sync="isModalActive" @close="$parent.showEdit = false" class="w3-modals">
         <div class="w3-modals__close-icon" @click="close()">
             <i class="far fa-times"></i>
         </div>
         <div class="card">
             <div class="card-content">
                 <div class="content">
                     <h3 class="title">Afleveradres bewerken</h3>
                     <a class="delete-address-link" @click="confirmDelete()">Verwijderen</a>
                 </div>
                 <delivery-address-edit-form :delivery-address="deliveryAddress" @success="success"></delivery-address-edit-form>
             </div>
         </div>
     </b-modal>
 </div>
</template>

<script>
    import AddressApi from "./../api/address";
    import DeliveryAddressEditForm from './DeliveryAddressEditForm';
    export default {
        name: 'DeliveryAddressEditModal',
        components: {
            DeliveryAddressEditForm,
        },
        props: [
            'deliveryAddress',
            'open'
        ],
        data() {
            return {
                isModalActive: false,
            }
        },
        methods: {
            success() {
                this.isModalActive = false;
                this.$emit('success');
            },
            close(){
                this.$parent.showEdit = false;
                this.isModalActive = false;
            },
            confirmDelete() {
                this.isModalActive = false;
                this.$emit('delete', this.deliveryAddress);
            }
        },
        watch: {
            open: function(val) {
                if(val === true) {
                    this.isModalActive = true;
                }
            },
        }
    }
</script>

<style>

</style>
