<template>
    <div>
        <customer-input-autocomplete
            @add-customer="addCustomer($event)"
            label="Koppel een klant aan deze gebruiker"
            class="m-t-20">
        </customer-input-autocomplete>

        <b-table
            :data="isEmpty ? [] : customers" class="m-t-20" :default-sort="['name', 'desc']">

            <template slot-scope="props">
                <b-table-column field="name" label="Naam" sortable>
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column field="action">
                    <span class="button is-danger is-small" @click="deleteCustomer(props.row.id)"><i class="fas fa-times"></i></span>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-if="loading === false">
                    <div class="content has-text-grey has-text-centered">
                        <p>Geen klanten gekoppeld...</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
    import CustomerInputAutocomplete from "./CustomerInputAutocomplete";

    export default {
        components: {
            CustomerInputAutocomplete,
        },

        props: [
            'customers'
        ],

        data() {
            return {
                loading: false,
                isEmpty: false,
            };
        },
        methods: {
            addCustomer(customer) {
                this.$emit('add-customer', customer)
            },

            deleteCustomer(id) {
                this.$emit('delete-customer', id);
            },
        }
    };
</script>
