<template>
    <div>
        <b-table
            :data="orders"
            :loading="tableOptions.loading"
            :total="tableOptions.total"
            :per-page="tableOptions.perPage"
            @page-change="onPageChange"
            backend-pagination
            paginated>

            <template slot-scope="props">
                <b-table-column field="id"
                                label="Bestel #"
                                width="40">
                    <strong>{{ props.row.id}}</strong>
                </b-table-column>

                <b-table-column field="created_at"
                                label="Datum">
                    {{ props.row.created_at }}
                </b-table-column>

                <b-table-column field="total_tax_incl_display"
                                label="Totaal bedrag">
                    {{ props.row.total_tax_incl | currency }}
                </b-table-column>

                <b-table-column>
                    <button class="button is-primary"
                            v-on:click="isComponentModalActive = true, selectOrder(props.row)">
                        Details bestelling
                    </button>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section"
                         v-if="! tableOptions.loading">
                    <div class="content has-text-grey has-text-centered">
                        <p>Er zijn nog geen bestellingen geplaatst...</p>
                    </div>
                </section>
            </template>
        </b-table>

        <!--MODAL-->
        <b-modal
            :active.sync="isComponentModalActive"
            has-modal-card>
            <div class="modal-card"
                 style="">
                <header class="modal-card-head">
                    <div class="modal-card-title">
                        <strong>Bestelling #{{ selectedOrder.id }}</strong>
                    </div>
                </header>
                <section class="modal-card-body">
                    <order-information-box :order-id="selectedOrder.id"></order-information-box>
                </section>
                <footer class="modal-card-foot">
                    <button class="button"
                            type="button"
                            @click="isComponentModalActive = false">
                        Sluiten
                    </button>
                </footer>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import orderApi from "./../api/order";
    import BTableColumn from "buefy/src/components/table/TableColumn";
    import OrderInformationBox from '../components/OrderInformationBox';

    export default {
        components: {BTableColumn, OrderInformationBox},
        props: ["initialProducts"],
        data() {
            return {
                orders: [],
                tableOptions: {
                    total: 0,
                    page: 1,
                    perPage: 15,
                    loading: true,
                },
                selectedOrder: {},
                isComponentModalActive: false,
            };
        },
        methods: {
            getOrders(page = null) {
                orderApi.getOrders(page).then(response => {
                    this.orders = response.data;
                    this.tableOptions.total = response.meta.total;
                    this.tableOptions.loading = false;
                });
            },
            onPageChange(page) {
                this.tableOptions.page = page;
                this.getOrders(page)
            },
            selectOrder: function (order) {
                this.selectedOrder = order;
            },
        },
        created() {
            this.getOrders();
        },
        mounted() {
        },
    };
</script>

<style scoped>
    .modal-card {
        width: 100%;
    }
</style>
