<template>
    <div class="w3-admin-main-form full-width">
        <div class="w3-admin-hero">
            <h1 class="w3-admin-hero__title">Gebruikers</h1>
            <button class="button is-primary w3-admin-hero__button"
                    @click="isCreateUserActive = true">Nieuwe gebruiker aanmaken
            </button>
        </div>
        <div class="columns m-t-15">
            <div class="column">
                <div class="card">
                    <div class="card-content">
                        <users-list></users-list>

                        <b-modal :active.sync="isCreateUserActive">
                            <div class="card">
                                <div class="card-content">
                                    <user-create-form></user-create-form>
                                </div>
                            </div>
                        </b-modal>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserCreateForm from './../components/UserCreateForm';
    import UsersList from './../components/UsersList';

    export default {
        components: {
            UserCreateForm,
            UsersList
        },

        data() {
            return {
                isCreateUserActive: false,
            };
        },
        mounted() {
        },
        methods: {
        }
    };
</script>

<style>
</style>
