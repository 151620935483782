<template>

    <div id="DashboardHighlightedProduct" class="column column-eq-height is-12-mobile is-10-tablet is-6-desktop is-4-fullhd">
        <div class="w3-card w3-card--content w3-card--rounded w3-card--eq-height w3-card--new-wine is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
            <div class="columns">
                <div class="column">
                    <h3 class="w3-header w3-header--tertiary w3-header--primary">Ontdek nieuwe wijnen</h3>
                </div>
            </div>
            <div class="columns is-mobile is-marginless--bottom">
                <div class="column is-12-mobile is-9-tablet">

                    <strong class="is-block m-b-10"><i :class="getColorClass(product.color)"></i> {{ product.title }}</strong>
                    <ul class="w3-list">
                        <li>
                            <span class="w3-new-wine__attribute-label">Land van herkomst:</span>
                            <span>{{ product.country }}</span>
                        </li>
                        <li>
                            <span class="w3-new-wine__attribute-label">Streek:</span>
                            <span>{{ product.region_title }}</span>
                        </li>
                        <li>
                            <span class="w3-new-wine__attribute-label">Druivensoort:</span>
                            <span v-for="value in product.Druivensoort">{{ value }}</span>
                        </li>
                        <li>
                            <span class="w3-new-wine__attribute-label">Alcoholpercentage:</span>
                            <span v-for="value in product.Alcoholpercentage">{{ value }}</span>
                        </li>
                        <li>
                            <span class="w3-new-wine__attribute-label">Bewaaradvies:</span>
                            <span v-for="value in product.Bewaaradvies">{{ value }}</span>
                        </li>
                    </ul>

                </div>
                <div class="column is-3-tablet is-hidden-mobile">
                    <img :src="product.default_image" :alt="product.title" class="image" style="max-height: 170px" />
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import UserApi from "./../api/user";
    import { convertWineColorStringToClass } from "../utilities/color";

    export default {
        data() {
            return {
                product: {
                    title: '',
                    country: '',
                    region_title: '',
                    Wijnsoort: [],
                    Bewaaradvies: [],
                    Alcoholpercentage: []
                },
                title: ''
            }
        },
        methods: {
            getColorClass(color) {
                return convertWineColorStringToClass(color);
            }
        },
        mounted() {
            UserApi.getCurrentUser().then((data) => {
                this.product = data.highlighted_product;
            });
        }
    }
</script>
