<template>
 <div>
     <a @click="isModalActive = true" v-if="status === 'new'" class="w3-button w3-button--link-primary">
         Factuuradres aanmaken
     </a>

     <b-modal :active.sync="isModalActive" @close="$parent.edit = false" class="w3-modals">
         <div class="w3-modals__close-icon" @click="close()">
             <i class="far fa-times"></i>
         </div>
         <div class="card">
             <div class="card-content">
                 <div class="content">
                     <h3 class="title" v-if="status === 'new'">Factuuradres aanmaken</h3>
                     <h3 class="title" v-if="status === 'edit'">Factuuradres bewerken</h3>
                 </div>
                 <invoice-address-form :status="status" :invoice-address="invoiceAddress" @success="success"></invoice-address-form>
             </div>
         </div>
     </b-modal>
 </div>
</template>

<script>
    import InvoiceAddressForm from './InvoiceAddressForm.vue';
    export default {
        name: 'InvoiceAddressModal',
        components: {
            InvoiceAddressForm,
        },
        props: [
            'status',
            'open',
            'invoiceAddress'
        ],
        data() {
            return {
                isModalActive: false,
            }
        },
        methods: {
            success() {
                this.isModalActive = false;
                this.$emit('success');
            },
            close() {
                this.$parent.edit = false;
                this.isModalActive = false;
            }
        },
        watch: {
            open: function(val) {
                if(val === true) {
                    this.isModalActive = true;
                }
            },
        }
    }
</script>

<style>

</style>
