<template>
 <div>
     <a @click="isModalActive = true" class="w3-button w3-button--link-primary">
         Afleveradres aanmaken
     </a>

     <b-modal :active.sync="isModalActive" class="w3-modals">
         <div class="w3-modals__close-icon" @click="isModalActive = false">
             <i class="fas fa-times"></i>
         </div>
         <div class="card">
             <div class="card-content">
                 <div class="content">
                     <h3 class="title">Afleveradres aanmaken</h3>
                 </div>
                 <delivery-address-create-form @success="success"></delivery-address-create-form>
             </div>
         </div>
     </b-modal>
 </div>
</template>

<script>
    import DeliveryAddressCreateForm from './DeliveryAddressCreateForm';
    export default {
        name: 'DeliveryAddressCreateModal',
        components: {
            DeliveryAddressCreateForm,
        },
        data() {
            return {
                isModalActive: false,
            }
        },
        methods: {
            success() {
                this.isModalActive = false;
                this.$emit('success');
            }
        },
        computed: {

        },
        created() {

        }
    }
</script>

<style>

</style>
