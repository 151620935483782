<template>
    <div class="w3-checkout">
        <h2 class="w3-checkout__title is-size-3 has-text-centered">Bestelgegevens</h2>

        <div class="columns">
            <div class="column">
                <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--contact-person is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
                    <div class="w3-card--content">
                        <h3 class="w3-header w3-header--tertiary w3-header--primary">Afleveradres</h3>
                        <delivery-addresses @delivery-address-id="delivery_address_id = $event"></delivery-addresses>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--contact-person is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
                    <div class="w3-card--content">
                        <h3 class="w3-header w3-header--tertiary w3-header--primary">Factuuradres</h3>
                        <invoice-address @invoice-address-id="invoice_address_id = $event"></invoice-address>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--contact-person is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
                    <div class="w3-card--content">
                        <h3 class="w3-header w3-header--tertiary w3-header--primary">Referentie</h3>
                        <order-reference-input-box @reference="reference = $event"></order-reference-input-box>

                        <h3 class="w3-header w3-header--tertiary w3-header--primary m-t-15">Opmerkingen</h3>
                        <order-comment-input-box @comment="comment = $event"></order-comment-input-box>
                    </div>
                </div>
            </div>
        </div>
        <div class="columns">
            <div class="column">
                <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--contact-person is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
                    <div class="w3-card--content">
                        <h3 class="w3-header w3-header--tertiary w3-header--primary m-t-15">Tijdvak</h3>
                        <order-delivery-time-slot-radio
                            :time-slots="deliveryTimeSlots"
                            @timeSlot="deliveryTimeSlot = $event"
                        ></order-delivery-time-slot-radio>
                        <h3 class="w3-header w3-header--tertiary w3-header--primary m-t-15">Dag</h3>
                        <order-delivery-day-radio
                            :days="deliveryDays"
                            @day="deliveryDay = $event"
                        ></order-delivery-day-radio>
                        <h3 class="w3-header w3-header--tertiary w3-header--primary m-t-15">Let op!</h3>
                        <p>Niet aanwezig worden de wijnen de volgende dag nogmaals aangeboden voor 50 euro extra. Wijnen kunnen niet voor de deur gezet worden en onbewaakt worden achtergelaten. Ontvangst door minderjarigen ook niet mogelijk dit in verband met nieuwe wet regelgeving</p>
                    </div>
                </div>
            </div>
        </div>

        <form action="/checkout" method="post">
            <slot name="csrf"></slot>


            <input type="hidden" v-model="invoice_address_id" name="invoice_address_id" />
            <input type="hidden" v-model="delivery_address_id" name="delivery_address_id" />
            <input type="hidden" v-model="comment" name="comment" />
            <input type="hidden" v-model="reference" name="reference" />
            <input type="hidden" v-model="deliveryTimeSlot" name="delivery_time_slot" />
            <input type="hidden" v-model="deliveryDay" name="delivery_day" />

            <button type="submit" class="w3-button w3-button--primary w3-button--biggest is-pulled-right">Plaats bestelling</button>
        </form>
    </div>
</template>

<script>
    import DeliveryAddresses from '../components/DeliveryAddresses';
    import InvoiceAddress from '../components/InvoiceAddress';
    import OrderReferenceInputBox from '../components/OrderReferenceInputBox';
    import OrderCommentInputBox from '../components/OrderCommentInputBox';
    import OrderDeliveryTimeSlotRadio from "../components/OrderDeliveryTimeSlotRadio.vue";
    import OrderDeliveryDayRadio from "../components/OrderDeliveryDayRadio.vue";

    export default {
        components: {
            OrderDeliveryTimeSlotRadio,
            OrderDeliveryDayRadio,
            DeliveryAddresses,
            InvoiceAddress,
            OrderReferenceInputBox,
            OrderCommentInputBox,
        },
        props: {
            deliveryTimeSlots: {
                type: Array,
                default: []
            },
            deliveryDays: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                invoice_address_id: '',
                delivery_address_id: '',
                reference: '',
                comment: '',
                deliveryTimeSlot: '',
                deliveryDay: '',
            }
        }
    }
</script>

<style>

</style>
