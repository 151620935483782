<template>
    <div class="w3-admin-main-form">

        <form @submit.prevent="submitRegionUpdate">
            <div class="w3-admin-hero">
                <h1 class="w3-admin-hero__title">Wijnstreek aanpassen</h1>
                <button class="button is-primary w3-admin-hero__button">Opslaan
                </button>
            </div>

            <div class="notification is-success" v-show="is_saved">
                <button type="button" class="delete" @click.prevent="is_saved = false"></button>
                Wijzigingen zijn opgeslagen!
                <i class="far fa-thumbs-up"></i>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Wijnstreek titel</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Titel">
                        <b-input
                            type="text"
                            ref="title"
                            v-model="form.title"
                            placeholder="Titel"
                            required>
                        </b-input>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Land</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <b-field label="Land">
                        <b-autocomplete
                            v-model="country"
                            ref="autocomplete"
                            :data="filteredCountries"
                            placeholder="bijv. Nederland"
                            field="name"
                            open-on-focus
                            @select="countrySelected">

                            <template slot="empty">No results for {{ country }}</template>
                        </b-autocomplete>
                    </b-field>
                </div>
            </div>

            <div class="w3-admin-main-form__item">
                <div class="w3-admin-main-form__label">
                    <p class="w3-admin-main-form__title">Regio afbeeldingen</p>
                </div>
                <div class="w3-admin-main-form__input">
                    <div class="w3-admin-main-form-file-list">
                        <div v-for="image in filteredImages" class="w3-admin-main-form-file-list__item">
                            <div class="w3-admin-main-form-file-list__item-card">
                                <div class="w3-admin-main-form-file-list__image-wrapper">
                                    <img :src="`/storage/region_images/${image.filename}`"
                                         class="w3-admin-main-form-file-list__image"
                                         alt="">
                                </div>

                                <button class="delete is-small w3-admin-main-form-file-list__delete-button"
                                        type="button"
                                        @click="deleteImage(image.id)">
                                </button>
                            </div>
                        </div>
                    </div>

                    <b-field>
                        <b-upload v-model="imageDropFiles"
                                  multiple
                                  drag-drop
                                  accept="image/png,image/jpeg">
                            <section class="section">
                                <div class="content has-text-centered">
                                    <p>
                                        <b-icon
                                                icon="upload"
                                                size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>Sleep uw afbeedlingen hierheen of klik om te uploaden</p>
                                </div>
                            </section>
                        </b-upload>
                    </b-field>
                </div>
            </div>
        </form>

        <div class="notification is-success" v-show="is_saved">
            <button class="delete" @click="is_saved = false"></button>
            Wijzigingen zijn opgeslagen!
            <i class="far fa-thumbs-up"></i>
        </div>

    </div>
</template>

<script>
    import RegionApi from "./../api/region";

    export default {
        props: ['initialRegion'],
        data() {
            return {
                form: {
                    title: this.initialRegion.title,
                    country_iso: this.initialRegion.country_iso_a2,
                    removed_images: [],
                },
                countries: [],
                country: '',
                selected: null,
                imageDropFiles: [],
                images: this.initialRegion.images,
                is_saved: false,
            };
        },
        created() {
            this.getCountries().then(() => {
                const county = this.countries.find(({ iso }) => {
                    return iso === this.initialRegion.country_iso_a2;
                });

                this.$refs.autocomplete.setSelected(county);
            });
        },
        computed: {
            filteredCountries() {
                return this.countries.filter((option) => {
                    return option.name
                                 .toString()
                                 .toLowerCase()
                                 .indexOf(this.country.toLowerCase()) >= 0
                })
            },
            filteredImages() {
                return this.images.filter(({ id }) => {
                    return this.form.removed_images.findIndex(removedImageId => removedImageId === id) === -1;
                })
            },
        },
        methods: {
            submitRegionUpdate(view = false) {
                RegionApi.updateRegion(this.initialRegion.id, this.form)
                         .then((data) => {
                             this.is_saved = true;
                             if (view) {
                                 // window.location.href = data.region.region_url;
                             }

                         })
                         .catch(function(error) {
                             console.log(error);
                         });
            },
            countrySelected(option) {
                if (! option) {
                    return;
                }
                this.form.country_iso = option.iso
            },
            resetForm() {
                this.form.title = '';
                this.form.selected = null;
                this.form.removed_images = [];
                this.$refs.title.focus();
            },
            getCountries() {
                return new Promise((resolve, reject) => {
                    axios.get('/api/countries').then(response => {
                        this.countries = response.data;
                        resolve(response);
                    }).catch(error => {
                        reject(error);
                    });
                });
            },
            deleteImage(imageId) {
                this.form.removed_images.push(imageId);
            },
        },
        watch: {
            imageDropFiles(files) {
                if (files.length === 0) {
                    return;
                }

                const formData = new FormData();

                for (let i = 0; i < files.length; i++) {
                    formData.append(`images[${i}]`, files[i]);
                }

                axios.post(`/api/regions/${this.initialRegion.id}/images`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.images = response.data.images;
                    this.imageDropFiles = [];
                }).catch(error => {
                    console.log(error)
                })
            }
        },
    };
</script>

<style>
</style>
