<template>
 <div class="w3-edit-address-modal">
     <b-modal :active.sync="isModalActive" @close="$parent.showDelete = false" class="w3-modals">
         <div class="w3-modals__close-icon" @click="close()">
             <i class="far fa-times"></i>
         </div>
         <div class="card">
             <div class="card-content">
                 <div class="content">
                     <h3 class="title">Afleveradres verwijderen</h3>
                     <div class="columns">
                         <div class="column is-2">
                             <i class="fal fa-exclamation-triangle fa-4x"></i>
                         </div>
                         <div class="column">
                             <p>Weet je zeker dat je het adres wilt verwijderen? Dit kan niet ongedaan gemaakt worden.</p>
                         </div>
                     </div>
                     <button class="w3-button w3-button--primary-outline m-t-10" @click="close()">Annuleren</button>
                     <button class="w3-button w3-button--primary m-t-10" @click="deleteAddress()">Verwijderen</button>
                 </div>
             </div>
         </div>
     </b-modal>
 </div>
</template>

<script>
    import AddressApi from "./../api/address";
    export default {
        name: 'DeliveryAddressDeleteModal',
        props: [
            'deliveryAddress',
            'open'
        ],
        data() {
            return {
                isModalActive: false,
            }
        },
        methods: {
            deleteAddress() {
                this.isModalActive = false;
                this.$emit('delete');
            },
            close(){
                this.$parent.showDelete = false;
                this.isModalActive = false;
            },
        },
        watch: {
            open: function(val) {
                if(val === true) {
                    this.isModalActive = true;
                }
            },
        }
    }
</script>

<style>

</style>
