<template>
    <span v-text="amountInCart"></span>
</template>

<script>
    export default {
        computed: {
            amountInCart() {
                return this.$store.getters.amountInCart;
            }
        },
    }
</script>

<style>

</style>
