export default {
    /**
     * Store a customer.
     *
     * @param {object} name
     * @param {string} snel_start_relation_guid
     * @returns {Promise}
     */
    storeCustomer({ name, snel_start_relation_guid }) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/customers', { name, snel_start_relation_guid })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Update a customer.
     *
     * @param id
     * @param {string} name
     * @param {string} users
     * @param {string} snel_start_relation_guid
     * @returns {Promise}
     */
    updateCustomer(id, { name, users, snel_start_relation_guid, point_balance }) {
        return new Promise(function(resolve, reject) {
            axios.put(`/api/customers/${id}`, { name, users, snel_start_relation_guid, point_balance })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Delete a customer.
     *
     * @param id
     * @returns {Promise}
     */
    deleteCustomer(id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/customers/${id}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Get a list of customers.
     *
     * @param page
     * @param search
     * @returns {Promise}
     */
    getCustomers(page, search) {
        return new Promise(function(resolve, reject) {
            let url = `/api/customers/?page=${page}`;
            if (search) {
                url += `&search=${search}`
            }

            axios.get(url)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Search customers by keyword
     *
     * @param key
     * @returns {Promise}
     */
    searchCustomers(key) {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/customers/search?key=${key}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },

    /**
     * Get a list of customers by user.
     *
     * @returns {Promise}
     */
    getCustomersByUser() {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/user/customers`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Get a relation from an external service.
     *
     * @param query
     * @returns {Promise}
     */
    getExternalRelation(query) {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/relations/external/search?query=${query}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Get a relation by ID from an external service.
     *
     * @param id
     * @returns {Promise}
     */
    getExternalRelationById(id) {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/relations/external/${id}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
}
