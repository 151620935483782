<template>

    <div id="DashboardFavorites" class="column column-eq-height is-12-mobile is-10-tablet is-6-desktop is-4-fullhd">
        <div class="w3-card w3-card--content w3-card--rounded w3-card--eq-height is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">

            <div class="columns is-mobile">
                <div class="column is-narrow">
                    <h3 class="w3-header w3-header--tertiary w3-header--primary">Uw favoriete wijnen</h3>
                </div>
                <div class="column has-text-right">
                    <a href="/search?favorites=true" class="w3-button w3-button--link-primary"><i class="fal fa-angle-right"></i> bekijk meer</a>
                </div>
            </div>

            <div class="columns is-mobile m-b-0">
                <div class="column">
                    <strong>Gebaseerd op uw selectie</strong>
                </div>
            </div>

            <div class="columns is-mobile is-marginless--bottom" v-for="product in user.favorites">
                <div class="column is-8-mobile is-9-tablet">
                    {{ product.title }} <i :class="getColorClass(product.color)"></i>
                </div>
                <div class="column is-4-mobile is-3-tablet p-l-0">
                    <a :href="product.product_url" class="w3-button w3-button--primary w3-button--smallest is-pulled-right">
                        <span>bekijk wijn</span>
                    </a>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import UserApi from "./../api/user";
    import { convertWineColorStringToClass } from "../utilities/color";

    export default {
        data() {
            return {
                user: {}
            }
        },
        methods: {
            getColorClass(color) {
                return convertWineColorStringToClass(color);
            }
        },
        mounted() {
            UserApi.getCurrentUser().then((data) => {
                this.user = data;
            });
        }
    }
</script>
