<template>
    <div>
        <b-table
            :data="country_flags"
            :loading="tableOptions.loading"
            :total="tableOptions.total">

            <template slot-scope="props">
                <b-table-column field="title" label="Country">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column field="title" label="Flag">
                    <img :src="props.row.image_path" :alt="props.row.name" width="30" height="30">
                </b-table-column>

                <b-table-column field="action" label="Actie">
                    <button class="button is-danger m-l-5" @click="deleteCountryFlag(props.row.iso)">
                        Verwijderen
                    </button>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section" v-if="! tableOptions.loading">
                    <div class="content has-text-grey has-text-centered">
                        <p>Geen vlaggen gevonden...</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
    import countryFlagApi from "./../api/countryFlag";

    export default {
        data() {
            return {
                country_flags: [],
                tableOptions: {
                    total: 0,
                    page: 1,
                    perPage: 15,
                    loading: true,
                },
            };
        },
        created() {
            this.getCountryFlags();
        },
        mounted() {
        },
        methods: {
            getCountryFlags(page = 1) {
                countryFlagApi.getCountryFlags(page).then(response => {

                    this.country_flags = response;
                    this.tableOptions.total = response.length;
                    this.tableOptions.loading = false;
                });
            },
            deleteCountryFlag(countryIso) {
                countryFlagApi.deleteCountryFlag(countryIso).then(() => {
                    this.getCountryFlags();
                });
            },
            onPageChange(page) {
                this.tableOptions.page = page;
                this.getCountryFlags(page);
            },
            handleCountryFlagCreatedEvent() {
                this.getCountryFlags(this.tableOptions.page);
            }
        }
    };
</script>

<style>
</style>
