<template>
 <div class="w3-delivery-addresses">
     <delivery-address-row
         v-for="delivery_address in delivery_addresses"
         :key="delivery_address.id"
         :delivery-address="delivery_address"
         :active="activeAddress"
         @select-address="selectAddress($event)"
         @edit-address="editAddress($event)"
         @delete-address="confirmDelete($event)">
     </delivery-address-row>
     <delivery-address-create-modal @success="getDeliveryAddresses()"></delivery-address-create-modal>
     <delivery-address-edit-modal :delivery-address="editDeliveryAddress" :open="showEdit" @success="getDeliveryAddresses()" @delete="confirmDelete($event)"></delivery-address-edit-modal>
     <delivery-address-delete-modal :delivery-address="deleteDeliveryAddress" :open="showDelete" @delete="deleteAddress"></delivery-address-delete-modal>
 </div>
</template>

<script>
    import DeliveryAddressRow from './DeliveryAddressRow';
    import DeliveryAddressCreateModal from './DeliveryAddressCreateModal';
    import DeliveryAddressEditModal from './DeliveryAddressEditModal';
    import DeliveryAddressDeleteModal from './DeliveryAddressDeleteModal';
    import AddressApi from "./../api/address";

    export default {
        name: 'DeliveryAddresses',
        components: {
            DeliveryAddressRow,
            DeliveryAddressCreateModal,
            DeliveryAddressEditModal,
            DeliveryAddressDeleteModal,
        },
        data() {
            return {
                addresses: [],
                activeAddress: 1,

                showEdit: false,
                editDeliveryAddress: '',
                showDelete: false,
                deleteDeliveryAddress: '',
            }
        },
        methods: {
            getDeliveryAddresses() {
                this.showEdit = false;
                this.showDelete = false;
                let that = this;
                AddressApi.getAddressesByCustomer()
                          .then(function(data) {
                              that.addresses = data.data;
                              that.$nextTick(() => {
                                  if(that.delivery_addresses.length > 0 ) {
                                      that.activeAddress = that.delivery_addresses[0].id;
                                  }
                                  that.$emit('delivery-address-id', that.activeAddress);
                              });

                          })
                          .catch(function(error) {
                              console.log(error);
                          });
            },
            selectAddress(deliveryAddress) {
                this.activeAddress = deliveryAddress.id;
                this.$emit('delivery-address-id', this.activeAddress);
            },
            editAddress(deliveryAddress) {
                this.showEdit = true;
                this.editDeliveryAddress = deliveryAddress;
            },
            confirmDelete(deliveryAddress) {
                this.showDelete = true;
                this.deleteDeliveryAddress = deliveryAddress;
            },
            deleteAddress() {
                AddressApi.deleteAddress(this.deleteDeliveryAddress.id).then(() => {
                    this.showEdit = false;
                    this.showDelete = false;
                    this.getDeliveryAddresses();
                })
            }
        },
        computed: {
            delivery_addresses() {
                return this.addresses.filter(function (el) {
                    return el.address_type_id === 1;
                });
            }
        },
        created() {
            this.getDeliveryAddresses();
        }
    }
</script>

<style>

</style>
