export default {
    /**
     * Get the feature values belonging to the feature type.
     *
     * @returns {Promise}
     */
    getFeatureValuesByFeatureTypeId(id) {
        return new Promise(function(resolve, reject) {
            axios.get(`/api/feature-types/${id}/feature-values`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Store a feature value.
     *
     * @param {Object} param
     * @param {string} param.title
     * @returns {Promise}
     */
    storeFeatureValue({ title, feature_type_id }) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/feature-values', { title, feature_type_id })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
}
