<template>
    <button @click="handleButtonClick">
        <input type="checkbox"
               v-model="value"> Favoriet
    </button>
</template>

<script>
export default {
    props: {
        productId: Number
    },
    data() {
        return {
            value: false
        };
    },
    computed: {
        favoriteIds() {
            return this.$store.getters.favoriteIds;
        }
    },
    methods: {
        handleButtonClick() {
            this.toggleFavorite();
        },
        toggleFavorite() {
            if (this.favoriteIds.indexOf(this.productId) === -1) {
                this.$store.dispatch("addProductToFavorites", this.productId);
                this.value = true;
                return;
            }

            this.$store.dispatch("removeProductFromFavorites", this.productId);
            this.value = false;
        }
    },
    watch: {
        favoriteIds(favoriteIds) {
            if (favoriteIds.indexOf(this.productId) !== -1) {
                this.value = true;
            }
        }
    }
};
</script>

