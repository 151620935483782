export function convertWineColorStringToClass(color) {
    switch (color) {
        case 'Rood':
            return 'fas fa-tint wine-color--red';
        case 'Wit':
            return 'fas fa-tint wine-color--white';
        case 'Rosé':
            return 'fas fa-tint wine-color--rose';
        default:
            return '';
    }
}
