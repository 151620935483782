<template>
    <div>
        <select v-model="selectedCountry">
            <option :value="null"
                    selected>Selecteer een land
            </option>
            <option :value="country.iso"
                    v-for="country in filteredCountries"
                    v-text="country.name"></option>
        </select>

        <select :disabled="selectedCountry === null" v-model="selectedRegion">
            <option :value="null"
                    selected>Selecteer een gebied
            </option>
            <option v-for="region in filteredRegions"
                    v-text="region.title"></option>
        </select>

        <button type="button"
                class="w3-button w3-button--primary"
                :disabled="selectedCountry === null"
                @click="handleAddToSearchClick">voeg toe aan selectie
        </button>
    </div>
</template>

<script>
    import { Component } from 'vue-instantsearch';

    export default {
        name: "CountryRegionSelection",
        mixins: [Component],
        data() {
            return {
                countries: [],
                regions: [],
                selectedCountry: null,
                selectedRegion: null
            }
        },
        mounted() {
            let current_page = 1;
            axios.get('/api/active-countries?per_page=900').then(response => {
                this.countries = response.data;
                let current_page = 1;
            });
            this.loadRegionsFromApi(1);

        },
        computed: {
            filteredCountries() {
                return this.countries;
            },
            filteredRegions() {
                return this.regions.filter(({ country_iso_a2 }) => country_iso_a2 === this.selectedCountry);
            }
        },
        methods: {
            handleAddToSearchClick() {
                const country = this.countries.find(({ iso }) => iso === this.selectedCountry);

                this.searchStore._helper.addDisjunctiveFacetRefinement('country', country.name);

                if (this.selectedRegion) {
                    this.searchStore._helper.addDisjunctiveFacetRefinement('region_title', this.selectedRegion);
                }
            },
            loadCountriesFromApi(current_page) {
                axios.get('/api/active-countries?per_page=900').then(response => {
                    if (this.countries.length > 0) {
                        this.countries = this.countries.concat(response.data);
                    } else {
                        this.countries = response.data;
                    }

                    if (current_page <= response.data.meta.last_page) {
                        let new_page = (current_page + 1);
                        this.loadCountriesFromApi(new_page);
                    }
                });
            },
            loadRegionsFromApi(current_page) {
                axios.get('/api/regions?page=' + current_page).then(response => {
                    if (this.regions.length > 0) {
                        this.regions = this.regions.concat(response.data.data);
                    } else {
                        this.regions = response.data.data;
                    }

                    if (current_page <= response.data.meta.last_page) {
                        let new_page = (current_page + 1);
                        this.loadRegionsFromApi(new_page);
                    }
                });
            }
        },
    }
</script>

<style>

</style>