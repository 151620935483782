export default {
    /**
     * Get feature types.
     *
     * @param {number=} page
     * @returns {Promise}
     */
    getFeatureTypes(page) {
        if (! page) {
            page = 1;
        }

        return new Promise(function(resolve, reject) {
            axios.get(`/api/feature-types?page=${page}`)
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Store a feature type.
     *
     * @param {Object} param
     * @param {string} param.title
     * @returns {Promise}
     */
    storeFeatureType({ title }) {
        return new Promise(function(resolve, reject) {
            axios.post('/api/feature-types', { title })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Update a feature type.
     *
     * @param id
     * @param {Object} param
     * @param {string} param.title
     * @returns {Promise}
     */
    updateFeatureType(id, { title }) {
        return new Promise(function(resolve, reject) {
            axios.put(`/api/feature-types/${id}`, { title })
                 .then(function(response) {
                     resolve(response.data);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    },
    /**
     * Delete a feature type.
     *
     * @param id
     * @returns {Promise}
     */
    deleteFeatureType(id) {
        return new Promise(function(resolve, reject) {
            axios.delete(`/api/feature-types/${id}`)
                 .then(function(response) {
                     resolve(response);
                 })
                 .catch(function(error) {
                     reject(error);
                 });
        })
    }
}
