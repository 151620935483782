<template>

    <div id="DashboardPopularWineHouses" class="column column-eq-height is-12-mobile is-6-tablet is-4-desktop is-3-fullhd">
        <div class="w3-card w3-card--rounded w3-card--eq-height w3-card--content is-size-6-mobile is-size-7-tablet is-size-6-desktop is-size-7-fullhd">
            <div class="columns is-mobile">
                <div class="column is-narrow">
                    <h3 class="w3-header w3-header--tertiary w3-header--primary">Populaire wijnhuizen</h3>
                </div>
                <!--<div class="column has-text-right p-l-0">-->
                    <!--<a href="#" class="w3-button w3-button&#45;&#45;link-primary"><i class="fal fa-angle-right"></i> alle wijnhuizen</a>-->
                <!--</div>-->
            </div>
            <ul class="w3-list">
                <li v-for="(wine_house, index) in user.popular_wine_houses">
                    <strong>{{ index + 1 }}</strong> <span>{{ wine_house.title }}</span>
                </li>
            </ul>
        </div>
    </div>

</template>
<script>
    import UserApi from "./../api/user";

    export default {
        data() {
            return {
                user: {
                    popular_wine_houses: []
                }
            }
        },
        mounted() {
            UserApi.getCurrentUser().then((data) => {
                this.user = data;
            });
        }
    }
</script>
